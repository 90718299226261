<template>
  <div class="oct-switch">
    <span class="oct-switch__label-text" v-if="label !== ''">{{ label }}</span>

    <div class="oct-switch__anchor">
      <input
        type="checkbox"
        class="oct-switch__native-control"
        :value="value"
        :required="required"
        v-model="$_octSwitch_value"
      />

      <div class="oct-switch__background">
        <i class="oct-switch__checkmark"></i>
      </div>

      <div class="oct-switch__text">
        <span
          class="oct-switch__primary-text"
          v-for="(item, i) in texts"
          :key="i"
        >
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OctSwitch',
  components: {},
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    /** Whether checked */
    checked: {
      type: [Boolean,Array,String],
      default: false
    },
    /** Checked Value */
    value: {
      type: [String,Number],
      default: ''
    },
    /** Label text */
    label: {
      type: String,
      default: ''
    },
    /** Whether ordered */
    ordered: {
      type: Boolean,
      default: false
    },
    /** External validation function */
    validator: {
      type: Function,
      default: null
    },
    /** Whether required */
    required: {
      type: Boolean,
      default: false
    },
    /** Texts */
    texts: {
      type: Array,
      default: () => ['', '']
    }
  },
  computed: {
    /** Index number in the array */
    index () {
      if (Array.isArray(this.$_octSwitch_value)) // model is array
        return this.$_octSwitch_value.indexOf(this.value)

      return -1
    },
    /** Delegate value */
    $_octSwitch_value: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    /** Input validation */
    async validate (val) {
      // Empty check
      let invalid = this.required &&
          !this.$el.querySelector('.oct-switch__native-control').checked

      // Finally do the external validation
      if (!invalid && this.validator)
        invalid = !await this.validator(val)

      return !invalid
    },
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-switch {
  display: inline-flex;
  align-items: center;
}

.oct-switch__anchor {
  height: oct-rem(44);
  position: relative;
}

.oct-switch__native-control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.oct-switch__text {
  position: relative;
  display: flex;
  height: 100%;
  padding: 0 oct-rem(4);
  align-items: center;
  z-index: 1;
}

.oct-switch__primary-text {
  font-size: oct-rem(14);
  line-height: 1;
  text-align: center;
  display: block;
  padding: 0 oct-rem(22);
}

.oct-switch__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $oct-theme--neutral-20;
  border-radius: oct-rem(44/2);
  transition-duration: .3s;

  .oct-switch__native-control:checked + & {
    //background-color: $oct-theme--primary;
  }
}

.oct-switch__checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: oct-rem(4);
  margin: auto;
  width: 50%;
  width: calc(50% - oct-rem(4));
  height: oct-rem(36);
  border-radius: oct-rem(36/2);
  background-color: #fff;
  transition-duration: .3s;

  .oct-switch__native-control:checked + .oct-switch__background & {
    transform: translateX(100%);
  }
}

.oct-switch__label-text {
  font-size: oct-rem(17);
  line-height: (24/17);
  letter-spacing: 0;
  margin: (-5em/17) (10em/17) (-5em/17) 0;
  display: block;
}
</style>
