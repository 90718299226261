<template>
  <compnent
    :is="href === '' ? tagName : 'a'"
    class="oct-button"
    :class="[
      {
        'oct-button--outlined': outline,
        'oct-button--block': block,
        'oct-button--square': !$scopedSlots.default && !text && icon,
      },
      `oct-button--${type}`,
      `oct-button--${size}`,
      `oct-button--align-${align}`
    ]"
    :href="href === '' ? false : href"
  >
    <oct-icon
      class="oct-button__leading-icon"
      :icon="icon" v-if="icon"
    />

    {{ text }}
    <slot />

    <div
      class="oct-button__trailing-icon"
      v-if="$scopedSlots['trailing-icon']"
    >
      <slot name="trailing-icon"/>
    </div>
  </compnent>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctButton',
  components: { OctIcon },
  props: {
    /** Tag name */
    tagName: {
      type: String,
      default: 'button'
    },
    /** Button text */
    text: {
      type: String,
      default: ''
    },
    /** Href */
    href: {
      type: String,
      default: ''
    },
    /** Icon ID to display */
    icon: {
      type: String,
      default: null
    },
    /** Type of button [default | primary | danger | link | google | twitter | facebook | line | processing] */
    type: {
      type: String,
      default: 'default'
    },
    /** Text alignment [center | left | right] */
    align: {
      type: String,
      default: 'center'
    },
    /** Whether outlined */
    outline: {
      type: Boolean,
      default: false
    },
    /** Size [small, medium, large] */
    size: {
      type: String,
      default: 'medium'
    },
    /** Block */
    block: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-button {
  font-size: oct-rem(16);
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  color: $oct-theme--on-primary;
  position: relative;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: oct-rem(48);
  padding: 0 oct-rem(24);
  background-color: $oct-theme--neutral-70;
  border: oct-rem(1) solid $oct-theme--neutral-70;
  border-radius: 0;
  outline: none;
  box-sizing: border-box;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  transition-duration: .2s;

  &:active, &--hover {
    opacity: .3;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--primary {
    color: $oct-theme--on-primary;
    background-color: $oct-theme--primary;
    border: oct-rem(1) solid $oct-theme--primary;

    &:hover, &.oct-button--hover {
      color: $oct-theme--primary;
      background-color: transparent;
    }
  }

  &--danger {
    color: $oct-theme--on-primary;
    background-color: $oct-theme--danger;
    border: oct-rem(1) solid $oct-theme--danger;

    &:hover, &.oct-button--hover {
      color: $oct-theme--on-primary;
      background-color: #D71920;
      border-color: transparent;
    }
  }

  &--on-primary {
    color: $oct-theme--primary;
    border-color: $oct-theme--on-primary;
    background-color: $oct-theme--on-primary;

    &:hover, &.oct-button--hover {
      color: $oct-theme--on-primary;
      background-color: $oct-theme--primary;
      border-color: $oct-theme--primary;
    }
  }

  &--on-primary.oct-button--outlined {
    color: $oct-theme--on-primary;
    border-color: $oct-theme--on-primary;
    background-color: transparent;

    &:hover, &.oct-button--hover {
      //color: $oct-theme--primary;
      //background-color: $oct-theme--on-primary;
      //border-color: $oct-theme--on-primary;
    }
  }

  &--outlined {
    color: $oct-theme--neutral;
    background-color: transparent;

    &.oct-button--danger {
      color: $oct-theme--danger;
      background-color: transparent;
      border-color: $oct-theme--danger;
    }
  }

  &--link {
    border-color: transparent;
    background-color: transparent;

    &:hover {
      //color: $oct-theme--primary;
      //border-color: transparent;
      //background-color: transparent;
    }
  }

  &--link-danger {
    color: $oct-theme--danger;
    border-color: transparent;
    background-color: transparent;
  }

  &[disabled] {
    color: $oct-theme--on-primary;
    background-color: $oct-theme--neutral-20;
    border-color: transparent;
    pointer-events: none;
  }

  &--outlined[disabled] {
    color: $oct-theme--neutral-20;
    border-color: $oct-theme--neutral-20;
    background-color: transparent;
  }

  &--block {
    width: 100%;
  }

  &--small {
    font-size: oct-rem(14);
    height: oct-rem(36);
    padding: 0 oct-rem(18);
  }

  &--large {
    font-size: oct-rem(20);
    height: oct-rem(60);
  }

  &--square {
    width: oct-rem(48);
    padding: 0;

    .oct-button__leading-icon {
      width: oct-rem(24);
      height: oct-rem(24);
      left: auto;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(16);
  height: oct-rem(48);

  &:hover {
    opacity: .3;
  }

  &--primary {
    padding: 0 14px;
  }

  &--small {
    font-size: oct-rem(14);
    height: oct-rem(36);
    padding: 0 oct-rem(16);
  }

  &--large {
    font-size: oct-rem(20);
    height: oct-rem(60);
    padding: 0;
  }
}
}

.oct-button__leading-icon {
  position: absolute;
  top: 0;
  left: oct-rem(15);
  bottom: 0;
  margin: auto;
  width: oct-rem(32);
  height: oct-rem(32);

  .oct-button--small & {
    width: oct-rem(16);
    height: oct-rem(16);
  }
}

.oct-button-group {
  background-color: $oct-theme--surface;
  border-radius: 15px;

  .oct-button {
    border-bottom: .99px solid $oct-theme--secondary-border;
    border-radius: 0;

    &:first-child {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }

    &:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  .oct-button--square {
    &:first-child {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }

    &:last-child {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  &--horizontal {
    display: inline-flex;
    flex-wrap: nowrap;

    .oct-button {
      flex: 1 1 auto;
      border-bottom-width: 0;
      border-right: .99px solid #e3e3e3;
    }

    .oct-button--square {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        border-width: 0;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.oct-button__trailing-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: oct-rem(13);
  margin: auto;
  width: oct-rem(32);
  height: oct-rem(32);

  .oct-icon {
    fill: $oct-theme--on-primary;
  }

  .oct-button--outlined & {
    .oct-icon {
      fill: $oct-theme--neutral-70;
    }
  }
}
</style>
