<template>
  <div class="oct-table" @click="handleOutsideClick">
    <!-- Table -->
    <table class="oct-table__table">
      <!-- Header-->
      <thead class="oct-table__header">
        <tr
          v-for="(headerRow, rowIndex) in headers"
          :key="rowIndex"
          class="oct-table__header-row"
        >
          <th
            v-for="(header, index) in headerRow"
            :key="index"
            :colspan="header.colspan || 1"
            :class="{
              'oct-table__header-cell--fixed': isFixedColumn(index, headerRow),
              'oct-table__header-cell--end': isFixedColumn(index, headerRow) && !isFixedColumn(index+1, headerRow)
            }"
            class="oct-table__header-cell"
            :style="getCellStyle(index, headerRow)"
            ref="headerCells"
          >
            <div class="oct-table__text-container">
              <span class="oct-table__cell-text">
                {{ header.text }}
              </span>

              <oct-icon
                icon="filter"
                v-if="rowIndex === headers.length - 1 && filters && filters[index]"
                @click.native="toggleFilterMenu(index, $event)"
                class="oct-table__filter-icon"
              />
            </div>
          </th>
        </tr>
      </thead>

      <!-- Body -->
      <tbody class="oct-table__body">
        <tr
          v-for="(row, rowIndex) in tableData"
          :key="rowIndex"
          class="oct-table__row"
          ref="firstRow"
          @click="$emit('click:row', row)"
        >
          <td
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            :class="{
              'oct-table__cell--fixed': isFixedColumn(cellIndex, tableData[0]),
              'oct-table__cell--end': isFixedColumn(cellIndex, tableData[0]) && !isFixedColumn(cellIndex+1, tableData[0])
            }"
            class="oct-table__cell"
            :data-text="cell"
            :style="getCellStyle(cellIndex, tableData[0])"
          >
            <div class="oct-table__text-container">
              <span class="oct-table__cell-text">
                {{ cell }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Menu -->
    <div
      v-if="isFilterMenuVisible"
      :style="filterMenuStyle"
      class="oct-table__filter-menu"
      @click.stop
    >
      <div
        v-for="(group, groupIndex) in filters[activeFilterIndex]"
        :key="groupIndex"
        class="oct-table__filter-menu-section"
      >
        <!-- Heading -->
        <div
          v-if="group.groupName"
          class="oct-table__filter-menu-heading"
        >
          {{ group.groupName }}
        </div>

        <div
          v-for="(item, itemIndex) in group.items"
          :key="itemIndex"
          @click="menuItemClicked(item)"
          class="oct-table__filter-menu-item"
          :class="{ 'oct-table__filter-menu-item--active': item.active }"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'

export default {
  name: "OctTable",
  components: {
    OctIcon
  },
  props: {
    /**
     * The headers for the table columns. It can support multiple rows of headers.
     * Each header object should contain `text` and optionally `colspan`.
     * @type {Array}
     */
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The data to be displayed in the table.
     * @type {Array}
     */
    tableData: {
      type: Array,
      required: true
    },
    /**
     * Definitions for the filters of the columns.
     * Each filter object should contain `groupName` and `items`.
     * Each item object should contain `text`, `active`, and `payload`.
     * @type {Array}
     */
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The number of columns to be fixed.
     * @type {Number}
     */
    fixedColumns: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      isFilterMenuVisible: false,
      filterMenuStyle: {
        top: '0px',
        left: '0px'
      },
      activeFilterIndex: null,
      columnWidths: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateColumnWidths();
    });
    window.addEventListener('resize', this.calculateColumnWidths);
    window.addEventListener('resize', this.handleScroll);
    this.$el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateColumnWidths);
    window.removeEventListener('resize', this.handleScroll);
    this.$el.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    headers() {
      this.$nextTick(() => {
        this.calculateColumnWidths();
      });
    },
    tableData() {
      this.$nextTick(() => {
        this.calculateColumnWidths();
      });
    }
  },
  methods: {
    /**
     * Toggles the visibility of the filter menu.
     * @param {number} index - The index of the column to filter.
     * @param {Event} event - The click event.
     */
    toggleFilterMenu(index, event) {
      event.stopPropagation(); // Stop the event from propagating to the parent div
      const buttonRect = event.target.getBoundingClientRect();
      const tableRect = this.$el.getBoundingClientRect();
      const scrollLeft = this.$el.scrollLeft;
      this.activeFilterIndex = index;
      this.isFilterMenuVisible = !this.isFilterMenuVisible;
      this.filterMenuStyle = {
        top: `${buttonRect.bottom - tableRect.top}px`,
        left: `${buttonRect.left - tableRect.left + scrollLeft}px`
      };
    },
    /**
     * Emits an event when a filter menu item is clicked.
     * @param {Object} item - The clicked menu item.
     */
    menuItemClicked(item) {
      this.$emit('click:menu', item.payload);
      this.isFilterMenuVisible = false;
    },
    /**
     * Handles click events outside the filter menu to hide it.
     */
    handleOutsideClick() {
      this.isFilterMenuVisible = false;
    },
    /**
     * Handles scroll events to hide the filter menu.
     */
    handleScroll() {
      if (this.isFilterMenuVisible) {
        this.isFilterMenuVisible = false;
      }
    },
    /**
     * Checks if a column is a fixed column, considering colspan.
     * @param {number} index - The index of the column.
     * @param {Array} row - The row to check.
     * @returns {boolean} - True if the column is fixed, false otherwise.
     */
    isFixedColumn(index, row) {
      let count = 0;
      for (let i = 0; i < row.length; i++) {
        const colspan = row[i].colspan || 1;
        count += colspan;
        if (index < count) {
          return i < this.fixedColumns;
        }
      }
      return false;
    },
    /**
     * Returns the style for a fixed column cell, considering colspan.
     * @param {number} index - The index of the column.
     * @param {Array} row - The row to check.
     * @returns {Object} - The style object.
     */
    getCellStyle(index, row) {
      if (this.isFixedColumn(index, row)) {
        let left = 0;
        let count = 0;
        for (let i = 0; i < row.length; i++) {
          const colspan = row[i].colspan || 1;
          count += colspan;
          if (index < count) {
            break;
          }
          if (this.columnWidths[i]) {
            left += this.columnWidths[i];
          }
        }
        return {
          left: `${left}px`
        };
      }
      return {};
    },
    /**
     * Calculates the widths of each column and stores them in an array.
     */
    calculateColumnWidths() {
      this.$nextTick(() => {
        if (this.$refs.firstRow && this.$refs.firstRow.length > 0) {
          const cells = this.$refs.firstRow[0].children;
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            const colspan = cell.colspan || 1;
            let width = 0;
            for (let j = 0; j < colspan; j++) {
              width += cell.getBoundingClientRect().width;
            }
            this.$set(this.columnWidths, i, width);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

/* Containers */
.oct-table {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.oct-table__table {
  width: 100%;
  border-collapse: collapse;
}

.oct-table__text-container {
  display: flex;
  gap: oct-rem(6);
  justify-content: center;
  align-items: center;
}

/* Texts */
.oct-table__header-row {
  font-size: oct-rem(12);
  line-height: (16/12);
  color: $oct-theme--neutral-50;
}

.oct-table__row {
  font-size: oct-rem(16);
  line-height: (19/16);
  line-height: 1;
}

.oct-table__cell-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: oct-rem(6);
  align-items: center;
  overflow: hidden;
}

.oct-table__filter-menu-heading {
  font-size: oct-rem(12);
  line-height: 1.2;
}

/* Elements */
.oct-table__header-cell,
.oct-table__cell {
  font-weight: normal;
  text-align: center;
  position: relative;
  padding: oct-rem(25.5) oct-rem(16);
  vertical-align: middle;
  background-color: $oct-theme--neutral-10;
  border-top: oct-rem(1) solid $oct-theme--neutral-20;

  &:first-child,
  .oct-table__header-cell--end + &,
  .oct-table__cell--end + & {
    padding-left: oct-rem(32);
  }

  &:last-child, &--end {
    padding-right: oct-rem(32);
  }

  &--fixed {
    position: sticky;
    z-index: 2;
    background-color: $oct-theme--surface;
  }

  .oct-table__header-row:first-child & {
    border-width: 0;
  }
}

.oct-table__header-cell {
  padding-top: oct-rem(16);
  padding-bottom: oct-rem(16);
  border-width: 0;
}

.oct-table__filter-icon {
  width: oct-rem(16);
  height: oct-rem(16);
  fill: $oct-theme--neutral-50;
  cursor: pointer;
}

.oct-table__filter-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  padding: oct-rem(32);
  background-color: $oct-theme--surface;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.oct-table__filter-menu-section {
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  padding-bottom: oct-rem(16);
  border-bottom: oct-rem(1) solid $oct-theme--neutral-20;

  &:last-child {
    padding-bottom: 0;
    border-width: 0;
  }
}

.oct-table__filter-menu-item {
  display: flex;
  align-items: center;
  gap: oct-rem(8);
  min-width: oct-rem(172);
  cursor: pointer;
  height: oct-rem(19);
}

.oct-table__filter-menu-item--active {
  font-weight: bold;
}
</style>
