import OctIcon from '@/components/icon/OctIcon.vue'

export default {
  components: {
    OctIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'close'
    },
    action: {
      type: Object,
      default: () => ({
        text: '',
        type: 'danger'
      })
    },
    /**
     * Dialog alignment
     * Available values are [left|center|right]
     */
    align: {
      type: String,
      default: 'center'
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  data: () =>({
  }),
}