import md5 from 'blueimp-md5'

export default {
  name: 'OctPrototypeView',
  data: () => ({
    //Indicate processing
    processing: false
  }),
  methods: {
    /** Convert a item into a list item */
    itemToListItem (item, wantsTime=false, useProfileIcon=true) {
      // Default customer icon
      const defaultIcon =  {
        lowResolution: '/images/customer__default.png',
        standardResolution: '/images/customer__default.png',
        highResolution: '/images/customer__default.png'
      }

      // Customer
      const customer = this.$store.getters['customers/byId'](item.customer)

      return {
        id: item.id,
        // Media
        media: {
          // Profile icon and treatment photo may not exist
          image: useProfileIcon ?
            // Profile icon
            customer && customer.profileIcon || defaultIcon :
            // Treatment photo
            item.images.find(i => i.lowResolution) || defaultIcon
        },
        // Customer name. Customers may not exist.
        title: customer && customer.name || '',
        text: this.$_octPrototypeView_getDateString(item.publishedAt, wantsTime),
        status: item.status
      }
    },
    /** Convert a brand into a list item */
    brandToListItem (item) {
      const images = {
        'Aujua': {
          lowResolution: '/images/brand__aujua.jpg',
          standardResolution: '/images/brand__aujua.jpg',
        },
        'milbon': {
          lowResolution: '/images/brand__milbon.jpg',
          standardResolution: '/images/brand__milbon.jpg',
        },
        'villalodola': {
          lowResolution: '/images/brand__villa-lodora.jpg',
          standardResolution: '/images/brand__villa-lodora.jpg',
        },
        'Villa Lodola': {
          lowResolution: '/images/brand__villa-lodora.jpg',
          standardResolution: '/images/brand__villa-lodora.jpg',
        },
        'iMPREA': {
          lowResolution: '/images/brand__imprea.jpg',
          standardResolution: '/images/brand__imprea.jpg',
        },
        'PJOLI': {
          lowResolution: '/images/brand__pjoli.jpg',
          standardResolution: '/images/brand__pjoli.jpg',
        },
        'LASSICAL': {
          lowResolution: '/images/brand__lassical.jpg',
          standardResolution: '/images/brand__lassical.jpg',
        }
      }

      return {
        id: item.id,
        media: {
          image: images[item.name_en] || undefined
        },
        title: item.name_en,
        text: item.name || (item.name_en === 'milbon' ? 'ミルボン' : item.name_en),
        reverse: true
      }
    },
    /** Convert a product into a list item */
    productToListItem (item=null) {
      return {
        id: item.id,
        media: item.image ? {
          image: {
            standardResolution: item.image,
            lowResolution: item.image,
          }
        } : undefined,
        icon: !item.image ? this.$_octPrototypeView_getCategoryIconById((item.categories ? item.categories[0] : {id: 0}).id) : undefined,
        title: item.name || (item.categories ? item.categories[0] : {name: ''}).name,
        // Eyebrow. Add sub brand name for products in milbon brand
        text: item.id && item.categories.length ?
          `${item.categories[0].name}${item.brand_master && item.brand_master.name_en === 'milbon' ? `／${item.brand_product.name}` : ''}` :
          undefined,
        dense: item.id ? true : false
      }
    },
    /** Convert item id to  hash */
    idToHash: (id) => md5(id),
    /** Category icon by ID */
    $_octPrototypeView_getCategoryIconById (id) {
      return ['', 'pump', 'bottle', 'dispenser', 'dispenser'][id]
    },
    /** Get customer by ID */
    $_octPrototypeView_getCustomerById () {
      return this.$store.state.customer
    },
    $_octPrototypeView_getDateString (val, time=false) {
      const t = time ? ` ${val.getHours()}:${this.$_octPrototypeView_pad(val.getMinutes())}` : ''
      return `${val.getFullYear()}/${val.getMonth()+1}/${val.getDate()}（${this.$_octPrototypeView_getDayOfWeekString(val.getDay())}）${t}`
    },
    $_octPrototypeView_getDateParameter (val, time=false) {
      const t = time ? ` ${val.getHours()}:${this.$_octPrototypeView_pad(val.getMinutes())}` : ''
      return `${val.getFullYear()}-${this.$_octPrototypeView_pad(val.getMonth()+1)}-${this.$_octPrototypeView_pad(val.getDate())}${t}`
    },
    $_octPrototypeView_getDayOfWeekString: (val) => (
      ['日', '月', '火', '水', '木', '金', '土'][val]
    ),
    $_octPrototypeView_pad: (num, d=2) => (
      (Math.pow(10, d) + Number(num)).toString().substr(1, d)
    ),
    $_octPrototypeView_getRoleNameById: (id) => (
      [
        '',
        'オーナーアカウント',
        'スタイリスト',
        'アシスタント',
        'スタッフ'
      ][id]
    ),
    $_octPrototypeView_getCategoryNameById: (id) => (
      {
        shampoos: 'シャンプー',
        treatments: 'トリートメント',
        outbath: 'アウトバス',
        styling: 'スタイリング',
        skincare: 'スキンケア',
        make: 'メイク',
      }[id]
    ),
    $_octPrototypeView_getProfileIconSafely: (val) => (
      val && val.lowResolution ? val : {
        highResolution: '/images/customer__default.png',
        standardResolution: '/images/customer__default.png',
        lowResolution: '/images/customer__default.png',
      }
    ),
    $_octPrototypeView_goBack (n=-1) {
      if (this.$store.state.session.history.depth)
        this.$router.go(n)
      else
        this.$router.push({name: 'Dashboard'}).catch(() => {})
    }
  },
  computed: {
    /** Transition name */
    transitionName: vm => vm.$store.state.session.history.action
  }
}