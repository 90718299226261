import { render, staticRenderFns } from "./SSPasswordResetViewDone.vue?vue&type=template&id=7e18bccd&scoped=true&"
import script from "./SSPasswordResetViewDone.vue?vue&type=script&lang=js&"
export * from "./SSPasswordResetViewDone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e18bccd",
  null
  
)

export default component.exports