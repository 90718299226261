<template>
  <div id="app">
    <oct-snackbar />

    <router-view/>

    <!-- Confirm Dialog -->
    <oct-dialog
      class="app-dialog--confirm"
      v-if="$store.state.confirmation"
    >
      {{ $store.state.confirmation.message }}

      <template #actions>
        <oct-button
          outline
          size="small"
          @click.native="$_app_confirm(false)"
        >
          キャンセル
        </oct-button>

        <oct-button
          size="small"
          @click.native="$_app_confirm(true)"
        >
          OK
        </oct-button>
      </template>
    </oct-dialog>

    <oct-svg/>
  </div>
</template>

<script>
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctSnackbar from '@/components/snackbar/OctSnackbar'
import OctSvg from '@/components/svg/OctSvg'
import OctDialog from '@/components/dialog/OctDialog.vue'

export default {
  name: 'App',
  components: {
    OctButton,
    OctSnackbar,
    OctDialog,
    OctSvg
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .catch(() => {})
        .finally(() => this.$router.replace({name:'Login'}).catch(() => {}))
    },
    /** Process confirmation */
    $_app_confirm (val=true) {
      // Do process
      val ? this.$store.state.confirmation.resolve() : this.$store.state.confirmation.reject()
      // Close dialog
      this.$store.commit('state', {confirmation: null})
    }
  },
  created () {
    // Subscribe mutation
    this.$store.subscribe(mutation => {
      // Case of error
      if (mutation.type === 'error' && mutation.payload)
        switch (mutation.payload.code) {
          case 100:
            return this.$store.dispatch('alert', mutation.payload)
          case '400':
          case '401': // Refresh token expired
          case '403':
          case 403:
            return this.signOut()
          case 404:
            this.$store.dispatch('alert', mutation.payload.message)
            return this.$router.replace({name:'Dashboard'}).catch(() => {})
          case 500:
            this.$store.dispatch('alert', mutation.payload.message)
            return this.signOut()
          default:
            console.error(mutation.payload)
            alert(mutation.payload.message)
        }
    })
  }
}
</script>

<style lang="scss">
@import 'assets/app.scss';

.app-dialog--confirm {
  .oct-dialog__surface {
    width: oct-rem(460);
  }

  .oct-dialog__content {
    white-space: pre-line;
  }

  .oct-dialog-header {
    height: oct-rem(20) !important;
  }

  .oct-dialog-header__icon {
    display: none;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .oct-dialog-header {
    height: oct-rem(32) !important;
  }
}
}
</style>
