<template>
  <div class="oct-customer-card">
    <oct-media
      :image="{
        id: icon.id,
        lowResolution: icon.lowResolution,
        standardResolution: icon.standardResolution,
        status: icon.status
      }"
      class="oct-customer-card__media"
    />

    <div class="oct-customer-card__text">
      <h3 class="oct-customer-card__primary-text">
        {{ title }}
      </h3>

      <span
        class="oct-customer-card__secondary-text"
        v-if="text"
      >
        {{ text }}
      </span>

      <oct-chip
        :selected="meta.active"
        class="oct-customer-card__chip"
        v-if="meta.text"
      >
        {{ meta.text }}
      </oct-chip>

      <!-- History link -->
      <span
        class="oct-customer-card__link"
        v-if="history"
        @click="$emit('click:history')"
      >
        過去の施術履歴
        <oct-icon icon="chevron-right" />
      </span>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctMedia from '@/components/media/OctMedia.vue'

export default {
  components: {
    OctIcon,
    OctChip,
    OctMedia
  },
  name: 'OctCustomerCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    meta: {
      type: Object,
      default: () => ({
        text: '',
        active: true
      })
    },
    icon: {
      type: Object,
      default: () => ({
        highResolution: '/images/customer__default.png',
        standardResolution: '/images/customer__default.png',
        lowResolution: '/images/customer__default.png',
      })
    },
    /** Indicate show a history link */
    history: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-customer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.oct-customer-card__media {
  margin: 0 0 oct-rem(16);
  width: oct-rem(80);
  height: oct-rem(80);
  padding-top: 0;
}

.oct-customer-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oct-customer-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.oct-customer-card__primary-text {
  font-size: oct-rem(32);
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 oct-rem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.oct-customer-card__secondary-text {
  font-size: oct-rem(12);
  line-height: 1;
  color: $oct-theme--neutral-50;
  margin: 0 0 oct-rem(16);
  display: block;
}

.oct-customer-card__chip {
  margin: 0 0 oct-rem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.oct-customer-card__link {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--primary;
  fill: $oct-theme--primary;
  margin: 0 0 oct-rem(16);
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .oct-icon {
    margin: 0 0 0 oct-rem(4);
    width: oct-rem(20);
    height: oct-rem(20);
  }
}
</style>