<template>
  <oct-dialog
    full
    title="施術情報の絞り込み"
    :icon="'arrow-left'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-treatment-filter"
  >
    <oct-form class="ss-treatment-filter__content">
      <!-- Sort Order -->
      <oct-form-group
        label="並び順"
      >
        <oct-form-row>
          <oct-select
            block
            outline
            dense
            v-model="payload.sort"
            :items="[
              {
                value: 'desc',
                text: '新しい順'
              },
              {
                value: 'asc',
                text: '古い順'
              }
            ]"
          />
        </oct-form-row>
      </oct-form-group>

      <!-- Stylists -->
      <oct-form-group
        label="スタイリスト"
        v-if="$store.state.staffs.me.isAdministrator"
      >
        <oct-form-row>
          <oct-select
            block
            outline
            dense
            v-model="stylist"
            :items="[{
              value: '',
              text: 'スタイリストを選択'
            }].concat($store.state.staffs.all.filter(item => item.role === 2).map(item => ({
              value: item.id,
              text: `${item.lastName} ${item.firstName}`
            })))"
          />
        </oct-form-row>
      </oct-form-group>

      <oct-form-group
        label="ステータス順に絞り込み"
      >
        <oct-form-row>
          <oct-radio
            value=""
            v-model="payload.status"
            label="すべて"
          />
        </oct-form-row>

        <oct-form-row>
          <oct-radio
            :value="3"
            v-model="payload.status"
            label="未送信"
          />
        </oct-form-row>

        <oct-form-row>
          <oct-radio
            :value="4"
            v-model="payload.status"
            label="送信済"
          />
        </oct-form-row>
      </oct-form-group>

      <oct-form-group
        label="日付別に絞り込み"
      >
        <oct-form-row>
          <oct-radio
            value=""
            v-model="datestart"
            label="すべての施術履歴"
          />
        </oct-form-row>

        <oct-form-row>
          <oct-radio
            :value="dateToString(new Date(today.getTime() - 48*3600*1000))"
            v-model="datestart"
            label="過去48時間以内"
          />
        </oct-form-row>

        <oct-form-row>
          <oct-radio
            :value="dateToString(new Date(today.getTime() - 30*24*3600*1000))"
            v-model="datestart"
            label="過去30日"
          />
        </oct-form-row>

        <oct-form-row>
          <oct-radio
            :value="dateToString(new Date(today.getTime() - 90*24*3600*1000))"
            v-model="datestart"
            label="過去90日"
          />
        </oct-form-row>

        <oct-form-row
          v-for="i in [0,1]"
          :key="`y${i}`"
        >
          <oct-radio
            :value="`${today.getFullYear()-i}/01/01`"
            v-model="datestart"
            :label="`${today.getFullYear()-i}年`"
          />
        </oct-form-row>
      </oct-form-group>

      <oct-form-group
        label="日付範囲で絞り込み"
      >
        <oct-form-row>
          <oct-form-col :cols="2">
            <oct-text-field
              type="date"
              placeholder="開始日"
              block
              v-model="datestart"
            />
          </oct-form-col>

          <oct-form-col :cols="2">
            <oct-text-field
              type="date"
              placeholder="終了日"
              block
              v-model="dateend"
            />
          </oct-form-col>
        </oct-form-row>
      </oct-form-group>
    </oct-form>

    <template #actions>
      <oct-button
        block
        size="large"
        @click.native="submit"
      >
        絞り込み
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctRadio  from '@/components/radio/OctRadio.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SSTreatmentListFilterView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctRadio,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctFormRow,
    OctFormCol,
    OctDialog
  },
  props: {
    /** Filter */
    item: {
      type: Object,
      default: () => ({
        /** User IDs */
        id: [],
        /** Sort order */
        order: 'desc',
        /** Start date */
        datestart: '',
        /** End date */
        dateend: '',
        /** Status */
        status: '',
        /** Page */
        page: 1
      })
    }
  },
  data () {
    return {
      /** Today */
      today: new Date(new Date().setHours(0, 0, 0, 0)),
      /** Payload */
      payload: {
        id: this.item.id,
        sort: this.item.sort,
        datestart: this.item.datestart,
        dateend: this.item.dateend,
        status: this.item.status,
        page: this.item.page
      }
    }
  },
  computed: {
    /** Stylist ID */
    stylist: {
      get () {
        return this.payload.id.length ? this.payload.id[0] : ''
      },
      set (val) {
        this.payload.id = val ? [val] : []
      }
    },
    /** Start date */
    datestart: {
      get () {
        return this.payload.datestart ? this.dateToString(this.payload.datestart) : ''
      },
      set (val) {
        this.payload.datestart = val ? new Date(val) : ''
      }
    },
    /** End date */
    dateend: {
      get () {
        return this.payload.dateend ? this.dateToString(this.payload.dateend) : ''
      },
      set (val) {
        this.payload.dateend = val ? new Date(val) : ''
      }
    },
    ...mapGetters({
      /** Filter */
      filter: 'treatments/filter/state'
    })
  },
  methods: {
    submit () {
      this.$store.commit('treatments/filter/state', this.payload)
      this.$emit('update:item', this.payload)
      this.$_octPrototypeView_goBack()
    },
    /** Date to string */
    dateToString: val => `${val.getFullYear()}-${(val.getMonth()+1).toString().padStart(2, 0)}-${val.getDate().toString().padStart(2, 0)}`
  },
  created () {
    this.payload = {
      id: this.filter.id,
      sort: this.filter.sort,
      datestart: this.filter.datestart,
      dateend: this.filter.dateend,
      status: this.filter.status,
      page: 1
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-treatment-filter__content {
  &::v-deep .oct-form-group__row {
    flex-direction: column;
    align-items: flex-start;
  }

  &::v-deep .oct-form-group {
    margin-bottom: oct-rem(48);
  }

  &::v-deep .oct-form-group__label {
    margin-bottom: oct-rem(16);
  }

  &::v-deep .oct-form__row:last-child {
    margin-bottom: 0;
  }
}
</style>