<template>
  <div
    class="oct-text-field"
    :class="{
      'oct-text-field--dense': dense,
      'oct-text-field--block': block,
      'oct-text-field--with-leading-icon': $scopedSlots['leading-icon']
    }"
  >
    <div
      class="oct-text-field__leading-icon"
      v-if="$scopedSlots['leading-icon']"
    >
      <slot name="leading-icon"/>
    </div>

    <i
      class="oct-text-field__trailing-icon"
      @click="toggleVisibility"
      v-if="type === 'password'"
    >
      <oct-icon icon="visibility" v-if="!visibility" />
      <oct-icon icon="visibility-off" v-else />
    </i>

    <input
      class="oct-text-field__input"
      :id="id"
      :name="name"
      :type="(type === 'postal-code' || (type === 'date' && !$_octTextField_value)) ? 'text' : (type === 'password' && visibility ? 'text' : type)"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :size="cols*2"
      :pattern="pattern"
      :disabled="disabled"
      :required="required"
      :aria-invalid="invalid.toString()"
      v-model.lazy="$_octTextField_value"
      v-if="rows <= 1"
      @touchstart="onTouchStart"
      @focus="onTouchStart"
      @blur="onBlur"
      @change="$emit('change', $event)"
      @input="onInput"
      ref="$_octTextField_input"
    />

    <textarea
      class="oct-text-field__input"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :rows="rows"
      :cols="cols*2"
      :pattern="pattern"
      :disabled="disabled"
      :required="required"
      :aria-invalid="invalid.toString()"
      v-model.lazy="$_octTextField_value"
      v-else
      @change="$emit('change', $event)"
      @input="onInput"
      ref="$_octTextField_input"
    />

    <div
      class="oct-text-field-helper-line"
      v-if="$slots.helperText"
    >
      <span class="oct-text-field-helper-text">
        <slot name="helperText" />
      </span>
    </div>

    <div
      class="oct-text-field-helper-line oct-text-field-helper-line--invalid"
      v-if="invalid && $slots.helperTextInvalid"
    >
      <span class="oct-text-field-helper-text">
        <slot name="helperTextInvalid" />
      </span>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctTextField',
  components: {
    OctIcon
  },
  props: {
    /** Id attribute for native control */
    id: {
      type: [String,Number],
      default: null
    },
    /** Name attribute for native control */
    name: {
      type: [String,Number],
      default: null
    },
    /** Text Value */
    value: {
      type: [String,Number],
      default: ''
    },
    /** Placeholder Text */
    placeholder: {
      type: String,
      default: ''
    },
    /** Native pattern attribute */
    pattern: {
      type: String,
      default: undefined
    },
    /** Native autocomplete attribute */
    autocomplete: {
      type: String,
      default: 'off'
    },
    /** Text field width */
    cols: {
      type: Number,
      default: 20
    },
    /** Whether enable multiline input */
    rows: {
      type: Number,
      default: 1
    },
    /** Input type [number|email|tel|postal-code] */
    type: {
      type: String,
      default: 'text'
    },
    /** External validation function */
    validator: {
      type: Function,
      default: null
    },
    /** Indicate to update lazy */
    lazy: {
      type: Boolean,
      default: true
    },
    /** Native disabled attribute */
    disabled: {
      type: Boolean,
      default: false
    },
    /** Whether required */
    required: {
      type: Boolean,
      default: false
    },
    /** Renderd as block element */
    block: {
      type: Boolean,
      default: false
    },
    /** Whether to display in densed size */
    dense: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      invalid: false,
      /** Visibility of password */
      visibility: false
    }
  },
  computed: {
    $_octTextField_value: {
      get () {
        return this.value
      },
      async set (val) {
        // Validate and emit
        await this.validate(val)
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /** Input validation */
    async validate (val=this.$_octTextField_value) {
      // Empty check
      let invalid = this.required && !val

      // Number check
      invalid = (this.type === 'number' && !this.validateNumber(val)) || invalid

      // Email check
      invalid = (this.type === 'email' && !this.validateEmail(val)) || invalid

      // Finally do the external validation
      if (!invalid && this.validator)
        invalid = !await this.validator(val)

      this.invalid = invalid

      // Set custome validity
      this.$refs['$_octTextField_input'] &&
        this.$refs['$_octTextField_input'].setCustomValidity(
          invalid ? 'Invalid Value' : ''
        )

      return !invalid
    },
    /** Number validateion */
    validateNumber (val) {
      return /^[.0-9]+$/.test(val)
    },
    /** Email validateion */
    validateEmail (val) {
      return /[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9_-]+[a-zA-Z0-9._-]+/.test(val)
    },
    /** Toggle password visibility */
    toggleVisibility () {
      this.visibility = !this.visibility
    },
    /** Touch start handler */
    onTouchStart (event) {
      // Set ‘type’ attribute of the native input to ‘date’ to launch date picker if ‘type’ property is ‘date’
      event.target.type = this.type === 'date' ? this.type : event.target.type
    },
    /** Blur handler */
    onBlur (event) {
      // Set ‘type’ attribute of the native input to ‘text’ if ‘type’ property is ‘date’ and value is empty.
      // Because date input field on iOS doesn't show placeholder text.
      if (this.type === 'date' && event.target.value === '')
        event.target.type = 'text'
    },
    /** Input handler */
    onInput () {
      // Dispatch change event if ‘lazy’ property is true, because chrome on iOS doesn't fire change event untile focus turns off
      !this.lazy &&
        Array.from(this.$el.querySelectorAll('input, textarea'))
          .forEach(item => item.dispatchEvent(new Event('change')))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-text-field {
  position: relative;
  display: inline-block;

  &--block {
    display: block;
    width: 100%;
  }
}

.oct-text-field__input {
  font-family: $font-family-sans-serif-ja;
  font-size: oct-rem(16);
  line-height: 1.5;
  margin: 0 0 oct-rem(8);
  padding: oct-rem(12-1) oct-rem(16-1);
  background-color: transparent;
  border: oct-rem(1) solid $oct-theme--neutral-20;
  border-radius: 0;
  appearance: none;
  outline: none;
  -webkit-font-smoothing: antialiased;

  .oct-text-field--with-leading-icon & {
    padding-left: oct-rem(24+16+16-1);
  }

  .oct-text-field--dense & {
    padding: oct-rem(8-1);
  }

  .oct-text-field--dense.oct-text-field--with-leading-icon & {
    padding-left: oct-rem(24+8+8-1);
  }

  .oct-text-field--block & {
    width: 100%;
  }

  &[disabled] {
    color: $oct-theme--neutral-30;
    background-color: $oct-theme--neutral-10;
  }

  &[type="number"] {
    -moz-appearance:textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
      appearance: none;
      margin: 0;
    }

    &[size="2"] {
      width: oct-rem(54);
    }

    &[size="3"] {
      width: oct-rem(68);
    }

    &[size="4"] {
      width: oct-rem(80);
    }

    &[size="5"] {
      width: oct-rem(120);
    }
  }

  &[type="date"] {
    color: inherit;
    text-align: left;
    height: oct-rem(48);

    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:focus {
    border-color: $oct-theme--primary;
  }

  &::placeholder {
    font-family: $font-family-sans-serif;
    color: $oct-theme--neutral-20;
    opacity: 1;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  &[aria-invalid="true"] {
    border-color: $oct-theme--danger;

    &::placeholder {
      color: $oct-theme--neutral-20;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
}
}

.oct-text-field__leading-icon {
  fill: $oct-theme--neutral-70;
  position: absolute;
  top: oct-rem(16);
  left: oct-rem(16);
  width: oct-rem(24);
  height: oct-rem(24);

  .oct-text-field--dense & {
    top: oct-rem(8);
    left: oct-rem(8);
  }

  .oct-icon {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}

.oct-text-field__trailing-icon {
  position: absolute;
  top: oct-rem(16);
  right: oct-rem(16);
  width: oct-rem(16);
  height: oct-rem(16);
  cursor: pointer;

  .oct-icon {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  [aria-invalid="true"] ~ & {
    fill: $oct-theme--danger;
    border-color: $oct-theme--danger;
  }
}

textarea.oct-text-field__input {
  height: auto;
  padding: oct-rem(12-1) oct-rem(16-1);
}

.oct-text-field-helper-line {
  color: $oct-theme--neutral-50;
  margin: 0 0 oct-rem(8);

  &:last-child {
    margin-bottom: 0;
  }

  [aria-invalid="true"] ~ &--invalid {
    color: $oct-theme--danger;
    opacity: 1;
  }
}

.oct-text-field-helper-text {
  font-size: oct-rem(12);
  line-height: (18/12);
  margin: 0;
  display: block;
}
</style>
