var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oct-user-form"},[(_vm.editableProperties.profileIcon)?_c('div',{staticClass:"oct-user-form__visual"},[_c('oct-media',{staticClass:"oct-user-form__media",attrs:{"alt":`${_vm.$data.$payload.lastName} ${_vm.$data.$payload.lastFirstName}`,"image":_vm.$_octPrototypeView_getProfileIconSafely(_vm.$data.$payload.profileIcon),"actions":_vm.$data.$payload.profileIcon.id ? ['remove'] : [],"dense":"","editable":""},on:{"upload:complete":_vm.onUploadStateChange,"remove":_vm.removeProfileIcon}})],1):_vm._e(),_c('oct-form',[_c('oct-form-row',[_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"姓"}},[_c('oct-text-field',{ref:"$_octUF_lastName",attrs:{"required":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 入力してください。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.lastName),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "lastName", $$v)},expression:"$data.$payload.lastName"}})],1)],1),_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"名"}},[_c('oct-text-field',{ref:"$_octUF_firstName",attrs:{"required":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 入力してください。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.firstName),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "firstName", $$v)},expression:"$data.$payload.firstName"}})],1)],1)],1),(!_vm.editableProperties.role || !(/(2|3)/.test(_vm.$data.$payload.role.toString())))?_c('oct-form-group',{attrs:{"label":"アカウント種別"}},[_c('oct-text-field',{attrs:{"value":_vm.$_octPrototypeView_getRoleNameById(_vm.$data.$payload.role),"disabled":""}})],1):_c('oct-form-group',{attrs:{"label":"アカウント種別"}},[_c('oct-select',{attrs:{"block":"","items":[
          {
            value: 2,
            text: 'スタイリスト'
          },
          {
            value: 3,
            text: 'アシスタント'
          }
        ]},model:{value:(_vm.$data.$payload.role),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "role", $$v)},expression:"$data.$payload.role"}})],1),_c('oct-form-group',{attrs:{"label":"メールアドレス"}},[_c('oct-text-field',{ref:"$_octUF_email",attrs:{"type":"email","disabled":!_vm.editableProperties.email},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" メールアドレスが正しくありません。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.email),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "email", $$v)},expression:"$data.$payload.email"}})],1),(_vm.$data.$payload.role !== 1 && _vm.editableProperties.admin)?_c('oct-form-group',[_c('oct-checkbox',{attrs:{"disabled":_vm.disabledProperties.admin,"label":"管理アカウントにする"},model:{value:(_vm.$data.$payload.isAdministrator),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "isAdministrator", $$v)},expression:"$data.$payload.isAdministrator"}})],1):_vm._e(),(_vm.$data.$payload.role === 1)?_c('oct-form-group',[_c('oct-checkbox',{attrs:{"label":"スタイリストとして表示する"},model:{value:(_vm.$data.$payload.data.isStylist),callback:function ($$v) {_vm.$set(_vm.$data.$payload.data, "isStylist", $$v)},expression:"$data.$payload.data.isStylist"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }