import Vue from 'vue'
import API from '@/api/index.js'

const state = () => {
  return {
    /** All items */
    all: [],
    /** Salon ID or User ID to determine which API to use */
    id: '',
    /** Comment type */
    type: 'users'
  }
}

/**
 * Comment class
 */
export class Comment {
  constructor (props={}) {
    this.message = '' // Message
    this.releaseAt = new Date() // Release date and time
    this.updatedAt = new Date() // Updated date and time
    this.default = false //  Indicate this comment is sat as default
    this.status = 4 // Comment status
    this.data = {} // Extra data

    // Set props
    this.set(props)
  }

  /** Set properties on the comment */
  set (props) {
    Object.assign(this, props, {
      // Normalize date properties
      releaseAt: props.releaseAt ? (props.releaseAt instanceof Date ? props.releaseAt : new Date(props.releaseAt)) : this.releaseAt,
      updatedAt: props.updatedAt ? (props.updatedAt instanceof Date ? props.updatedAt : new Date(props.updatedAt)) : this.updatedAt,
      // Normalize data
      data: props.data ? props.data : {}
    })

    this.releaseDate = `${this.releaseAt.getFullYear()}-${(this.releaseAt.getMonth() + 1).toString().padStart(2, 0)}-${this.releaseAt.getDate().toString().padStart(2, 0)}`
    this.releaseTime = `${this.releaseAt.getHours().toString().padStart(2, 0)}:${this.releaseAt.getMinutes().toString().padStart(2, 0)}`
  }

  /** Convert comment into a post parameter */
  toParameter () {
  }
}

// getters
const getters = {
  /** @return {object} A Comment specified by ID */
  byId: (state) => (id) => state.all.find(item => item.id === id),
  /** @return {array} Comments set as default */
  defaults: state => state.all.filter(item => item.default),
  /** @return {array} Comments filtered by function sat in the parameter */
  filter: state => filter => state.all.filter(filter)
}

// actions
const actions = {
  /* Fetch all items */
  async fetch ({commit, state}, {id}) {
    return API.getComments(id, state.type)
      .then(response => commit('all', response))
  },

  /** Save an item */
  async save ({state, commit}, payload) {
    // Save and add the response to the collection
    return API.updateComment(state.id, state.type, payload)
      .then(response => {
        // Add to the collection
        commit('add', [response])
        return response
      })
  },

  /**
   * Add an item at beginning of all
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  unshift ({commit, dispatch}, item) {
    // Save the item and add the item at the beginning of the collection
    return dispatch('save', item)
      .then(response => {
        // Remove an item if already exist
        commit('remove', [response])
        // Unshift the item
        commit('unshift', response)

        return response
      })
  },

  /** Update an item and bring it to it to the front */
  update ({commit, state}, item) {
    commit('state', {all: [item].cancat(state.all.filter(i => i.id !== item.id))})
  },

  /** Remove an item */
  remove ({state, commit}, payload) {
    // Remove comment and also remove from all at first
    return API.removeComment(state.id, state.type, payload)
      .then(response => {
        commit('remove', [payload])
        return response
      })
  }
}

// mutations
const mutations = {
  /**
   * Add items to the collection
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  add (state, items) {
    items.forEach(item => {
      // Index of the item
      const index = state.all.findIndex(i => i.id === item.id)
      // If found remove it
      index !== -1 ? state.all[index].set(item) : state.all.push(new Comment(item))
    })
  },

  /** Unshift an item */
  unshift (state, val) {
    state.all.unshift(new Comment(val))
  },

  /** Remove items from the collection */
  remove (state, items) {
    items.forEach(item => {
      // Index of the item
      const index = state.all.findIndex(i => i.id === item.id)
      // If found remove it
      index !== -1 && state.all.splice(index, 1)
    })
  },

  /** Update all */
  all (state, val) {
    Vue.set(state, 'all', val.map(item => new Comment(item)))
  },

  /** Update state */
  state (state, val) {
    Object.assign(state, val)
  },
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default create()