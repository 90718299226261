<template>
  <figure
    class="oct-media"
    :class="{
      'oct-media--dense': dense,
      'oct-media--natural': natural,
      'oct-media--processing': processing
    }"
  >
    <picture
      class="oct-media__picture"
      :class="{
        'oct-media__picture--empty': !image.lowResolution
      }"
    >
      <!-- Image source -->
      <source
        :srcset="$image.standardResolution"
        :media="`(min-width: ${breakPoint}px)`"
        class="oct-media__source"
      />

      <!-- Image -->
      <img
        class="oct-media__image"
        :src="$image.lowResolution"
        :alt="alt"
      />

      <!-- Video Note -->
      <div class="oct-media__note" v-if="type === 'video' && !processing && !video.id">
        <oct-icon icon="camera" />
        <span class="oct-media__note-text">動画を選択</span>
      </div>

      <!-- Note slot -->
      <div
        class="oct-media__note"
        v-if="$slots.note && !processing && !image.id"
      >
        <span class="oct-media__note-text">
          <slot name="note" />
        </span>
      </div>

      <!-- File input -->
      <input
        type="file"
        class="oct-media__input"
        @change="uploadMedia"
        :accept="`${type}/*`"
        v-if="editable"
      >

      <!-- Video -->
      <video
        class="oct-media__image oct-media__video"
        :src="$video.url"
        :autoplay="$video.autoplay"
        muted
        preload
        ref="oct-media__video"
        @click="playVideo"
        @playing="onPlayVideo"
        @pause="onPauseVideo"
        @ended="onPauseVideo"
        v-if="$video && $video.url"
      />

      <!-- Actions -->
      <div class="oct-media__actions" v-if="!processing">
        <i
          class="oct-media__action oct-media__action--add"
          v-if="[image, video].find(item => item.id && item.status > 2) && editable"
        >
          <oct-icon icon="refresh" />
        </i>

        <i
          class="oct-media__action oct-media__action--remove"
          v-if="actions.includes('remove')"
          @click="$emit('remove')"
        >
          <oct-icon icon="close" />
        </i>

        <i
          class="oct-media__action oct-media__action--add"
          v-if="actions.includes('add')"
        >
          <oct-icon icon="plus" />
        </i>
      </div>

      <!-- Attention plus -->
      <i
        class="oct-media__attention"
        v-if="!processing && [image, video].find(item => !item.id) && editable && actions.includes('new')"
      >
        <oct-icon icon="plus-circle" />
      </i>

      <!-- Progress -->
      <div class="oct-media__progress" v-if="processing && progress !== 0 && progress !== 1">
        <div class="oct-media__progress-base">
          <svg class="oct-media__progress-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path class="oct-media__progress--a" d="M16,32A16,16,0,1,1,32,16,16.018,16.018,0,0,1,16,32ZM16,1.5A14.5,14.5,0,1,0,30.5,16,14.517,14.517,0,0,0,16,1.5Z"/>
          </svg>
        </div>
        <div class="oct-media__progress-half">
          <div
            class="oct-media__progress-rotate"
            :style="{
              transform: progress < 0.5 ? `rotate(${-180 + 360*progress}deg)` : 'none'
            }"
          >
            <svg class="oct-media__progress-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path class="oct-media__progress--b" d="M16,32A16,16,0,1,1,32,16,16.018,16.018,0,0,1,16,32ZM16,1.5A14.5,14.5,0,1,0,30.5,16,14.517,14.517,0,0,0,16,1.5Z"/>
            </svg>
          </div>
        </div>
        <div class="oct-media__progress-half">
          <div
            class="oct-media__progress-rotate"
            :style="{
              transform: progress > 0.5 ? `rotate(${-360 + 360*progress}deg)` : false
            }"
          >
            <svg class="oct-media__progress-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path class="oct-media__progress--b" d="M16,32A16,16,0,1,1,32,16,16.018,16.018,0,0,1,16,32ZM16,1.5A14.5,14.5,0,1,0,30.5,16,14.517,14.517,0,0,0,16,1.5Z"/>
            </svg>
          </div>
        </div>
      </div>
    </picture>

    <slot />
  </figure>
</template>

<script>
import API from '@/api/index.js'
import OctImage from '@/components/image/OctImage.js'
import OctIcon from '@/components/icon/OctIcon.vue'

export default {
  name: 'OctMedia',
  components: {
    OctIcon
  },
  props: {
    /**
     * Media type
     * [image | video]
     */
    type: {
      type: String,
      default: 'image'
    },
    /** Image */
    image: {
      type: Object,
      default: () => OctImage()
    },
    /** Video */
    video: {
      type: Object,
      default: () => ({
        id: '',
        url: '',
        thumbnail: 'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7',
        autoplay: false,
        status: 4
      })
    },
    /** Alt */
    alt: {
      type: String,
      default: ''
    },
    /** Break point */
    breakPoint: {
      type: Number,
      default: 769
    },
    /** Indicate dense */
    dense: {
      type: Boolean,
      default: false
    },
    /** Use natural size */
    natural: {
      type: Boolean,
      default: false
    },
    /** Indicate the media is editable */
    editable: {
      type: Boolean,
      default: false
    },
    /** Indicate the media is editable */
    actions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      /** Temprory image */
      $_octMedia_image: this.image,
      /** Temprory video */
      $_octMedia_video: this.video,
      /** Indicate upload is processing */
      processing: false,
      /** Upload progress */
      progress: 0,
      /** Timeout IDs */
      $_octMedia_timeoutIds: []
    }
  },
  computed: {
    $image: {
      get () {
        return this.type === 'image' ?
          /** The image */
          {
            lowResolution: this.getSafeImage(this.$data.$_octMedia_image.lowResolution),
            standardResolution: this.getSafeImage(this.$data.$_octMedia_image.standardResolution),
            highResolution: this.getSafeImage(this.$data.$_octMedia_image.highResolution)
          } :
          /** Thumbnail for video */
          {
            lowResolution: this.getSafeImage(this.$data.$_octMedia_video.thumbnail),
            standardResolution: this.getSafeImage(this.$data.$_octMedia_video.thumbnail),
            highResolution: this.getSafeImage(this.$data.$_octMedia_video.thumbnail)
          }
      },
      set (val) {
        this.$data.$_octMedia_image = val
        this.$emit('update:image', val)
      }
    },
    $video: {
      get () {
        return this.$data.$_octMedia_video
      },
      set (val) {
        this.$data.$_octMedia_video = val
        this.$emit('update:video', val)
      }
    }
  },
  methods: {
    /** Upload media */
    async uploadMedia(event) {
      // Raise processing
      this.processing = true
      this.progress = 0

      // The file
      const file = event.target.files[0]

      try {
        // Payload
        const payload = await API.getUploadURL({mime: file.type}, this.type === 'video')

        // Reset progress
        this.progress = 0

        // XHR
        API.upload(payload.url, file, event => {
          // Update progress
          this.progress = event.loaded/event.total
          // Progress is .9 max
          if (this.progress > .9)
            this.progress = .9
          // Check upload status
          event.loaded >= event.total &&
            this.$_octMedia_checkUploadStatus(payload.id)
        })

        // Reset input
        this.$el.querySelector('.oct-media__input').value = ''

        /** @event upload:start */
        this.$emit('upload:start', {id: payload.id})
      } catch (thrown) {
        this.$store.commit('error', thrown)
      }
    },
    /** Check upload status */
    $_octMedia_checkUploadStatus (id) {
      // Start processing
      this.processing = true

      // Kill all timeouts and clear stored timeout IDs
      this.$data.$_octMedia_timeoutIds =
        this.$data.$_octMedia_timeoutIds.reduce((acc, cur) => {
          clearTimeout(cur)
          return acc
        }, [])

      // Retry counts
      let retry = 100

      const checkTheStatus = async () => {
        const response = await API.getDownloadURL(id, this.type === 'video')

        if (response.status > 2 || !retry--) {
          if (this.type === 'image')
            this.$image = response
          else
            this.$video = response
          
          // Stop processing
          this.processing = false
          this.progress = 1

          this.$emit('upload:complete', response)
        } else {
          // Retry and store timeout ID
          this.$data.$_octMedia_timeoutIds.push(
            setTimeout(checkTheStatus, (100-retry)*150)
          )
        }
      }

      checkTheStatus(id)
    },
    /** Image load handler */
    $_octMedia_onLoad () {
      this.processing = false
    },
    /** Image load error handler */
    $_octMedia_onLoadError () {
      const id = this.type === 'image' && this.image.id && this.image.id || // Image ID
                    this.type === 'video' && this.video.id && this.video.id // Video ID

      if (this.processing || !id) return

      // Raise processing
      this.processing = true;

      // Fetch new image if ID is sat (only once)
      API.getDownloadURL(id, this.type === 'video')
        .then(response => this.$data[`$_octMedia_${this.type}`] = response)
    },
    /** @return {string} Safe path */
    getSafeImage (src) {
      return src || 'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7'
    },
    /** Play vidoe */
    playVideo () {
      this.$refs['oct-media__video'].play()
    },
    /** Video start handler */
    onPlayVideo () {
      this.$el.classList.add('oct-media--playing')
      // Show controls
      this.$refs['oct-media__video'].controls = true
    },
    /** Video pause handler */
    onPauseVideo () {
      this.$el.classList.remove('oct-media--playing')
      // hide controls
      this.$refs['oct-media__video'].controls = false
    }
  },
  mounted () {
    // Listen to image load events
    this.$el.querySelector('img.oct-media__image')
              .addEventListener('load', this.$_octMedia_onLoad)
    this.$el.querySelector('img.oct-media__image')
              .addEventListener('error', this.$_octMedia_onLoadError)
  },
  destroyed () {
    // Clear timeouts
    this.$data.$_octMedia_timeoutIds.forEach(i => clearTimeout(i))

    // Stop listening to events
    this.$el.querySelector('img.oct-media__image')
              .removeEventListener('load', this.$_octMedia_onLoad)
    this.$el.querySelector('img.oct-media__image')
              .removeEventListener('error', this.$_octMedia_onLoadError)
  },
  watch: {
    $props: {
      handler () {
        [this.image, this.video].find(item => (
          item.id && (!item.status || item.status <= 2 ) &&
            this.$_octMedia_checkUploadStatus(item.id)
        ))
      },
      deep: true,
      immediate: true
    },
    image: {
      handler (val) {
        this.$data.$_octMedia_image = val
      },
      deep: true
    },
    video: {
      handler (val) {
        this.$data.$_octMedia_video = val
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-media {
  position: relative;
  margin: 0;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;

  &--natural {
    padding-top: 0;
  }

  &--processing {
    .oct-media__input,
    .oct-media__image {
      display: none;
    }
  }

@media print, screen and (min-width: map-get($grid-breakpoints, 'md')) {
}
}

.oct-media__picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  .oct-media--natural & {
    position: static;
  }
}

.oct-media__input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  z-index: 1;
}

.oct-media__image {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;

  .oct-media--natural & {
    position: static;
    display: block;
    height: auto;
    object-fit: initial;
  }
}

.oct-media__video {
  height: 80%;
  opacity: 0;
  z-index: 1;

  .oct-media--playing & {
    height: 100%;
    opacity: 1;
  }
}

.oct-media__note {
  color: $oct-theme--neutral-50;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .oct-icon {
    fill: $oct-theme--neutral-50;
    margin: 0 auto oct-rem(16);
    display: block;
    width: oct-rem(32);
    height: oct-rem(32);
  }
}

.oct-media__note-text {
  line-height: 1;
  display: block;
}

.oct-media__attention {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: oct-rem(32);
  height: oct-rem(32);
  pointer-events: none;

  .oct-icon {
    fill: $oct-theme--neutral-50;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.oct-media__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: oct-rem(32);
  height: oct-rem(32);

  svg {
    width: 100%;
    height: 100%;
  }

  &--a {
    fill:#dcdedf;
  }

  &--b {
    fill:#73797c;
  }
}

.oct-media__progress-base,
.oct-media__progress-half,
.oct-media__progress-rotate {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: oct-rem(32);
  height: oct-rem(32);

  svg {
    width: 100%;
    height: 100%;
  }

  &--a {
    fill:#dcdedf;
  }

  &--b {
    fill:#73797c;
  }
}

.oct-media__progress-half {
  left: 0;
  right: auto;
  width: 50%;
  overflow: hidden;

  &:last-child {
    transform: rotate(180deg);
    transform-origin: right center;
  }

  .oct-media__progress-rotate {
    transform: rotate(-180deg);
    transform-origin: right center;
    //transition-duration: .1s;
  }
}

.oct-media__progress-rotate {
  left: 0;
  right: auto;
  width: 100%;
  overflow: hidden;

  svg {
    width: 200%;
  }
}

.oct-media__progress-text {
  font-size: oct-rem(10);
  text-align: center;
  color: $oct-theme--neutral-50;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 100%;
  height: oct-rem(10);
}

.oct-media__actions {
  position: absolute;
  right: oct-rem(16);
  bottom: oct-rem(16);
  display: flex;
  gap: oct-rem(8);

  .oct-media--playing & {
    display: none;
  }

  .oct-media--dense & {
    right: oct-rem(8);
    bottom: oct-rem(8);
  }
}

.oct-media__action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: oct-rem(40);
  height: oct-rem(40);
  border-radius: 50%;
  background-color: $oct-theme--surface;
  box-shadow: 0 0 oct-rem(20) rgba(0, 0, 0, 0.1);

  &--add {
    pointer-events: none;
  }

  &--remove {
    z-index: 2;
  }

  .oct-icon {
    fill: $oct-theme--neutral-50;
    width: oct-rem(32);
    height: oct-rem(32);
  }

  .oct-media--dense & {
    width: oct-rem(32);
    height: oct-rem(32);

    .oct-icon {
      width: oct-rem(24);
      height: oct-rem(24);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
