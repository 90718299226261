<template>
  <oct-account-dialog
    title="ログイン"
    class="ss-login"
  >
    <oct-form>
      <!-- Email -->
      <oct-form-group>
        <oct-text-field
          name="email"
          type="email"
          v-model="$data.$payload.email"
          placeholder="メールアドレス"
          required
          ref="$_ssLoginView_email"
        >
          <template #helperTextInvalid>
            メールアドレスを入力してください。
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Salon -->
      <oct-form-group>
        <oct-select
          outline
          block
          v-model="$data.$payload.salon"
          :items="[
            {
              value: '',
              text: 'サロンを選択してください'
            }
          ].concat($data.$_ssLV_salons)"
          v-if="$data.$_ssLV_salons.length > 1"
        />
      </oct-form-group>

      <!-- Password -->
      <oct-form-group>
        <oct-text-field
          v-model="$data.$payload.password"
          type="password"
          placeholder="パスワード"
          required
          ref="$_ssLoginView_password"
        >
          <template #helperTextInvalid>
            パスワードを入力してください。
          </template>
        </oct-text-field>
      </oct-form-group>

      <template #primaryActions>
        <oct-button
          size="large"
          block
          :disabled="invalid || processing"
          @click.native="submit"
        >
          ログイン
        </oct-button>
      </template>

      <template #helperActions>
        <span @click="$router.push({name:'PasswordReminder'})">パスワードをお忘れの方はこちら</span>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import CryptoJS from 'crypto-js'
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSLoginView',
  mixins: [
    OctPrototypeForm
  ],
  components: {
    OctButton,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctAccountDialog
  },
  props: {
    /** Payload */
    payload: {
      type: Object,
      default: () => ({
        salon: '',
        email: '',
        password: '',
        /** Whether to compound */
        compound: false
      })
    }
  },
  data: () => ({
    $_ssLV_salons: []
  }),
  methods: {
    /** Submit handler */
    async beforeSubmit () {
      // Get salon if salon is not sat
      if (this.$data.$payload.salon === '')
        try {
          await API.getMySalons({email: this.$data.$payload.email})
            .then(response => this.$data.$payload.salon = response[0].id)
        } catch {
          this.$store.commit('error', {message: 'メールアドレスまたはパスワードが正しくありません。入力し直してください。'})
          return
        }

      // Decript password if necessary
      const password = this.$data.$payload.compound ? 
        CryptoJS.AES.decrypt(this.$data.$payload.password, 'stylestock').toString(CryptoJS.enc.Utf8) :
        this.$data.$payload.password
      //console.warn(CryptoJS.AES.encrypt('7Ujm9ol', 'stylestock').toString())

      // Sign in
      return API.signInWithEmailAndPassword(
        this.$data.$payload.salon,
        this.$data.$payload.email,
        password
      ).then(response => {
        // Set user ID
        this.$store.commit('staffs/me/state', {id: response.id})
        // Set salon ID
        this.$store.commit('state', {id: this.$data.$payload.salon})
        // Go to dashboard
        this.$router.replace({name:'Dashboard'}).catch(() => {})
      }).catch(() => (
        this.$store.commit('error', {message: 'メールアドレスまたはパスワードが正しくありません。入力し直してください。'})
      ))
    },
  },
  watch: {
    '$data.$payload.email': {
      handler (val) {
        // Build salon selector
        API.getMySalons({email: val})
          .then(response => {
            this.$data.$_ssLV_salons = response.map(item => ({
              value: item.id,
              text: item.name
            }))
            // Set salon ID if only one salon is available
            if (response.length === 1)
              this.$data.$payload.salon = response[0].id
            else
              this.$data.$payload.salon = ''
          })
          .catch(() => {
            this.$data.$_ssLV_salons = []
            this.$data.$payload.salon = ''
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>