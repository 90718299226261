<template>
  <div
    class="oct-dialog ss-dialog"
    :class="[
      { 'oct-dialog--full': full },
      `oct-dialog--${align}`
    ]"
  >
    <!-- Container -->
    <div class="oct-dialog__container ss-dialog__container">
      <!-- Surface -->
      <div class="oct-dialog__surface"
        role="alertdialog"
        aria-modal="true"
      >
        <!-- Content -->
        <transition :name="transitionName">
          <router-view class="ss-dialog__content" :key="$route.name"/>
        </transition>
      </div>
    </div>
    <!-- Scrim -->
    <div class="oct-dialog__scrim" @click="$emit('dismiss')"></div>
  </div>
</template>

<script>
import OctDialog from '@/components/dialog/index.js'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSDialog',
  mixins: [
    OctDialog,
    OctPrototypeView
  ],
}
</script>

<style scoped lang="scss">
@import "../components/dialog/index.scss";
</style>