<template>
  <oct-dialog
    full
    :title="`${$route.meta.title}`"
    :icon="$route.name === 'Assistants.todays' || !next ? 'arrow-left' : 'close'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-user-selector"
  >
    <!-- Switch -->
    <div class="ss-user-selector__action">
      <oct-switch 
        :texts="['関連順', 'すべて']"
        v-model="$data.$_ss_userSelector_showAll"
      />
    </div>

    <!-- List -->
    <oct-list>
      <oct-list-item
        v-for="item in $data.$_ss_userSelector_showAll ? items : favorites"
        :key="item.id"
        :media="{
          image: $_octPrototypeView_getProfileIconSafely(item.profileIcon)
        }"
        :title="`${item.lastName} ${item.firstName}`"
        :text="$_octPrototypeView_getRoleNameById(item.role)"
      >
        <template #meta>
          <oct-checkbox
            :value="item.id"
            v-model="selected"
          />
        </template>
      </oct-list-item>
    </oct-list>

    <!-- Action -->
    <template
      #actions
    >
      <oct-button
        block
        size="large"
        :disabled="processing"
        @click.native="submit"
      >
        {{ next ? (prop === 'assistants' ? '確定' : '次へ') : '選択'}}
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctSwitch from '@/components/switch/OctSwitch.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSUserSelector',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctCheckbox,
    OctSwitch,
    OctList,
    OctListItem,
    OctDialog
  },
  props: {
    /** Treatment ID */
    treatmentId: {
      type: String,
      default: 'treatment'
    },
    /** Property name to set the selection */
    prop: {
      type: String,
      default: 'stylists'
    },
    /** Next route */
    next: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    /** Indicate to show all */
    $_ss_userSelector_showAll: false
  }),
  computed: {
    /** Target treatment */
    item: vm => vm.$store.getters['treatments/byId'](vm.treatmentId),
    /** Array of selected user’s ID */
    selected: {
      get () {
        return this.item ? this.item[this.prop].map(item => item.id) : []
      },
      set (val) {
        // ID of all user
        const id = this.items.map(item => item.id)

        // Update state
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            [this.prop]: val.reduce((acc, cur) => (
              // Add id if the user is exist
              id.includes(cur) && acc.concat({id:cur}) || acc
            ), [])
          }]
        })
      }
    },
    /** User list to display */
    items () {
      return this.$store.getters['staffs/byRole'](this.prop === 'stylists' ? 2 : 3)
    },
    /** Customer of the treatment */
    customer: vm => vm.$store.getters['customers/byId'](vm.item.customer),
    /** Customer’s favorite users */
    favorites () {
      // All customer’s favorite users
      return !this.customer ? [] : [...new Set(
        // All customer’s favorite users
        this.customer.favorites
          // Narrow down to existing user
          .reduce((acc, cur) =>
            acc.concat(
              this.$store.getters['staffs/find'](
                item => 
                  // Owner account
                  (this.prop === 'stylists' && item.role === 1 && item.data.isStylist && item.id === cur) ||
                  // Normal account
                  (item.role === (this.prop === 'stylists' ? 2 : 3) && item.id === cur)
              ) || []
            ),
            // Add myself at the beginning
            this.$store.state.staffs.me.role === (this.prop === 'stylists' ? 2 : 3) ?
              [this.$store.getters['staffs/byId'](this.$store.state.staffs.me.id)] : [])
      )]
    }
  },
  methods: {
    /** Go next */
    submit () {
      this.processing = true
      this.next ? this.$router.push(this.next).catch(() => {}) : this.$_octPrototypeView_goBack()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-user-selector {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}

.ss-user-selector__action {
  margin-bottom: oct-rem(32);
  display: flex;
  justify-content: center;
}
</style>