var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-account-dialog',{staticClass:"ss-signup-view",attrs:{"title":_vm.title}},[_c('oct-form',{scopedSlots:_vm._u([{key:"primaryActions",fn:function(){return [_c('oct-button',{attrs:{"size":"large","block":"","disabled":_vm.invalid || _vm.processing},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}},[_vm._v(" 送信 ")])]},proxy:true}])},[_c('oct-form-group',{attrs:{"label":"サロン名"}},[_c('oct-text-field',{attrs:{"disabled":"","value":_vm.salonName}})],1),_c('oct-form-row',[_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"姓"}},[_c('oct-text-field',{ref:"$_octUF_lastName",attrs:{"placeholder":"田中","required":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 入力してください。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.lastName),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "lastName", $$v)},expression:"$data.$payload.lastName"}})],1)],1),_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"名"}},[_c('oct-text-field',{ref:"$_octUF_firstName",attrs:{"placeholder":"太郎","required":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 入力してください。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.firstName),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "firstName", $$v)},expression:"$data.$payload.firstName"}})],1)],1)],1),_c('oct-form-group',{attrs:{"label":"アカウント種別"}},[_c('oct-select',{attrs:{"block":"","outline":"","items":[
          {
            value: 2,
            text: 'スタイリスト'
          },
          {
            value: 3,
            text: 'アシスタント'
          }
        ]},model:{value:(_vm.$data.$payload.role),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "role", $$v)},expression:"$data.$payload.role"}})],1),_c('oct-form-group',{attrs:{"label":"メールアドレス"}},[_c('oct-text-field',{ref:"$_octUF_email",attrs:{"placeholder":"wada@sstock.milbon.co.jp","type":"email","required":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" メールアドレスが正しくありません。 ")]},proxy:true}]),model:{value:(_vm.$data.$payload.email),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "email", $$v)},expression:"$data.$payload.email"}})],1),_c('oct-form-group',[_c('oct-checkbox',{attrs:{"required":""},model:{value:(_vm.$data.$payload.useSalonToken),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "useSalonToken", $$v)},expression:"$data.$payload.useSalonToken"}}),_c('span',{staticClass:"ss-signup-view__caption-text"},[_c('a',{attrs:{"href":"https://salon.milbon.co.jp/pdf/TermsOfUse_STYLESTOCK.pdf","target":"_blank"}},[_vm._v(" 利用規約に "),_c('oct-icon',{attrs:{"icon":"launch"}})],1),_vm._v(" 同意する ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }