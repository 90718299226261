<template>
  <table style="font-family:sans-serif;color:#222;width:100%;border-collapse:collapse;border-spacing:0">
    <tbody>
      <!-- Header -->
      <tr>
        <td style="text-align:center;height:74px;padding:0" valign="middle">
          <img
            src="https://salon.milbon.co.jp/img/mail-magazine/logo__milbon-id.png"
            style="margin:0 auto;display:block;width:166px;height:34px"
          />
        </td>
      </tr>
      <!-- Notification -->
      <tr>
        <td style="font-size:14px;font-weight:bold;line-height:1.5;text-align:center;color:#fff;padding:16px 32px;background-color:#73797C" valign="center">
          STYLE STOCKに<br>新しいスタイルが追加されました。
        </td>
      </tr>
      <!-- Contents -->
      <tr>
        <td style="padding:48px 16px" align="center">
          <!-- Container with border -->
          <table style="width:100%;max-width:358px;border:1px solid #222;border-collapse:collapse;border-spacing:0">
            <tbody>
              <tr>
                <td style="padding:32px 0">
                  <!-- Layout table -->
                  <table style="width:100%;border-collapse:collapse;border-spacing:0">
                    <tbody>
                      <!-- Heading -->
                      <tr>
                        <td style="padding:0 32px 32px">
                          <p style="font-family:'Optima','Arsenal',serif;font-size:24px;line-height:1.7;margin:0">MY STYLE STOCK</p>
                          <p style="font-size:15px;line-height:1.7;margin:0">
                            {{ item.publishedAt.getFullYear() }}.{{ (item.publishedAt.getMonth()+1).toString().padStart(2, 0) }}.{{ item.publishedAt.getDate().toString().padStart(2, 0) }}
                          </p>
                        </td>
                      </tr>
                      <!-- Visual -->
                      <tr>
                        <td style="padding:0 0 50px">
                          <img
                            src="https://salonmilboncojp.ecbeing.biz/assets/style-stock/images/customer-mail__hero.png"
                            style="margin:0;display:block;width:100%"
                          />
                        </td>
                      </tr>
                      <!-- Stylist and message -->
                      <tr>
                        <td style="padding:0 32px 32px">
                          <table style="width:100%;border-collapse:collapse;border-spacing:0">
                            <tbody>
                              <!-- Stylist -->
                              <tr v-if="stylist">
                                <td style="padding:0 0 16px">
                                  <table style="width:100%;border-collapse:collapse;border-spacing:0">
                                    <tbody>
                                      <tr>
                                        <!-- Profile Icon -->
                                        <td style="width:64px;padding:0 16px 0 0">
                                          <img
                                            :src="stylist.profileIcon.lowResolution"
                                            style="margin:0;display:block;width:64px;height:64px"
                                          >
                                        </td>
                                        <!-- Texts -->
                                        <td style="padding:0" valign="middle">
                                          <table style="width:100%;border-collapse:collapse;border-spacing:0">
                                            <tbody>
                                              <!-- Salon name -->
                                              <tr>
                                                <td style="font-size:12px;line-height:1;color:#666;padding:0">
                                                  {{ salon.name }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style="height:8px;padding:0"></td>
                                              </tr>
                                              <!-- Stylist name -->
                                              <tr>
                                                <td style="font-size:20px;font-weight:bold;line-height:1.2;padding:0">
                                                  {{ stylist.lastName }} {{ stylist.firstName }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <!-- Message -->
                              <tr v-if="item.message">
                                <td style="padding:0 0 16px">
                                  <p
                                    v-html="escapeHTML(item.message)"
                                    style="font-size:16px;line-height:1.9;max-height:151px;margin:0;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:5;"
                                  ></p>
                                </td>
                              </tr>
                              <!-- Link -->
                              <tr v-if="item.message">
                                <td style="padding:0" align="center">
                                  <!-- Button -->
                                  <table style="width:118px;border:1px solid #222;border-collapse:collapse;border-spacing:0">
                                    <tbody>
                                      <tr>
                                        <td style="text-align:center;height:38px;padding:0" valign="middle">
                                          <a
                                            :href="mypage('STYLESTOCK_detail')"
                                            target="_blank"
                                            style="font-size:14px;line-height:38px;text-decoration:none;color:inherit;display:block;"
                                          >
                                            続きを読む
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <!-- Products -->
                      <tr v-if="products.length">
                        <td style="padding:0 32px">
                          <table style="width:100%;border-collapse:collapse;border-spacing:0">
                            <tbody>
                              <!-- Heading -->
                              <tr>
                                <td style="font-size:20px;font-weight:bold;line-height:1;padding:0 0 32px">
                                  使用アイテム
                                </td>
                              </tr>
                              <!-- List -->
                              <tr>
                                <td style="padding:0 0 16px">
                                  <table style="margin:0 auto;width:100%;border-collapse:collapse;border-spacing:0">
                                    <tbody>
                                      <!-- List item -->
                                      <tr v-for="product in products" :key="product.id">
                                        <td style="padding:16px 0 16px;border-top:1px solid #ccc;">
                                          <!-- Product -->
                                          <table style="width:100%;border-collapse:collapse;border-spacing:0">
                                            <tbody>
                                              <tr>
                                                <!-- Visual -->
                                                <td style="width:64px;padding:0 14px 0 0">
                                                  <a :href="`${product.brand_product.url}?stylestock=1&utm_source=STYLESTOCK&utm_medium=email&utm_campaign=STYLESTOCK_product#${product.id}`" target="_blank">
                                                    <img
                                                      :src="product.image"
                                                      style="margin:0;display:block;width:64px;height:auto"
                                                    />
                                                  </a>
                                                <td>
                                                <!-- Text -->
                                                <td style="padding:0" valign="middle">
                                                  <table style="width:100%;border-collapse:collapse;border-spacing:0">
                                                    <tbody>
                                                      <!-- Category name -->
                                                      <tr>
                                                        <td style="font-size:12px;line-height:1;color:#666;padding:0">
                                                          <a
                                                            :href="`${product.brand_product.url}?stylestock=1&utm_source=STYLESTOCK&utm_medium=email&utm_campaign=STYLESTOCK_product#${product.id}`"
                                                            target="_blank"
                                                            style="text-decoration:none;color:inherit"
                                                          >
                                                            {{ product.categories.length && product.categories[0].name || '' }}
                                                          </a>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style="height:8px;padding:0"></td>
                                                      </tr>
                                                      <!-- Product name -->
                                                      <tr>
                                                        <td style="font-size:14px;line-height:1.2;padding:0">
                                                          <a
                                                            :href="`${product.brand_product.url}?stylestock=1&utm_source=STYLESTOCK&utm_medium=email&utm_campaign=STYLESTOCK_product#${product.id}`"
                                                            target="_blank"
                                                            style="text-decoration:none;color:inherit"
                                                          >
                                                            {{ product.name }}
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                <td>
                                                <!-- Icon -->
                                                <td style="font-size:14px;line-height:1;width:14px;padding:0 16px 0 14px">
                                                  <a :href="`${product.brand_product.url}?stylestock=1&utm_source=STYLESTOCK&utm_medium=email&utm_campaign=STYLESTOCK_product#${product.id}`" target="_blank" style="vertical-align:top">
                                                    <img
                                                      src="https://salon.milbon.co.jp/img/mail-magazine/icon__arrow-right.png"
                                                      style="display:inline-block;width:8px;height:14px"
                                                    />
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <!-- Link -->
                              <tr>
                                <td style="padding:0">
                                  <!-- Button -->
                                  <table style="width:100%;border:1px solid #000;border-collapse:collapse;background-color:#000">
                                    <tr>
                                      <td valign="center" style="padding:0">
                                        <a :href="mypage('STYLESTOCK_more')" target="_blank" style="font-size:14px;line-height:54px;color:#fff;text-decoration:none;display:block;padding-left:15px">
                                          詳細を見る
                                        </a>
                                      </td>
                                      <td align="right" valign="center" style="padding:0">
                                        <a :href="mypage('STYLESTOCK_more')" target="_blank" style="line-height:54px;display:block;padding-right:15px;">
                                          <img src="https://salon.milbon.co.jp/img/mail-magazine/icon__arrow-right--dark.png" style="display:inline-block;width:8px;height:14px">
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <!-- Footer -->
      <tr>
        <td style="padding:0 16px 80px" align="center">
          <table style="width:100%;max-width:358px;border:1px solid #ccc;border-collapse:collapse;border-spacing:0">
            <tbody>
              <!-- Title -->
              <tr>
                <td style="font-family:'Optima','Arsenal',serif;font-size:26px;line-height:1;text-align:center;padding:31px 31px 8px">MY STYLE STOCK</td>
              </tr>
              <!-- Tagline -->
              <tr>
                <td style="font-size:12px;line-height:1;text-align:center;padding:0px 31px 20px">スタイルストック</td>
              </tr>
              <!-- Text -->
              <tr>
                <td style="font-size:12px;line-height:1.9;padding:0px 31px 31px">担当のスタイリストから施術の際のヘアスタイリングの写真や動画、使用された商品情報などを受け取ることができるサービスです。</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'SSCustomerMail',
  props: {
    /** Treatment ID to be rendered */
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    /** Target treatment. If the treatment is not found returns */
    item: vm => vm.$store.getters['treatments/byId'](vm.id),
    /** A stylist treated. If stylist is not assigned to the treatment returns undefined. */
    stylist: vm => vm.item.stylists.length ? vm.$store.getters['staffs/byId'](vm.item.stylists[0].id) : undefined,
    /** A salon treated at. If salon is not assigned to the treatment returns returns undefined */
    salon: vm => vm.$store.state,
    /** Array of product used in the treatment. If no product was used returns an empty array */
    products: vm => (
      // All products
      [vm.item.shampoo, vm.item.treatment, vm.item.outbath, ...vm.item.others]
        // Remove empty item
        .filter(item => item).reduce((acc, cur) => (
          // Add a product if not discontinued
          acc.concat(vm.$store.getters['products/byId'](cur) || [])
        ), [])
    ),
    /** My page link */
    mypage: vm => param => `https://salon.milbon.co.jp/shop/customer/stylestockhistory.aspx?utm_source=STYLESTOCK&utm_medium=email&utm_campaign=${param}&treatment_id=${vm.item.id}`
  },
  methods: {
    /** Returns HTML string from <html> to </html> */
    toHTML () {
      return `
        <!DOCTYPE HTML>
        <html lang="ja-JP">
          <head>
            <meta charset="utf-8">
            <title>STYLE STOCKに新しいスタイルが追加されました。</title>
          </head>
          <body style="margin:0;padding:0;background-color:#fff;">
            ${this.$el.outerHTML}
          </body>
        </html>
      `
    },
    /** Escape HTML tag from the string */
    escapeHTML (val) {
      return typeof val === 'string' ? val.replace(/[&'`"<>]/g, match => (
        {
          '&': '&amp;',
          "'": '&#x27;',
          '`': '&#x60;',
          '"': '&quot;',
          '<': '&lt;',
          '>': '&gt;',
        }[match]
      )).replace(/\n/g, '<br>') : val
    }
  }
}
</script>