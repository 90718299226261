var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-news-editor",attrs:{"full":"","title":_vm.title,"action":_vm.$data.$payload.id ? {
    text: '削除',
    type: 'danger'
  } : undefined},on:{"dismiss":_vm.$_octPrototypeView_goBack,"action":_vm.removeItem},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$data.$payload.status === 2)?_c('oct-button',{staticClass:"ss-news-editor__action",attrs:{"size":"large","outline":"","block":"","disabled":_vm.invalid || !_vm.$data.$payload.title || !_vm.$data.$payload.text},nativeOn:{"click":function($event){return _vm.submit(false)}}},[_vm._v(" 下書き保存 ")]):_vm._e(),_c('oct-button',{staticClass:"ss-news-editor__action",attrs:{"size":"large","block":"","disabled":_vm.invalid || !_vm.$data.$payload.title || !_vm.$data.$payload.text},nativeOn:{"click":function($event){return _vm.submit(true)}}},[_vm._v(" "+_vm._s(_vm.$data.$payload.status === 2 ? '公開' : '更新')+" ")])]},proxy:true}])},[_c('oct-form',[_c('oct-form-group',{attrs:{"label":"タイトル"}},[_c('oct-text-field',{ref:"$_ssNE_title",attrs:{"required":"","block":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" タイトルを入力してください ")]},proxy:true}]),model:{value:(_vm.$data.$payload.title),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "title", $$v)},expression:"$data.$payload.title"}})],1),_c('oct-form-group',{attrs:{"label":"投稿者"}},[_c('oct-select',{ref:"$_ssNE_author",attrs:{"block":"","outline":"","items":[
          {
            value: 1,
            text: 'milbon'
          },
          {
            value: 2,
            text: 'STYLE STOCK'
          },
        ]},model:{value:(_vm.$data.$payload.author),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "author", $$v)},expression:"$data.$payload.author"}})],1),_c('oct-form-group',{attrs:{"label":"キービジュアル"}},[_c('oct-media',{staticClass:"ss-news-editor__media",attrs:{"image":_vm.image,"actions":_vm.image ? ['remove'] : ['add'],"editable":""},on:{"update:image":[function($event){_vm.image=$event},_vm.$_octPrototypeForm_validate],"remove":_vm.removeImage},scopedSlots:_vm._u([{key:"note",fn:function(){return [_vm._v("キービジュアルを選択")]},proxy:true}])})],1),_c('oct-form-group',{attrs:{"label":"本文"}},[_c('oct-text-field',{ref:"$_ssNE_text",attrs:{"rows":20,"required":"","block":""},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 本文を入力してください ")]},proxy:true}]),model:{value:(_vm.$data.$payload.text),callback:function ($$v) {_vm.$set(_vm.$data.$payload, "text", $$v)},expression:"$data.$payload.text"}})],1),_c('oct-form-row',[_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"公開日"}},[_c('oct-text-field',{ref:"$_ssNE_releaseDate",attrs:{"required":"","type":"date","placeholder":_vm.dateToString(new Date())},on:{"change":_vm.$_octPrototypeForm_validate},scopedSlots:_vm._u([{key:"helperTextInvalid",fn:function(){return [_vm._v(" 公開日を入力してください ")]},proxy:true}]),model:{value:(_vm.releaseDate),callback:function ($$v) {_vm.releaseDate=$$v},expression:"releaseDate"}})],1)],1),_c('oct-form-col',{attrs:{"cols":2}},[_c('oct-form-group',{attrs:{"label":"公開時間"}},[_c('oct-select',{ref:"$_ssNE_releaseTime",attrs:{"block":"","outline":"","items":Array(23).fill(0).map((val, i) => (
              {
                value: i,
                text: `${(i).toString().padStart(2, 0)}:00`
              }
            ))},on:{"input":_vm.$_octPrototypeForm_validate},model:{value:(_vm.releaseTime),callback:function ($$v) {_vm.releaseTime=$$v},expression:"releaseTime"}})],1)],1)],1),(_vm.$data.$payload.status !== 2)?_c('oct-form-group',{attrs:{"label":"公開状態"}},[_c('oct-checkbox',{attrs:{"label":"非公開"},model:{value:(_vm.isPrivate),callback:function ($$v) {_vm.isPrivate=$$v},expression:"isPrivate"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }