<template>
  <oct-dialog
    full
    :title="title"
    :align="align"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-comment-list"
  >
    <!-- Switch -->
    <div class="ss-comment-list__actions">
      <oct-switch 
        :texts="['マイコメント', 'テンプレートコメント']"
        v-model="$data.$_ssCLV_showTemplates"
      />
    </div>

    <!-- List -->
    <ul class="ss-comment-list__content">
      <!-- List Item -->
      <li
        v-for="item in items"
        :key="item.id"
        class="ss-comment-lis-item"
      >
        <!-- Header -->
        <div class="ss-comment-list-item__header">
          <!-- Radio -->
          <oct-radio
            label="デフォルトに設定"
            :value="item.id"
            v-model="defaultCommentId"
            v-if="!$data.$_ssCLV_showTemplates"
          />

          <!-- Release date and an indicator -->
          <div class="ss-comment-list-item__header-text" v-else>
            {{ dateToString(item.updatedAt) }}

            <oct-chip dense outline v-if="item.status === 3">非公開</oct-chip>
            <!-- Show ‘NEW’ indicator for comments updated within a day -->
            <oct-chip
              dense
              outline
              selected
              v-else-if="new Date().getTime() - item.updatedAt.getTime() < 1*24*3600*1000"
              text="NEW"
            />
          </div>

          <!-- Action for templates (except for administrators) -->
          <oct-button
            size="small"
            @click.native="addToMyComments(item)"
            v-if="!$store.state.staffs.me.isAdministrator && $data.$_ssCLV_showTemplates"
          >
            マイコメントに追加
          </oct-button>
        </div>

        <!-- Text -->
        <div class="ss-comment-list-item__text">
          <p class="ss-comment-list-item__primary-text">
            {{ item.message }}
          </p>
        </div>

        <!-- Meta & Actions -->
        <div
          class="ss-comment-list-item__footer"
          v-if="$store.state.staffs.me.isAdministrator || !$data.$_ssCLV_showTemplates"
        >
          <!-- Meta -->
          <div class="ss-comment-list-item__meta">
            <span class="ss-comment-list-item__meta-text" v-if="!$data.$_ssCLV_showTemplates">
              最終更新日：{{ item.updatedAt.getFullYear() }}/{{ (item.updatedAt.getMonth()+1).toString().padStart(2, '0') }}/{{ item.updatedAt.getDate().toString().padStart(2, '0') }}
            </span>

            <oct-button
              block
              outline
              size="small"
              @click.native="addToMyComments(item)"
              v-else
            >
              マイコメントに追加
            </oct-button>
          </div>

          <!-- Actions -->
          <div class="ss-comment-list-item__actions">
            <!-- Remove -->
            <oct-button
              block
              size="small"
              type="link-danger"
              @click.native="removeItem(item)"
            >
              削除
            </oct-button>
            <!-- Edit -->
            <oct-button
              block
              size="small"
              @click.native="$router.push({
                path: `${$route.path}${$data.$_ssCLV_showTemplates ? '/templates' : ''}/${item.id}`
              }).catch(() => {})"
            >
              編集
            </oct-button>
          </div>
        </div>
      </li>
    </ul>

    <!-- Child component -->
    <transition :name="transitionName">
      <router-view />
    </transition>

    <!-- Actions -->
    <template #actions>
      <oct-button
        block
        size="large"
        @click.native="$router.push({
          path: `${$route.path}${$data.$_ssCLV_showTemplates ? '/templates' : ''}/new`
        }).catch(() => {})"
        v-if="$store.state.staffs.me.isAdministrator || !$data.$_ssCLV_showTemplates"
      >
        {{ $data.$_ssCLV_showTemplates ? 'テンプレート' : '' }}コメント作成

        <template #trailing-icon>
          <oct-icon icon="plus"/>
        </template>
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctRadio  from '@/components/radio/OctRadio.vue'
import OctSwitch from '@/components/switch/OctSwitch.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSCommentListView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctRadio,
    OctSwitch,
    OctChip,
    OctDialog
  },
  props: {
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Dialog alignment */
    align: {
      type: String,
      default: 'right'
    }
  },
  data: () => ({
    /**  Indicates whether template comments should be displayed */
    $_ssCLV_showTemplates: false
  }),
  computed: {
    /** ID of the comment set as default */
    defaultCommentId: {
      get () {
        return (this.items.find(item => item.default) || {id: ''}).id
      },
      async set (val) {
        // Requests
        const promises = [
          // Wait for atleast one second
          new Promise(resolve => setTimeout(resolve, 1000))
        ]
        // Turn of ‘defalut’ of the comments currently set as default
        this.$store.getters[`${this.module}/defaults`].forEach(item => (
          promises.push(
            this.$store.dispatch(`${this.module}/save`, Object.assign(item, {
                default: false
            }))
              .catch(thrown => this.$store.commit('error', thrown))
          )
        ))

        // Wait for finishing all requests
        await Promise.all(promises)

        // Turn on ‘defalut’ of the comment specified by val(comment ID)
        this.$store.dispatch(`${this.module}/save`, Object.assign(
          this.$store.getters[`${this.module}/byId`](val), {
            default: true
          }
        ))
          .catch(thrown => this.$store.commit('error', thrown))
      }
    },
    /** Comments to show determined by value of $_ssCLV_showTemplates */
    items () {
      return this.$data.$_ssCLV_showTemplates ? 
        // Salon’s comments
        this.$store.getters['comments/filter'](item => (
          // Private comments are shown to the administrators
          this.$store.state.staffs.me.isAdministrator ? true :
          (item.status === 4 && item.releaseAt.getTime() <= new Date().getTime())
        ))
          // Sorted by release date
          .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()) :
        // User’s comments
        this.$store.state.staffs.me.comments.all.concat().reverse()
    },
    /** @return path of current module (staffs/me/comments|comments) */
    module () {
      return this.$data.$_ssCLV_showTemplates ? 'comments' : 'staffs/me/comments'
    }
  },
  methods: {
    /** Remove a comment specified by ID */
    async removeItem (item) {
      try {
        // Confirm 
        await this.$store.dispatch('confirm', 'このコメントを本当に削除しますか？\n削除すると元には戻せません。')

        // Do remove
        this.$store.dispatch(`${this.module}/remove`, item)
          .then(() => this.$store.dispatch('notify', {
            text: `${this.$data.$_ssCLV_showTemplates ? 'テンプレート' : 'マイ' }コメントを削除しました`,
            type: 'danger'
          }))
          .catch(thrown => this.$store.commit('error', thrown))
      } catch {
        // Do nothing
      }
    },
    /** Add a comment to my comments */
    addToMyComments (item) {
      this.$store.dispatch(`staffs/me/comments/save`, Object.assign({}, item, {
        id: '', status: 4, default: false
      }))
        .then(() => this.$store.dispatch('notify', {
          text: `マイコメントに追加しました`,
        }))
        .catch(thrown => this.$store.commit('error', thrown))
    },
    /** Date to string */
    dateToString: val => `${val.getFullYear()}/${(val.getMonth() + 1).toString().padStart(2, '0')}/${val.getDate().toString().padStart(2, '0')}`
  },
  async mounted () {
    // Restore view
    this.$data.$_ssCLV_showTemplates = this.$route.query.template || this.$route.meta.template || false

    /* Remove all test comments
    for (const i of this.$store.getters['comments/filter'](() => true)) {
      /tacrawltest/.test(i.message)
       && await this.$store.dispatch(`comments/remove`, i)
    }
    */
  },
  watch: {
    '$data.$_ssCLV_showTemplates' (val) {
      this.$route.meta.template = val
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-comment-list {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }

  &::v-deep .oct-switch__primary-text {
    min-width: oct-rem(175);
    padding: 0;
  }
}

.ss-comment-list__content {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ss-comment-list__actions {
  margin-bottom: oct-rem(32);
  display: flex;
  justify-content: center;
}

.ss-comment-lis-item {
  margin-bottom: oct-rem(32);
  padding-bottom: oct-rem(32);
  border-bottom: 1px solid $oct-theme--neutral-20;

  &:last-child {
    padding-bottom: 0;
    border-bottom-width: 0;
  }
}

.ss-comment-list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 oct-rem(16);

  .oct-button {
    flex: 0 0 auto;
  }
}

.ss-comment-list-item__header-text {
  font-size: oct-rem(14);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: flex;
  align-items: center;
  gap: oct-rem(8);
}

.ss-comment-list-item__text {
  margin: 0 0 oct-rem(16);
}

.ss-comment-list-item__primary-text {
  font-size: oct-rem(14);
  line-height: 1.5;
  white-space: pre-line;
}

.ss-comment-list-item__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ss-comment-list-item__meta-text {
  font-size: oct-rem(14);
  line-height: 1;
  color: $oct-theme--neutral-50;
}

.ss-comment-list-item__actions {
  display: flex;
}
</style>