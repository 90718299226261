<template>
  <span
    class="oct-chip"
    :class="[{
      'oct-chip--outlined': outline,
      'oct-chip--dense': dense
    }, `oct-chip--${type}`]"
    role="row"
    :aria-selected="selected ? 'true' : selected"
  >
    {{ text }}
    <slot />
  </span>
</template>

<script>
export default {
  name: 'OctChip',
  props: {
    /** Text */
    text: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    /** Chip type [default|danger] */
    type: {
      type: String,
      default: 'default' 
    },
    /** Whether apply outlined style */
    outline: {
      type: Boolean,
      default: false
    },
    /** Whether selected */
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-chip {
  font-size: oct-rem(14);
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: $oct-theme--neutral-30;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: oct-rem(38);
  padding: 0 oct-rem(17);
  appearance: none;
  border: 1px solid $oct-theme--neutral-30;
  outline: none;
  border-radius: oct-rem(19);
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &--dense {
    font-size: oct-rem(10);
    font-weight: bold;
    height: oct-rem(26);
    padding: 0 oct-rem(7);
  }

  &[aria-selected="true"] {
    color: $oct-theme--on-primary;
    background-color: $oct-theme--primary;
    border-color: $oct-theme--primary;
  }

  &--danger {
    &[aria-selected="true"] {
      color: $oct-theme--on-primary;
      background-color: $oct-theme--danger;
      border-color: $oct-theme--danger;
    }
  }

  &--outlined {
    background-color: transparent;

    &[aria-selected="true"] {
      color: $oct-theme--primary;
      background-color: transparent;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(14);
  height: oct-rem(38);
  padding: 0 oct-rem(18);
  border-radius: oct-rem(19);

  &--dense {
    font-size: oct-rem(10);
    height: oct-rem(26);
    padding: 0 oct-rem(7);
  }
}
}
</style>