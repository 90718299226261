<template>
  <div
    class="oct-form-group"
    :class="[{
      'oct-form-group--with-label': label,
      'oct-form-group--with-caption': $scopedSlots['caption'],
      'oct-form-group--nowrap': nowrap,
      'oct-form-group--dense': dense
    }]"
  >
    <div class="oct-form-group__content">
      <div class="oct-form-group__label" v-if="label">
        <h3 class="oct-form-group__label-text">{{ label }}</h3>
        <span class="oct-form-group__meta-text" v-if="meta !== ''">{{ meta }}</span>
      </div>

      <div class="oct-form-group__row" v-if="$scopedSlots.default">
        <slot />

        <div class="oct-form-group__text" v-if="$scopedSlots['text']">
          <slot name="text"/>
        </div>
      </div>

      <div class="oct-form-group__caption" v-if="$scopedSlots['caption']">
        <slot name="caption"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OctFormGroup',
  props: {
    /** Label text */
    label: {
      type: String,
      default: ''
    },
    /** Label text */
    meta: {
      type: String,
      default: ''
    },
    /** Size of margin to the next form group */
    dense: {
      type: Boolean,
      default: false
    },
    /** Fex's wrap style */
    nowrap: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-form-group {
  margin: 0 0 oct-rem(16);

  &--with-label {
    margin-bottom: oct-rem(32);
  }

  .oct-form__row &--with-label {
    margin-bottom: oct-rem(16);
  }

  ::v-deep .oct-text-field {
    width: 100%;
  }

  ::v-deep .oct-text-field__input[size="40"],
  ::v-deep .oct-text-field__input[cols="40"] {
    width: 100%;
  }
}

.oct-form-group__row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: oct-rem(44);

  .oct-form-group--with-caption & {
    margin-bottom: oct-rem(6)
  }

  .oct-form-group--nowrap & {
    flex-wrap: nowrap;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  min-height: oct-rem(48);
}
}

.oct-form-group__label {
  margin: 0 0 oct-rem(8);
  display: flex;
  justify-content: space-between;
}

.oct-form-group__label-text,
.oct-form-group__meta-text {
  font-size: oct-rem(16);
  font-weight: bold;
  line-height: 1;
  color: $oct-theme--neutral-50;
  margin: 0;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.oct-form-group__meta-text {
  opacity: .55;
}

.oct-form-group__text {
  font-size: oct-rem(18);
  font-weight: normal;
  line-height: (24/18);
  margin: (-5em/18) 0 (-5em/18) (12em/18);
  display: block;
}

.oct-form-group__caption {
  font-size: oct-rem(13);
  font-weight: normal;
  line-height: (18/13);
  margin: (-3em/13) 0;
  display: block;
  width: 100%;
  opacity: .55;

  a {
    color: $oct-theme--primary;
  }
}
</style>
