<template>
  <oct-dialog
    full
    :title="$route.meta.title"
    icon="close"
    @dismiss="
      $store.state.session.history.depth ?
        $router.go(-1) :
        $router.push(back).catch(() => {})
    "
    class="ss-salon-editor-view"
  >
    <!-- Content -->
    <form
      autocomplete="none"
      @submit="false"
      @change="$_ssSEV_checkValidity"
      ref="$_ssSEV__form"
    >
      <oct-form
        class="ss-salon-editor-view__content"
      >
        <!-- Text -->
        <p class="ss-salon-editor-view__secondary-text">*必須項目</p>

        <!-- Common Settings -->
        <section class="ss-salon-editor-view__section">
          <!-- Status -->
          <oct-form-group
            label="ステータス*"
          >
            <oct-select
              outline
              block
              :disabled="payload.status === 1"
              v-model="$data._payload.status"
              :items="[
                {
                  value: 4,
                  text: '稼働中'
                },
                {
                  value: 2,
                  text: '停止中'
                },
                {
                  value: 1,
                  text: '削除済'
                }
              ]"
            />
          </oct-form-group>

          <!-- Release Date -->
          <oct-form-group
            label="公開日*"
            class="ss-salon-editor-view__form-group"
          >
            <oct-form-row>
              <!-- Date -->
              <oct-form-col :cols="2">
                <oct-text-field
                  type="date"
                  required
                  v-model="$_ssSEV_releasedAt"
                  class="ss-salon-editor-view__input--date"
                />
              </oct-form-col>

              <!-- Hour -->
              <oct-form-col :cols="2">
                <oct-select
                  outline
                  block
                  v-model="$_ssSEV_releaseHours"
                  :items="
                    Array(24).fill(0).map((val, key) => ({
                      value: key,
                      text: `${key.toString().padStart(0, 2)}:00`
                    }))
                  "
                  class="ss-salon-editor-view__input--hours"
                />
              </oct-form-col>
            </oct-form-row>
          </oct-form-group>

          <!-- Salon Code -->
          <oct-form-group
            label="サロンコード*"
          >
            <oct-text-field
              outline
              block
              required
              :validator="$_ssSEV_validateSalonId"
              :disabled="Boolean(payload.id)"
              v-model="$_ssSEV_salonId"
            >
              <template #helperTextInvalid>
                サロンコードを正しく入力してください
              </template>
            </oct-text-field>
          </oct-form-group>

          <!-- Salon Name -->
          <oct-form-group
            label="サロン名*"
          >
            <oct-text-field
              outline
              block
              required
              disabled
              placeholder="サロンコードを入力すると自動で表示されます"
              v-model="$data._payload.name"
            />
          </oct-form-group>

          <!-- Salon Logo -->
          <oct-form-group
            label="サロンロゴ"
          >
            <oct-media
              :image="
                $data._payload.profileIcon.id ?  
                  $data._payload.profileIcon :
                  {
                    lowResolution: '/images/salon__default.png',
                    standardResolution: '/images/salon__default.png',
                    highResolution: '/images/salon__default.png',
                    status: 2
                  }
              "
              dense
              editable
              :actions="$data._payload.profileIcon.id ? ['remove'] : ['add']"
              @upload:complete="$_ssSEV_onUploadStateChange"
              @remove="$_ssSEV_removeProfileIcon"
              class="ss-salon-editor-view__media"
            />
          </oct-form-group>
        </section>

        <!-- Style Stock Settings -->
        <section class="ss-salon-editor-view__section">
          <!-- Heading -->
          <h2 class="ss-salon-editor-view__primary-text">スタイルストック専用</h2>

          <!-- ID -->
          <oct-form-group
            label="ID*"
          >
            <oct-text-field
              outline
              block
              required
              v-model="$data._payload.milbonId"
            >
              <template #helperTextInvalid>
                IDを入力してください
              </template>
            </oct-text-field>
          </oct-form-group>

          <!-- Password -->
          <oct-form-group
            :label="`パスワード${!payload.id ? '*' : ''}`"
          >
            <oct-text-field
              outline
              block
              type="password"
              :required="!payload.id"
              autocomplete="new-password"
              v-model="$data._payload.milbonPassword"
            >
              <template
                #helperText
                v-if="payload.id"
              >
                ※パスワードを変更する場合のみ入力してください
              </template>

              <template
                #helperTextInvalid
                v-if="!payload.id"
              >
                パスワードをを入力してください
              </template>
            </oct-text-field>
          </oct-form-group>

          <!-- IP Address -->
          <oct-form-group
            label="IPアドレス*"
          >
            <!-- IP Addresses -->
            <div class="ss-salon-editor-view__input-row">
              <div
                v-for="(val, key) in $data._payload.ipAddresses"
                :key="`ipaddress${key}`"
                class="ss-salon-editor-view__input-group"
              >
                <oct-text-field
                  required
                  outline
                  block
                  v-model="$data._payload.ipAddresses[key]"
                >
                  <template #helperTextInvalid>
                    IPアドレスを入力してください
                  </template>
                </oct-text-field>

                <oct-icon
                  icon="plus"
                  @click.native="$_ssSEV_addIP"
                  v-if="key === $data._payload.ipAddresses.length - 1"
                />

                <oct-icon
                  icon="close"
                  @click.native="$_ssSEV_removeIP(key)"
                  v-if="$data._payload.ipAddresses.length > 1"
                />
              </div>

              <oct-checkbox
                label="固定IPはありません"
                v-model="$_ssSEV_hasntIP"
              />
            </div>
          </oct-form-group>
        </section>

        <!-- Separator -->
        <hr class="ss-salon-editor-view__hr">

        <!-- Account Settings -->
        <section class="ss-salon-editor-view__section ss-salon-editor-view__section--account">
          <!-- Heading -->
          <h2 class="ss-salon-editor-view__primary-text">オーナーアカウント</h2>

          <!-- Row Headings -->
          <oct-form-row
            class="ss-salon-editor-view__row--account"
            :class="{
              'ss-salon-editor-view__row--offset': $data._payload.owners.length > 1
            }"
          >
            <!-- Last Name -->
            <oct-form-col :cols="4">
              <oct-form-group label="姓*" />
            </oct-form-col>

            <!-- First Name -->
            <oct-form-col :cols="4">
              <oct-form-group label="名*" />
            </oct-form-col>

            <!-- Email -->
            <oct-form-col :cols="4">
              <oct-form-group label="メールアドレス*" />
            </oct-form-col>

            <!-- Password -->
            <oct-form-col :cols="4">
              <oct-form-group label="初期パスワード*" />
            </oct-form-col>
          </oct-form-row>

          <!-- Owner Accounts -->
          <oct-form-row
            class="ss-salon-editor-view__row--account"
            :class="{
              'ss-salon-editor-view__row--offset': $data._payload.owners.length > 1
            }"
          >
            <template
              v-for="(val, key) in $data._payload.owners"
            >
              <!-- Last Name -->
              <oct-form-col
                :cols="4"
                :key="`ownerLastName${key}`"
              >
                <oct-form-group>
                  <div class="ss-salon-editor-view__input-row">
                    <oct-text-field
                      required
                      placeholder="姓"
                      v-model="$data._payload.owners[key].lastName"
                    >
                      <template #helperTextInvalid>
                        姓を入力してください
                      </template>
                    </oct-text-field>
                  </div>
                </oct-form-group>
              </oct-form-col>

              <!-- First Name -->
              <oct-form-col
                :cols="4"
                :key="`ownerFirstName${key}`"
              >
                <oct-form-group>
                  <div class="ss-salon-editor-view__input-row">
                    <oct-text-field
                      required
                      placeholder="名"
                      v-model="$data._payload.owners[key].firstName"
                    >
                      <template #helperTextInvalid>
                        名を入力してください
                      </template>
                    </oct-text-field>
                  </div>
                </oct-form-group>
              </oct-form-col>

              <!-- Email -->
              <oct-form-col
                :cols="4"
                :key="`ownerEmail${key}`"
              >
                <oct-form-group>
                  <div class="ss-salon-editor-view__input-row">
                    <oct-text-field
                      type="email"
                      required
                      placeholder="メールアドレス"
                      :disabled="Boolean(val.id)"
                      v-model="$data._payload.owners[key].email"
                    >
                      <template #helperTextInvalid>
                        メールアドレスを正しく入力してください
                      </template>
                    </oct-text-field>
                  </div>
                </oct-form-group>
              </oct-form-col>

              <!-- Password -->
              <oct-form-col
                :cols="4"
                :key="`ownerPassword${key}`"
              >
                <oct-form-group>
                  <div class="ss-salon-editor-view__input-row">
                    <div class="ss-salon-editor-view__input-group">
                      <oct-text-field
                        type="password"
                        required
                        :validator="val => val.length <= 20 && /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/.test(val)"
                        pattern="(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}"
                        :disabled="Boolean(val.id)"
                        placeholder="パスワード"
                        v-model="$data._payload.owners[key].password"
                      >
                        <template #helperTextInvalid>
                          ※半角英数字8文字以上20文字以内で入力してください。<br>
                          ※数字と英字を混ぜた文字列を設定してください。
                        </template>
                      </oct-text-field>

                      <oct-icon
                        icon="close"
                        @click.native="$_ssSEV_removeOwner(key)"
                        v-if="$data._payload.owners.length > 1"
                      />
                    </div>
                  </div>
                </oct-form-group>
              </oct-form-col>
            </template>
          </oct-form-row>

          <!-- Action -->
          <div class="ss-salon-editor-view__row--align-end">
            <oct-button
              outline
              @click.native="$_ssSEV_addOwner"
              class="ss-salon-editor-view__button"
            >
              行を追加

              <template #trailing-icon>
                <oct-icon
                  icon="plus"
                />
              </template>
            </oct-button>
          </div>
        </section>
      </oct-form>
    </form>

    <!-- Actions -->
    <template #actions>
      <oct-button
        outline
        size="large"
        @click.native="
          $store.state.session.history.depth ?
            $router.go(-1) :
            $router.push(back).catch(() => {})
        "
      >
        キャンセル
      </oct-button>

      <oct-button
        size="large"
        :disabled="processing || invalid || payload.status === 1"
        class="ss-salon-editor-view__action"
        @click.native="$_ssSEV_submit"
      >
        保存
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
import API from '@/api/index.js'
import { createSalon, generatePassword } from '@/store/modules/OctSalons.js'
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSSalonEditorView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctCheckbox,
    OctMedia,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctFormRow,
    OctFormCol,
    OctDialog,
  },
  props: {
    /** Treatment ID */
    treatmentId: {
      type: String,
      default: ''
    },
    /** Indicate editable */
    editable: {
      type: Boolean,
      default: true
    },
    /** Path to go back */
    back: {
      type: Object,
      default: () => ({})
    },
    /**
     * Salon data
     * @type {Salon}
     */
    payload: {
      type: Object,
      default: () => createSalon()
    }
  },
  data: () => ({
    /** Local payload */
    _payload: createSalon(),
    /** Indicate validity of the form */
    invalid: true,
    /** Indicate processing */
    processing: false
  }),
  computed: {
    /** Treatment data */
    item: vm => vm.$store.getters['treatments/byId'](vm.treatmentId),
    /** Customer data */
    customer: vm => vm.item ? vm.$store.getters['customers/byId'](vm.item.customer) : undefined,
    /** Salon ID */
    $_ssSEV_salonId: {
      get () {
        return this.$data._payload.id
      },
      set (val) {
        // Set salon ID
        this.$set(this.$data._payload, 'id', val)

        // Set salon name
        this.$set(
          this.$data._payload,
          'name',
          this.$store.state.salons.candidates[val] || ''
        )
      }
    },
    /** Date of release */
    $_ssSEV_releasedAt: {
      get () {
        return `${this.$data._payload.releasedAt.getFullYear()}-${(this.$data._payload.releasedAt.getMonth() + 1).toString().padStart(2, '0')}-${this.$data._payload.releasedAt.getDate().toString().padStart(2, '0')}`
      },
      set (val) {
        const vals = val.split('-').map(val => Number(val))
        const date = new Date(this.$data._payload.releasedAt)

        date.setFullYear(vals[0])
        date.setMonth(vals[1]-1)
        date.setDate(vals[2])

        this.$set(this.$data._payload, 'releasedAt', date)
      }
    },
    /** Hour of release */
    $_ssSEV_releaseHours: {
      get () {
        return this.$data._payload.releasedAt.getHours()
      },
      set (val) {
        this.$set(this.$data._payload, 'releasedAt', new Date(this.$data._payload.releasedAt.setHours(val)))
      }
    },
    /** Availability of IP address */
    $_ssSEV_hasntIP: {
      get () {
        return !this.$data._payload.ipAddresses.length
      },
      set (val) {
        if (val)
          this.$set(this.$data._payload, 'ipAddresses', [])
        else if (!this.$data._payload.ipAddresses.length)
          this.$set(this.$data._payload, 'ipAddresses', [''])
      }
    },
  },
  methods: {
    /** Check validity of the form */
    async $_ssSEV_checkValidity () {
      // Ignore if the component hasn't be mounted
      if (!this.$refs['$_ssSEV__form']) return

      await this.$nextTick()

      this.invalid = !this.$refs['$_ssSEV__form'].checkValidity()
    },
    /** Submit payload */
    async $_ssSEV_submit () {
      if (this.processing) return

      const data = _cloneDeep(this.$data._payload)

      // Clean up data
      // Salon code
      if (!this.payload.id && await API.getSalonDetails({id: data.id}).catch(() => false))
        return this.$store.dispatch('alert', 'サロンがすでに登録されています。')

      if (data.status === 1 && !window.confirm('サロンを本当に「削除済」にしますか？「削除済」にすると元には戻せません。'))
        return

      // Password
      if (!data.milbonPassword)
        delete data.milbonPassword

      // Profile icon
      if (!data.profileIcon.id)
        data.profileIcon = {}

      // Normalize IP addresses and owners, just to be sure
      data.ipAddresses = data.ipAddresses.filter(item => item)
      data.owners = data.owners.filter(item => item.email && item.password)

      delete data.stats
      delete data.initialPwd

      // Turn the processing on
      this.processing = true

      this.$store.dispatch('salons/saveItem', {payload: data, replace: Boolean(this.payload.id)})
        .then(() => {
          this.$store.dispatch('notify', 'サロンを保存しました')
          // Go back
          this.$store.state.session.history.depth ?
            this.$router.go(-1) :
            this.$router.push(this.back).catch(() => {})
        })
        .catch(() => this.$store.commit('error', {message: 'サロンを保存できませんでした。しばらく経ってからもう一度お試しください。'}))
        .finally(() => this.processing = false)
    },
    /** Validate salon ID */
    async $_ssSEV_validateSalonId (val) {
      return Boolean(this.$store.state.salons.candidates[val])
    },
    /** Add IP address */
    $_ssSEV_addIP () {
      this.$data._payload.ipAddresses.push('')
      this.$_ssSEV_checkValidity()
    },
    /** Remove IP address */
    $_ssSEV_removeIP (index) {
      this.$data._payload.ipAddresses.splice(index, 1)
      this.$_ssSEV_checkValidity()
    },
    /** Add an owner */
    $_ssSEV_addOwner () {
      this.$data._payload.owners.push({email: '', password: generatePassword()})
      this.$_ssSEV_checkValidity()
    },
    /** Remove an owner */
    $_ssSEV_removeOwner (index) {
      if (window.confirm('オーナーアカウントを本当に削除しますか？削除すると元には戻せません。')) {
        this.$data._payload.owners.splice(index, 1)
        this.$_ssSEV_checkValidity()
      }
    },
    /** Handle upload state change event */
    $_ssSEV_onUploadStateChange (event) {
      this.$set(this.$data._payload, 'profileIcon', event)
    },
    /** Remove prifile icon */
    $_ssSEV_removeProfileIcon () {
      if (window.confirm('サロンロゴを削除しますか？'))
        this.$set(this.$data._payload, 'profileIcon', {})
    },
  },
  mounted () {
    // Check validity
    this.$_ssSEV_checkValidity()
  },
  watch: {
    payload: {
      handler (val) {
        this.$set(this.$data, '_payload', _merge({}, val))
        // Check validity
        this.$_ssSEV_checkValidity()
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

/* Containers */
.ss-salon-editor-view__content {
  padding: oct-rem(32) 0;

  ::v-deep .oct-form__content {
    display: flex;
    flex-direction: column;
    gap: oct-rem(64);
  }

  ::v-deep .oct-form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ss-salon-editor-view__section {
  &--account {
    .ss-salon-editor-view__primary-text + .oct-form__row {
      margin-bottom: 0;
    }

    ::v-deep .oct-form__col {
      margin-bottom: 0;
    }
  }
}

.ss-salon-editor-view__form-group {
  margin-bottom: 0;
}

.ss-salon-editor-view__input-row {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  width: 100%;
}

.ss-salon-editor-view__input-group {
  display: flex;
  gap: oct-rem(16);
  align-items: flex-start;

  .oct-icon {
    margin: oct-rem(8) 0 0;
    flex: 0 0 oct-rem(32);
    width: oct-rem(32);
    height: oct-rem(32);
    cursor: pointer;

    &__plus {
      fill: $oct-theme--surface;
      background-color: $oct-theme--neutral;
      border-radius: 50%;
    }
  }
}

.ss-salon-editor-view__row--account {
  display: grid;
  grid-template-columns: 15.6% 15.6% 1fr 1fr;
  column-gap: oct-rem(8);

  .ss-salon-editor-view__input-group {
    gap: oct-rem(8)
  }
}

.ss-salon-editor-view__row--offset {
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-right: oct-rem(-40);
}
}

.ss-salon-editor-view__row--align-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Texts */
.ss-salon-editor-view__primary-text {
  font-size: oct-rem(24);
  font-weight: bold;
  line-height: 1;
  margin: 0 0 oct-rem(32);
}

.ss-salon-editor-view__secondary-text {
  font-size: oct-rem(14);
  line-height: 1.2;
  text-align: center;
  margin: 0;
}

/* Elements */
.ss-salon-editor-view__hr {
  height: oct-rem(1);
  border: none;
  background-color: $oct-theme--neutral-20;
}

.ss-salon-editor-view__input {
  &--date, &--hours {
    ::v-deep .oct-text-field__input {
      text-align: center;
    }
  }
}

.ss-salon-editor-view__media {
  width: oct-rem(192);
  padding-top: oct-rem(192);
  border: oct-rem(1) solid $oct-theme--neutral-20;
}

.ss-salon-editor-view__button {
  padding: 0 oct-rem(48) 0 oct-rem(16);
  border-color: $oct-theme--neutral-20;

  :deep(.oct-button__trailing-icon) {
    width: oct-rem(24);
    height: oct-rem(24);
  }
}
</style>