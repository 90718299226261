<template>
  <div
    class="ss-dashboard"
    v-show="me.id"
  >
    <!-- Header -->
    <div class="ss-dashboard-header">
      <div class="ss-dashboard-header__content">
        <div
          class="ss-dashboard-header__graphic"
          @click="$router.push({
            name: 'Profile.mine'
          }).catch(() => {})"
        >
          <oct-media
            :image="me.profileIcon"
            :alt="`${me.lastName} ${me.firstName}`"
            class="ss-dashboard-header__image"
          />
        </div>

        <!-- Title -->
        <div class="ss-dashboard-header__title">
          <span class="ss-dashboard-header__title-text">
            {{ $_octPrototypeView_getDateString(new Date) }}
          </span>
        </div>

        <!-- Actions -->
        <div
          class="ss-dashboard-header__actions"
          v-if="me.data.verified !== false"
        >
          <!-- Comments -->
          <div
            class="ss-dashboard-header__action"
            @click="$router.push({
              name: 'Comments.mine'
            })"
          >
            <oct-icon icon="message-outline" />
          </div>

          <!-- Notifications -->
          <div
            class="ss-dashboard-header__action"
            :class="{
              'ss-dashboard-header__action--activated': unreadNotifications.length
            }"
            @click="$router.push({
              name: 'Notifications'
            })"
          >
            <oct-icon icon="bel-outline" v-if="!unreadNotifications.length" />
            <oct-icon icon="bel" v-else />
          </div>

          <div class="ss-dashboard-header__action ss-dashboard-header__action--exit">
            <oct-icon icon="exit" @click.native="signOut" />
          </div>
        </div>
      </div>
    </div>

    <!-- Lists -->
    <div
      class="ss-dashboard__content"
      v-if="items.length && !$store.state.processing && me.data.verified !== false"
    >
      <!-- Unsent items -->
      <oct-list
        title="未送信"
        :class="{
          'ss-dashboard__list--empty': !unsent.length 
        }"
      >
        <oct-list-item
          v-for="item in unsent"
          :key="item.id"
          v-bind="itemToListItem(item)"
          @click.native="$router.push({
            name: 'Treatment',
            params: {
              treatmentId: item.id
            }
          }).catch(() => {})"
        >
          <template #meta>
            <oct-chip
              dense
              selected
            >
              未送信
            </oct-chip>
          </template>

          <template #trailing-icon>
            <oct-icon icon="chevron-right"/>
          </template>
        </oct-list-item>
      </oct-list>

      <!-- Sent items -->
      <oct-list
        title="送信済"
        :class="{
          'ss-dashboard__list--empty': !sent.length 
        }"
      >
        <oct-list-item
          v-for="item in sent"
          :key="item.id"
          v-bind="itemToListItem(item)"
          @click.native="$router.push({
            name: 'Treatment',
            params: {
              treatmentId: item.id
            }
          }).catch(() => {})"
        >
          <template #meta>
            <oct-chip
              dense
            >
              送信済
            </oct-chip>
          </template>

          <template #trailing-icon>
            <oct-icon icon="chevron-right"/>
          </template>
        </oct-list-item>
      </oct-list>
    </div>

    <div
      class="ss-dashboard__content ss-dashboard__content--empty"
      v-else-if="!$store.state.processing && me.data.verified !== false"
    >
      <div class="ss-dashboard-note">
        <span class="ss-dashboard-note__secondary-text">
          まだお客様がいません
        </span>
        <p class="ss-dashboard-note__primary-text">
          QRコードをスキャンして、<br>お客様を追加しましょう
        </p>
      </div>
    </div>

    <!-- Actions -->
    <div
      class="ss-dashboard__action"
      v-if="me.data.verified !== false"
    >
      <oct-button
        block
        outline
        size="large"
        icon="book"
        @click.native="$router.push({name:'Treatments'}).catch(()=>{})"
      >
        施術履歴
      </oct-button>

      <oct-button
        block
        outline
        size="large"
        @click.native="$router.push({name: 'Treatments', query: {customer: true}})"
        class="ss-dashboard__secondary-action"
      >
        <template #trailing-icon>
          <oct-icon icon="search"/>
        </template>
      </oct-button>

      <oct-button
        block
        size="large"
        @click.native="$router.push({name: 'QR'})"
        class="ss-dashboard__secondary-action"
      >
        <template #trailing-icon>
          <oct-icon icon="qr"/>
        </template>
      </oct-button>
    </div>

    <!-- Dialog -->
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index.js'
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSDashBoard',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctMedia,
    OctChip,
    OctList,
    OctListItem
  },
  computed: {
    /** Unread notifications and news */
    unreadNotifications: vm => [...vm.$store.getters['staffs/me/notifications/unread'], ...vm.$store.getters['news/unread']],
    items: vm => vm.$store.getters['treatments/collection']('mine')['items'],
    unsent: vm => vm.$store.getters['treatments/collection']('mine')['items'].filter(item => item.status < 4),
    sent: vm => vm.$store.getters['treatments/collection']('mine')['items'].filter(item => item.status === 4),
    ...mapState({
      me: state => state.staffs.me,
    }),
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .then(() => {
          this.$router.replace({name:'Welcome'}).catch(() => {})
        })
        .catch(thrown => this.$store.commit('error', thrown))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-dashboard {
  padding: 0 0 oct-rem(126);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding-bottom: 0;
}
}

.ss-dashboard-header {
  position: sticky;
  top: 0;
  margin-bottom: oct-rem(32);
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(64);
}
}

.ss-dashboard-header__content {
  position: relative;
  display: flex;
  align-items: center;
  height: oct-rem(80);
  padding: oct-rem(16);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  height: oct-rem(120);
  padding: oct-rem(32);
}
}

.ss-dashboard-header__graphic {
  margin-right: oct-rem(76-48);
  flex: 0 0 oct-rem(48);
  max-width: oct-rem(48);
  width: oct-rem(48);
  height: oct-rem(48);
  border: 1px solid $oct-theme--neutral-20;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-right: oct-rem(144-56);
  flex: 0 0 oct-rem(56);
  max-width: oct-rem(56);
  width: oct-rem(56);
  height: oct-rem(56);
}
}

.ss-dashboard-header__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-dashboard-header__title {
  flex: 1 1 auto;
  text-align: center;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(20);
}
}

.ss-dashboard__content {
  margin: 0 auto;
  padding: 0 oct-rem(16);

  &--empty {
    text-align: center;
    position: fixed;
    top: 80px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 80px - 92px);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  max-width: oct-rem(1312+64*2);
  margin-bottom: oct-rem(108-32-64);
  padding: 0 oct-rem(64);

  &:not(.ss-dashboard__content--empty) {
    display: grid;
    column-gap: oct-rem(32);
    grid-template-columns: 1fr 1fr;
  }

  &--empty {
    position: static;
  }

  &::v-deep .oct-list-item__graphic {
    margin-right: oct-rem(20);
    flex: 0 0 oct-rem(80);
    width: oct-rem(80);
    max-width: oct-rem(80);
    height: oct-rem(80);
  }

  &::v-deep .oct-list-item__primary-text {
    font-size: oct-rem(24);

    .oct-list-item--single & {
      font-size: oct-rem(20);
    }
  }

  &::v-deep .oct-list-item__secondary-text {
    font-size: oct-rem(14);
    margin-bottom: oct-rem(12);
  }
}
}

.ss-dashboard__list {
  &--empty {
    display: none;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  &--empty {
    display: block;
  }
}
}

.ss-dashboard-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: oct-rem(16);
}

.ss-dashboard-note__primary-text {
  font-size: oct-rem(24);
  line-height: (36/24);
  display: block;
}

.ss-dashboard-note__secondary-text {
  font-size: oct-rem(20);
  line-height: 1;
  display: block;
}

.ss-dashboard-header__actions {
  position: relative;
  flex: 0 0 auto;
  gap: oct-rem(12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  gap: oct-rem(24);
}
}

.ss-dashboard-header__action {
  width: oct-rem(32);
  height: oct-rem(32);
  cursor: pointer;

  &--exit {
    display: none;
  }

  .oct-icon {
    fill: $oct-theme--neutral;
    width: 100%;
    height: 100%;
  }

  &--activated {
    position: relative;

    .oct-icon {
      fill: $oct-theme--primary;
    }

    &:after {
      content: "";
      position: absolute;
      top: oct-rem(-2);
      right: oct-rem(-2);
      width: oct-rem(12);
      height: oct-rem(12);
      background-color: $oct-theme--neutral-30;
      border: oct-rem(2) solid $oct-theme--surface;
      border-radius: 50%;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  &--exit {
    display: block;
  }
}
}

.ss-dashboard__action {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  column-gap: oct-rem(14);
  padding: oct-rem(16);
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  position: static;
  margin: 0 auto;
  width: oct-rem(392+64*2);
  padding: oct-rem(64);
}
}

.ss-dashboard__secondary-action {
  flex: 0 0 oct-rem(60);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  display: none;
}
}

/** Override transform settings */
.ss-dashboard > .oct-dialog {
  /** Property and duration settings duaring transition */
  &.forward-enter-active, &.forward-leave-active, &.back-enter-active, &.back-leave-active {
    overflow: hidden;

    &::v-deep {
      & > .oct-dialog__container {
        height: 100%;
        overflow: hidden;
        transition: transform .5s;
      }
    }
  }

  &--center {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateY(100%);
        }
      }
    }
  }

  &--right {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(100%);
        }
      }
    }
  }

  &--left {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(-100%);
        }
      }
    }
  }

  /** Transition starting/ending point settings */
  &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }

  &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }
}
</style>