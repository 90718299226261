<template>
  <div class="ss-salon-list-view">
    <!-- Header -->
    <div class="ss-salon-list-view-header">
      <!-- Left -->
      <div class="ss-salon-list-view-header__section">
        <!-- Text -->
        <span class="ss-salon-list-view-header__secondary-text">表示件数</span>

        <!-- Number of Display -->
        <oct-select
          outline
          dense
          :items="[
            {
              value: 20,
              text: '20件'
            },
            {
              value: 50,
              text: '50件'
            },
            {
              value: 100,
              text: '100件'
            }
          ]"
          v-model="numberOfDisplay"
        />
      </div>

      <!-- Center -->
      <div class="ss-salon-list-view-header__section">
        <span class="ss-salon-list-view-header__primary-text">
          すべて（{{ ($store.getters['salons/latest'] || {total: 0}).total }}）
        </span>
      </div>

      <!-- Right -->
      <div class="ss-salon-list-view-header__section ss-salon-list-view-header__section--align-end">
        <oct-button
          outline
          icon="download"
          size="small"
          align="center"
          @click.native="download"
        >
          CSV出力
        </oct-button>
      </div>
    </div>

    <!-- Table -->
    <oct-table
      :headers="$data.$_ssSLV_headers"
      :tableData="items"
      :filters="$data.$_ssSLV_filters"
      :fixedColumns="2"
      @click:menu="onClickFilter"
      @click:row="$router.push({name: 'Management.salons.edit', params: {salonId: $event[0]}})"
    />

    <!-- Actions -->
    <div class="ss-salon-list-view__action">
      <oct-button
        block
        outline
        icon="plus"
        size="large"
        @click.native="$router.push({name: 'Management.salons.edit', params: {salonId:'new'}}).catch(() => {})"
      >
        サロン追加
      </oct-button>
    </div>

    <!-- Dialog -->
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctTable from '@/components/table/OctTable.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSSalonListView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctSelect,
    OctTable
  },
  data: () => ({
    $_ssSLV_headers: [
      [
        {
          text: ''
        },
        {
          text: ''
        },
        {
          text: ''
        },
        {
          text: ''
        },
        {
          text: ''
        },
        {
          text: '（送信スタイル数）',
          colspan: 3
        },
      ],
      [
        {
          text: 'サロンコード'
        },
        {
          text: 'サロン名'
        },
        {
          text: 'ステータス'
        },
        {
          text: 'スタイリスト数'
        },
        {
          text: 'アシスタント数'
        },
        {
          text: '全体'
        },
        {
          text: '月'
        },
        {
          text: '週'
        }
      ]
    ],
    $_ssSLV_filters: [
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'salonCode',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'salonCode',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'salonName',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'salonName',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'status',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'status',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'stylistCount',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'stylistCount',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'assistantCount',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'assistantCount',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'totalTreatments',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'totalTreatments',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'monthlyTreatments',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'monthlyTreatments',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
      [
        {
          groupName: '並び替え',
          items: [
            {
              text: '昇順で並び替え',
              active: false,
              payload: {
                orderBy: 'weeklyTreatments',
                sort: 'asc',
              }
            },
            {
              text: '降順で並び替え',
              active: false,
              payload: {
                orderBy: 'weeklyTreatments',
                sort: 'desc',
              }
            }
          ]
        },
        {
          items: [
            {
              text: 'リセット',
              active: false,
              payload: {}
            }
          ]
        }
      ],
    ],
    /** Indicate CSV download is processing */
    $_ssSLV_downloading: false,
  }),
  computed: {
    /** Items to display */
    items: vm => (
      (vm.$store.getters['salons/latest'] || {items: []})
        .items.map(item => ([
          item.id,
          item.name,
          ['', '削除済', '停止中', '', '稼働中'][item.status],
          item.stats.stylists,
          item.stats.assistants,
          item.stats.treatments.total,
          item.stats.treatments.monthly,
          item.stats.treatments.weekly,
        ]))
    ),
    /** Number of display */
    numberOfDisplay: {
      get () {
        return this.$route.query.count || 20
      },
      set (val) {
        this.$router.replace({
          name: 'Management.salons',
          query: Object.assign({}, this.$router.currentRoute.query || {}, { count: val})
        }).catch(() => {})
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.updateFilters(to)
    next()
  },
  mounted () {
    this.updateFilters()
  },
  methods: {
    /** Update filters */
    updateFilters (to=this.$router.currentRoute) {
      // Set active state of row filters
      this.$data.$_ssSLV_filters.forEach(r => (
        // Each group
        r.forEach(g => (
          // Each item
          g.items.forEach(item => (
            item.active =
              // Payload is not empty
              Object.keys(item.payload).length &&
              // Payload is included in the query
              !Object.keys(item.payload).some(p => item.payload[p] !== to.query[p])
          )))
        )
      ))
    },
    /** Handles click event of filters */
    onClickFilter (event) {
      this.$router.push({
        name: 'Management.salons',
        query: event
      }).catch(() => {})
    },
    /** Download list as CSV */
    async download () {
      if (this.$data.$_ssSLV_downloading) return

      try {
        // Turn the downloading flag on
        this.$data.$_ssSLV_downloading = true

        // CSV string to download
        const csv =
          ['サロンコード, サロン名, ステータス, スタイリスト数, アシスタント数, 全体, 月, 週']
            .concat((await this.$store.dispatch('salons/fetchAll')).map(item => (
              `"${item.id}","${item.name}",${item.stats.stylists},${item.stats.assistants},${item.stats.treatments.total},${item.stats.treatments.monthly},${item.stats.treatments.weekly}`
            )))

        // UTF Bomb
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        // Blob to download
        const blob = new Blob([bom, csv.join('\n')], {type: 'text/csv'})

        // URL to download
        const url = (window.URL || window.webkitURL).createObjectURL(blob)

        // Create link
        const link = document.createElement('a')
        link.href = url
        link.download = 'style_stock_salons.csv'

        // Append link and click and remove
        document.body.insertAdjacentElement('beforeEnd', link)
        link.click()
        link.remove()

        // Release URL
        setTimeout(() => (window.URL || window.webkitURL).revokeObjectURL(url), 1000)
      } catch (thrown) {
        this.$store.dispatch('alert', 'CSVが作成できませんでした')
      } finally {
        // Turn the downloading flag off
        this.$data.$_ssSLV_downloading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

/* Containers */
.ss-salon-list-view {
  padding: 0 oct-rem(16) oct-rem(84);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  ::v-deep .oct-table__header-cell:nth-child(1),
  ::v-deep .oct-table__cell:nth-child(1) {
    width: oct-rem(112 + 32 + 16);
  }

  ::v-deep .oct-table__header-cell:nth-child(2),
  ::v-deep .oct-table__cell:nth-child(2) {
    width: oct-rem(208 + 32 + 16);
    max-width: oct-rem(208 + 32 + 16);
  }

  ::v-deep .oct-table__header-cell:nth-child(3),
  ::v-deep .oct-table__cell:nth-child(3) {
    font-size: oct-rem(14);
    width: oct-rem(117 + 32 + 16);
    max-width: oct-rem(117 + 32 + 16);
    min-width: oct-rem(117 + 32 + 16);

    &[data-text="稼働中"],
    &[data-text="停止中"] {
      .oct-table__text-container {
        gap: oct-rem(4);

        &:before {
          content: "";
          width: oct-rem(8);
          height: oct-rem(8);
          background-color: $oct-theme--success;
          border-radius: 50%;
        }
      }
    }

    &[data-text="稼働中"] {
      color: $oct-theme--success;
    }

    &[data-text="停止中"] {
      color: $oct-theme--danger;

      .oct-table__text-container:before {
        background-color: $oct-theme--danger;
      }
    }

    &[data-text="削除済"] {
      color: $oct-theme--neutral-50;
    }
  }

  ::v-deep .oct-table__header-cell:nth-child(4),
  ::v-deep .oct-table__cell:nth-child(4),
  ::v-deep .oct-table__header-cell:nth-child(5),
  ::v-deep .oct-table__cell:nth-child(5) {
    width: oct-rem(117 + 32);
    max-width: oct-rem(117 + 32);
    min-width: oct-rem(117 + 32);
  }

  ::v-deep .oct-table__header-cell:nth-child(6),
  ::v-deep .oct-table__cell:nth-child(6),
  ::v-deep .oct-table__header-cell:nth-child(7),
  ::v-deep .oct-table__cell:nth-child(7),
  ::v-deep .oct-table__header-cell:nth-child(8),
  ::v-deep .oct-table__cell:nth-child(8) {
    width: oct-rem(96 + 32);
    max-width: oct-rem(96 + 32);
    min-width: oct-rem(96 + 32);
  }

  ::v-deep .oct-table__header-cell:nth-child(6),
  ::v-deep .oct-table__cell:nth-child(6) {
    border-left: oct-rem(1) solid $oct-theme--neutral-20;
  }

  ::v-deep .oct-table__cell:nth-child(2) .oct-table__text-container {
    justify-content: flex-start;
  }

  ::v-deep .oct-table__cell:nth-child(2) .oct-table__text-container .oct-table__cell-text {
    text-decoration: underline;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding: 0 oct-rem(64) oct-rem(64);

  ::v-deep .oct-table__cell {
    cursor: pointer;
  }
}
}

.ss-salon-list-view-header {
  margin: 0 0 oct-rem(16);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.ss-salon-list-view-header__section {
  display: flex;
  align-items: center;
  gap: oct-rem(16);

  &--align-end {
    justify-content: flex-end;
  }

  ::v-deep .oct-button {
    width: oct-rem(123);
    height: oct-rem(40);
    padding-left: oct-rem(32);
    border-color: $oct-theme--neutral-20;
  }

  ::v-deep .oct-select__native-input {
    height: oct-rem(40);
    padding-top: oct-rem(11);
    padding-bottom: oct-rem(11);
  }
}

.ss-salon-list-view__action {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  column-gap: oct-rem(14);
  padding: oct-rem(16);
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  position: static;
  margin: 0 auto;
  width: oct-rem(392+64*2);
  padding: oct-rem(64);
}
}

/* Texts */
.ss-salon-list-view-header__secondary-text {
  font-size: oct-rem(12);
  color: $oct-theme--neutral-50;
}

/** Override transform settings */
.ss-salon-list-view > .oct-dialog {
  /** Property and duration settings duaring transition */
  &.forward-enter-active, &.forward-leave-active, &.back-enter-active, &.back-leave-active {
    overflow: hidden;

    &::v-deep {
      & > .oct-dialog__container {
        height: 100%;
        overflow: hidden;
        transition: transform .5s;
      }
    }
  }

  &--center {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateY(100%);
        }
      }
    }
  }

  &--right {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(100%);
        }
      }
    }
  }

  &--left {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(-100%);
        }
      }
    }
  }

  /** Transition starting/ending point settings */
  &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }

  &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }
}
</style>