import API from '@/api/index.js'
import _merge from 'lodash/merge'
import {create as createUser} from '@/store/modules/OctUser.js'

/** Normalize item */
const normalizeItem = val => (
  _merge({
    profileIcon: {
      highResolution: '/images/customer__default.png',
      standardResolution: '/images/customer__default.png',
      lowResolution: '/images/customer__default.png',
    }
  }, val.data === null ? Object.assign(val, {data: {}}) : val)
)

const state = () => {
  return {
    /** All users */
    all: [],
  }
}

// getters
const getters = {
  filter: state => filter => state.all.filter(item => filter ? filter(item) : false),
  find: state => filter => state.all.find(item => filter ? filter(item) : false),
  /**
   * Get users by role
   * If role is “2” add owner accounts sat as a stylist
   */
  byRole: state => id => (
                            id === 2 ?
                              state.all.filter(item => item.role === 1 && item.data.isStylist):
                              []
                          ).concat(state.all.filter(item => item.role === id)),
  /** Get user by ID */
  byId: state => id => state.all.find(item => item.id === id) 
}

// actions
const actions = {
  /** Fetch all users */
  async fetchAll ({commit}, payload={}) {
    const items = []

    // Initialize query
    payload.page = 1
    payload.count = 50

    while (
      // Fetch items
      await API.getUsers(payload).then(response => {
        items.push(...response.items)
        return response.items.length ? items.length < response.total : false
      })
    ) payload.page++

    // Update items
    commit('state', {all: items})
  },

  /** Save a user */
  save ({commit, state}, {item}) {
    let payload = JSON.parse(JSON.stringify(item))
    // Strip unnecessary properties
    delete payload.salon

    // Case of new user
    if (!payload.id) {
      delete payload.id
      delete payload.profileIcon
    }

    // Normalize payload
    payload.role = Number(payload.role)

    return API.updateUser(payload).then(response => {
      // Update state
      commit('add', {items: [response]})
      // Update myself if necessary
      state.me.id === response.id && commit('me/state', response)
      return response
    })
  },

  /**
   * Add an item at beginning of all
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  unshift ({commit, dispatch}, {item}) {
    // Save the item and add the item at the beginning of the collection
    return dispatch('save', {item:item})
      .then(response => {
        // Remove an item if already exist
        commit('remove', {items:[response]})
        // Unshift the item
        commit('unshift', {item:Object.assign({}, item, response)})

        return response
      })
  },

  /** Remove a user */
  remove ({commit}, {item}) {
    return API.removeUser({id: item.id})
      .then(response => commit('remove', {items:[response]}))
  },

  /** Send remind mail of registration */
  remind (context, {item}) {
    return API.remindUser(item.id)
  }
}

// mutations
const mutations = {
  /**
   * Add items to the collection
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  add (state, {items}) {
    items.forEach(item => {
      // Index of the item
      const index = state.all.findIndex(i => i.id === item.id)
      // If found remove it
      index !== -1 ? Object.assign(state.all[index], normalizeItem(item)) : state.all.push(normalizeItem(item))
    })
  },

  /** Remove items from the collection */
  remove (state, {items}) {
    items.forEach(item => {
      // Index of the item
      const index = state.all.findIndex(i => i.id === item.id)
      // If found remove it
      index !== -1 && state.all.splice(index, 1)
    })
  },

  /** Unshift an item */
  unshift (state, {item}) {
    state.all.unshift(normalizeItem(item))
  },

  /** Update state */
  state (state, val) {
    // Normalization
    if (val.all) val.all = val.all.map(item => normalizeItem(item))
    Object.assign(state, val)
  },
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    me: createUser()
  }
})

export default create()