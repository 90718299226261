<template>
  <aside
    class="oct-snackbar"
    :class="{
      'oct-snackbar--open': $data.$_osb_isOpen,
      'oct-snackbar--danger': notification && notification.type === 'danger'
    }"
    @click="close"
  >
    <div class="oct-snackbar__surface" role="status" aria-relevant="additions">
      <!-- Icon -->
      <div class="oct-snackbar__icon">
        <oct-icon
          :icon="notification && notification.type ==='danger' ? 'info-outline' : 'done'"
        />
      </div>

      <div class="oct-snackbar__label" aria-atomic="false">
        {{ notification ? notification.text || notification : '' }}
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import OctIcon from '@/components/icon/OctIcon.vue'

export default {
  name: 'OctSnackbar',
  components: {
    OctIcon
  },
  props: {
    /** Duration to close in miliseconds */
    duration: {
      type: Number,
      default: 2000
    },
  },
  data () {
    return {
      $_osb_isOpen: false,
      /** Timeout id */
      $_osb_tid: null
    }
  },
  computed: {
    ...mapState({
      notification: 'notification'
    })
  },
  methods: {
    /** Open snackbar */
    async open () {
      // Open
      this.$data.$_osb_isOpen = true

      // Clear timeout
      clearTimeout(this.$data.$_osb_tid)

      // Close after duration
      this.$data.$_osb_tid = setTimeout(() => {
        this.close()
      }, this.duration)
    },
    /** Close snackbar */
    async close () {
      // Do close
      this.$data.$_osb_isOpen = false
      // Clear notification
      setTimeout(() => this.$store.commit('state', {notification: null}), 1000)
    }
  },
  watch: {
    notification: {
      handler (val) {
        val && this.open()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-snackbar {
  position: fixed;
  top: oct-rem(0);
  left: oct-rem(0);
  width: 100%;
  height: 0;
  z-index: 100;
}

.oct-snackbar__surface {
  color: $oct-theme--on-primary;
  margin: 0;
  display: flex;
  align-items: center;
  gap: oct-rem(8);
  padding: oct-rem(16);
  background-color: $oct-theme--primary;
  transform: translateY(-100%);
  transition-duration: .4s;

  .oct-snackbar--danger & {
    background-color: $oct-theme--danger;
  }

  .oct-snackbar--open & {
    transform: translateY(0);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  justify-content: center;
}
}

.oct-snackbar__icon {
  fill: $oct-theme--on-primary;
  flex: oct-rem(32);
  max-width: oct-rem(32);
  width: oct-rem(32);
  height: oct-rem(32);
  border: 1px solid $oct-theme--on-primary;
  border-radius: 50%;

  .oct-snackbar--danger & {
    border: none;
  }

  .oct-icon {
    width: 100%;
    height: 100%;
  }
}

.oct-snackbar__label {
  font-size: oct-rem(16);
  line-height: 1;
  letter-spacing: 0;
  white-space: nowrap;
  margin: 0 ;
  flex: 0 0 auto;
}
</style>