<template>
  <div class="oct-user-card">
    <div class="oct-user-card__visual">
      <oct-media
        :alt="title"
        :image="$_octPrototypeView_getProfileIconSafely(image)"
        class="oct-user-card__media"
      />
    </div>

    <div class="oct-user-card__text">
      <span class="oct-user-card__secondary-text">
        {{ text }}
      </span>

      <span class="oct-user-card__eyebrow-text">
        {{ eyebrow }}
      </span>

      <h3 class="oct-user-card__primary-text">
        {{ title }}
      </h3>
    </div>

    <div class="oct-user-card__indicator" v-if="status === 1">
      <span class="oct-user-card__indicator-text">アカウント停止中</span>
    </div>

    <div class="oct-user-card__indicator oct-user-card__indicator--success" v-if="status === 2">
      <span class="oct-user-card__indicator-text">パスワード未登録</span>
    </div>

    <div class="oct-user-card__actions">
      <oct-button
        block
        outline
        size="large"
        @click.native="$emit('click:remind')"
        v-if="status === 2"
      >
        パスワード登録メール再送
      </oct-button>

      <oct-button
        block
        size="large"
        @click.native="$emit('click:edit')"
      >
        {{ editable ? 'スタッフ' : 'アカウント'}}情報変更
      </oct-button>

      <oct-button
        block
        outline
        size="large"
        @click.native="$router.push({name:'Staffs'}).catch(() => {})"
        v-if="enableSecondaryActions"
      >
        スタッフ一覧
      </oct-button>

      <div
        class="oct-user-card__actions-row"
        v-if="editable"
      >
        <div
          class="oct-user-card__actions-col"
          v-if="status !== 0"
        >
          <oct-button
            block
            outline
            size="medium"
            :disabled="status === 2"
            @click.native="$emit('click:suspend')"
          >
            {{ status !== 1 ? 'スタッフ停止' : '停止解除' }}
          </oct-button>
        </div>

        <div
          class="oct-user-card__actions-col"
          v-if="status === 0"
        >
          <oct-button
            block
            outline
            size="medium"
            @click.native="$emit('click:verify')"
          >
            スタッフ承認
          </oct-button>
        </div>

        <div class="oct-user-card__actions-col">
          <oct-button
            block
            outline
            type="danger"
            size="medium"
            @click.native="$emit('click:remove')"
          >
            スタッフ削除
          </oct-button>
        </div>
      </div>

      <div
        class="oct-user-card__action"
        v-if="!editable"
      >
        <div
          class="oct-user-card__action-text"
          @click="$emit('click:signout')"
        >
          ログアウト
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'OctUserCard',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctMedia,
  },
  props: {
    eyebrow: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => ({
        lowResolution: '',
        standardResolution: ''
      })
    },
    /**
     * Indicate whether show buttons for administrators
     */
    editable: {
      type: Boolean,
      default: false
    },
    /** Indicate to show secondary actions */
    enableSecondaryActions: {
      type: Boolean,
      default: false
    },
    /** User state */
    status: {
      type: Number,
      default: 4,
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-user-card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  max-width: oct-rem(416);
}
}

.oct-user-card__visual {
  margin: 0 0 oct-rem(32);
  border: 1px solid $oct-theme--neutral-20;
}

.oct-user-card__media {
  margin: 0;
  width: oct-rem(170);
  height: oct-rem(170);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  width: oct-rem(190);
  height: oct-rem(190);
}
}

.oct-user-card__text {
  text-align: center;
  margin: 0 0 oct-rem(32);
}

.oct-user-card__eyebrow-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral;
  margin: 0 0 oct-rem(12);
  display: block;
}

.oct-user-card__primary-text {
  font-size: oct-rem(32);
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
}

.oct-user-card__secondary-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral-50;
  margin: 0 0 oct-rem(32);
  display: block;
}

.oct-user-card__indicator {
  text-align: center;
  margin: 0 0 oct-rem(32);
}

.oct-user-card__indicator-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--danger;
  display: block;

  .oct-user-card__indicator--success & {
    color: $oct-theme--primary;
  }
}

.oct-user-card__actions {
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  align-items: center;
  width: 100%;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  gap: oct-rem(32);
}
}

.oct-user-card__actions-row {
  display: flex;
  width: 100%;
  gap: oct-rem(14);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  gap: oct-rem(32);
}
}

.oct-user-card__actions-col {
  flex: 1 1 auto;
}

.oct-user-card__action-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--primary;
  margin: 0 0 oct-rem(32);
  display: block;
  cursor: pointer;
}

.oct-user-card__action {
  padding-top: oct-rem(16);
}
</style>