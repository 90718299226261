<template>
  <oct-dialog
    full
    :title="title"
    :align="align"
    icon="arrow-left"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-news"
  >
    <!-- Header -->
    <div class="ss-news-header">
      <!-- Visual -->
      <div class="ss-news-header__visual">
        <oct-media
          :image="
            item.author === 1 ? {
              lowResolution: '/images/news__icon--milbon.png',
              standardResolution: '/images/news__icon--milbon.png',
              highResolution: '/images/news__icon--milbon.png'
            } : {
              lowResolution: '/images/news__icon--style-stock.png',
              standardResolution: '/images/news__icon--style-stock.png',
              highResolution: '/images/news__icon--style-stock.png'
            }
          "
        />
      </div>
      <!-- Text -->
      <div class="ss-news-header__text">
        <span class="ss-news-header__eyebrow-text">{{ item.releaseAt.getFullYear() }}年{{ (item.releaseAt.getMonth()+1).toString().padStart(2, 0) }}月{{ item.releaseAt.getDate().toString().padStart(2, 0) }}日</span>
        <span class="ss-news-header__primary-text">{{ item.title }}</span>
      </div>
    </div>
    <!-- Content -->
    <div class="ss-news__content">
      <!-- Hero -->
      <oct-media
        :image="item.images[0]"
        natural
        class="ss-news__media"
        v-if="item.images.length"
      />

      <!-- Text -->
      <div class="ss-news__text">
        <p class="ss-news__primary-text">
          {{ item.text }}
        </p>
      </div>
    </div>
  </oct-dialog>
</template>

<script>
import OctMedia from '@/components/media/OctMedia.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSNewsView',
  mixins: [
    OctPrototypeView,
  ],
  components: {
    OctMedia,
    OctDialog
  },
  props: {
    /** News ID */
    newsId: {
      type: String,
      default: ''
    },
    /** Page title */
    title: {
      type: String,
      default: 'お知らせ'
    },
    /** Dialog alignment */
    align: {
      type: String,
      default: 'right'
    }
  },
  data () {
    return {}
  },
  computed: {
    /** Indicate the comment is mine */
    item: vm => vm.$store.getters['news/byId'](vm.newsId),
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-news {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}

.ss-news-header {
  margin: 0 0 oct-rem(32);
  display: flex;
  align-items: center;
  gap: oct-rem(12);
}

.ss-news-header__visual {
  flex: 0 0 auto;
  width: oct-rem(64);
  height: oct-rem(64);
  border: 1px solid $oct-theme--neutral-20;
}

.ss-news-header__text {
  display: flex;
  flex-direction: column;
  gap: oct-rem(4);
}

.ss-news-header__eyebrow-text {
  font-size: oct-rem(10);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: block;
}

.ss-news-header__primary-text {
  font-size: oct-rem(14);
  font-weight: bold;
  line-height: 1.5;
  display: block;
}

.ss-news__media {
  margin: 0 0 oct-rem(32);
}

.ss-news__text {
  margin: 0 0 oct-rem(32);
}

.ss-news__primary-text {
  font-size: oct-rem(14);
  line-height: 2;
  white-space: pre-line;
}
</style>