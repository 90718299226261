<template>
  <div class="oct-user-form">
    <div
      class="oct-user-form__visual"
      v-if="editableProperties.profileIcon"
    >
      <oct-media
        :alt="`${$data.$payload.lastName} ${$data.$payload.lastFirstName}`"
        :image="$_octPrototypeView_getProfileIconSafely($data.$payload.profileIcon)"
        :actions="$data.$payload.profileIcon.id ? ['remove'] : []"
        dense
        editable
        @upload:complete="onUploadStateChange"
        @remove="removeProfileIcon"
        class="oct-user-form__media"
      />
    </div>

    <oct-form>
      <oct-form-row>
        <oct-form-col :cols="2">
          <oct-form-group
            label="姓"
          >
            <oct-text-field
              required
              v-model="$data.$payload.lastName"
              ref="$_octUF_lastName"
            >
              <template #helperTextInvalid>
                入力してください。
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>

        <oct-form-col :cols="2">
          <oct-form-group
            label="名"
          >
            <oct-text-field
              required
              v-model="$data.$payload.firstName"
              ref="$_octUF_firstName"
            >
              <template #helperTextInvalid>
                入力してください。
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>
      </oct-form-row>

      <oct-form-group
        label="アカウント種別"
        v-if="!editableProperties.role || !(/(2|3)/.test($data.$payload.role.toString()))"
      >
        <oct-text-field
          :value="$_octPrototypeView_getRoleNameById($data.$payload.role)"
          disabled
        />
      </oct-form-group>

      <oct-form-group
        label="アカウント種別"
        v-else
      >
        <oct-select
          block
          v-model="$data.$payload.role"
          :items="[
            {
              value: 2,
              text: 'スタイリスト'
            },
            {
              value: 3,
              text: 'アシスタント'
            }
          ]"
        />
      </oct-form-group>

      <oct-form-group
        label="メールアドレス"
      >
        <oct-text-field
          type="email"
          v-model="$data.$payload.email"
          :disabled="!editableProperties.email"
          ref="$_octUF_email"
        >
          <template #helperTextInvalid>
            メールアドレスが正しくありません。
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Admin status -->
      <oct-form-group
        v-if="$data.$payload.role !== 1 && editableProperties.admin"
      >
        <oct-checkbox
          :disabled="disabledProperties.admin"
          label="管理アカウントにする"
          v-model="$data.$payload.isAdministrator"
        />
      </oct-form-group>

      <!-- As Stylist -->
      <oct-form-group
        v-if="$data.$payload.role === 1"
      >
        <oct-checkbox
          label="スタイリストとして表示する"
          v-model="$data.$payload.data.isStylist"
        />
      </oct-form-group>
    </oct-form>
  </div>
</template>

<script>
import OctMedia from '@/components/media/OctMedia.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'OctUserForm',
  mixins: [
    OctPrototypeView,
    OctPrototypeForm
  ],
  components: {
    OctCheckbox,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctFormRow,
    OctFormCol,
    OctMedia,
  },
  props: {
    /** Payload */
    payload: {
      type: Object,
      default: () => ({
        profileIcon: {},
        firstName: '',
        lastName: '',
        role: 2,
        email: '',
        status: 2
      })
    },
    /** Indicate which property is editable */
    editableProperties: {
      type: Object,
      default: () => ({
        profileIcon: true,
        firstName: true,
        lastName: true,
        role: false,
        email: false,
        admin: false
      })
    },
    /** Indicate which property is disabled */
    disabledProperties: {
      type: Object,
      default: () => ({
        admin: false
      })
    }
  },
  methods: {
    /** Remove profile icon */
    removeProfileIcon () {
      this.$data.$payload.profileIcon = {}
    },
    /** Profile icon upload state change handler */
    onUploadStateChange (val) {
      // Update profile icon
      this.$data.$payload.profileIcon = val
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-user-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &::v-deep .oct-select__native-input {
    background-color: transparent;
    border-color: $oct-theme--neutral-20;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  &::v-deep .oct-form {
    width: 100%;
  }
}
}

.oct-user-form__visual {
  margin: 0 0 oct-rem(32);
  border: 1px solid $oct-theme--neutral-20;
}

.oct-user-form__media {
  margin: 0;
  width: oct-rem(170);
  height: oct-rem(170);
  background-color: $oct-theme--neutral-10;
}

.oct-user-form__text {
  text-align: center;
  margin: 0 0 oct-rem(32);
  padding: 0 0 oct-rem(16);
}

.oct-user-form__eyebrow-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral;
  margin: 0 0 oct-rem(12);
  display: block;
}

.oct-user-form__primary-text {
  font-size: oct-rem(32);
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
}

.oct-user-form__secondary-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral-50;
  margin: 0 0 oct-rem(32);
  display: block;
}

.oct-user-form__actions {
  display: flex;
  flex-direction: column;
  gap: oct-rem(32);
  align-items: center;
  width: 100%;
  padding: 0 oct-rem(12);
}

.oct-user-form__action-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--primary;
  margin: 0 0 oct-rem(32);
  display: block;
  cursor: pointer;
}
</style>