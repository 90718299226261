import _merge from 'lodash/merge'
import Vue from 'vue'
import API from '@/api/index.js'

const normalizeItem = (item) => (
  Object.assign(item, {
    publishedAt: item.publishedAt ? new Date(item.publishedAt) : new Date(),
    updatedAt: item.updatedAt ? new Date(item.updatedAt) : new Date(),
    releaseAt: item.releaseAt ? new Date(item.releaseAt) : new Date()
  })
)

const state = () => {
  return {
    /**
     * All items
     * @property {string} {}.id - News ID
     * @property {string} {}.salon - Salon ID registered the news
     * @property {string} {}.title - News title
     * @property {array} {}.images - Images for the news
     * @property {string} {}.text - News text
     * @property {number} {}.author - News author
     * @property {date} {}.publishedAt - Published date and time
     * @property {date} {}.updatedAt - Updated date and time
     * @property {date} {}.releaseAt - Release date and time
     * @property {string} {}.type - Notification type [notification|treatment|comment|news]
     * @property {Object} {}.data - Extra data
     * @property {boolean} {}.read - Indicate the item is read
     */
    all: {},
    released: [],
    salon: '12000010'
  }
}

// getters
const getters = {
  /** @returns all items */
  all: state => Object.values(state.all).sort((a, b) => new Date(b.releaseAt).getTime() - new Date(a.releaseAt).getTime()),
  /** All released news sorted by release date */
  released: state => Object.values(state.all).filter(item => state.released.indexOf(item.id) !== -1).sort((a, b) => new Date(b.releaseAt).getTime() - new Date(a.releaseAt).getTime()),
  /** @return {object} A News specified by ID */
  byId: state => id => state.all[id],
  /** All unread items */
  unread: (state, getters) => getters['released'].filter(item => !item.read),
  /** All read items */
  read: (state, getters) => getters['released'].filter(item => item.read)
}

// actions
const actions = {
  /** Fetch all items */
  async fetch ({state, commit}, payload={}) {
    // Result
    const items = []

    // Set payload
    Object.assign(payload, {
      salon: state.salon,
      page: 1,
      count: 100
    })

    //eslint-disable-next-line no-await-in-loop
    while (
      await API.getNews(payload).then(response => {
        items.push(...response.items)
        return response.items.length && items.length < response.total
      })
    ) payload.page++

    // Add items
    commit('add', {items})

    return Promise.resolve(items)
  },

  /** Fetch all released news */
  async fetchReleased ({state, commit}, payload={}) {
    // Now
    const now = new Date()
    // Result
    let items = []

    // Set payload
    Object.assign(payload, {
      salon: state.salon,
      status: 4,
      page: 1,
      count: 100
    })

    //eslint-disable-next-line no-await-in-loop
    while (
      await API.getNews(payload).then(response => {
        items.push(...response.items)
        return response.items.length && items.length < response.total
      })
    ) payload.page++

    // Narrow down to really released items
    items = items.filter(item => now.getTime() > new Date(item.releaseAt).getTime())

    // Add items
    commit('add', {items})
    commit('released', {items})

    return Promise.resolve(items)
  },

  /** Fetch a news specified by id */
  fetchItem ({commit}, {id}) {
    return API.getNewsItem(id)
      .then(response => commit('add', {items:[response]}))
  },

  /** Save a news */
  save ({commit}, {payload}) {
    return API.updateNews(payload)
      .then(response => commit('add', {items:[response]}))
  },

  /** Remove a news */
  remove ({commit}, {item}) {
    return API.removeNews(item.id)
      .then(() => commit('remove', {items:[item]}))
  }
}

// mutations
const mutations = {
  /**
   * Add an item  to the collection.
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  add (state, {items}) {
    items.forEach(item => (
      Vue.set(state.all, item.id, _merge(
        // Images must not be merged
        Object.assign(state.all[item.id] || {}, {images:[]}),
        normalizeItem(item)
      ))
    ))
  },

  /** Remove items from the collection */
  remove (state, {items}) {
    items.forEach(item => {
      Vue.delete(state.all, item.id)
    })
  },

  /** Set released items */
  released (state, {items}) {
    Vue.set(state, 'released', items.map(item => item.id))
  },

  /** Turn read flag of each item on */
  read (state, {items}) {
    items.forEach(item => {
      (j => j && Object.assign(j, {read:true}))(state.all[item.id])
    })
  }
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default create()