import _merge from 'lodash/merge'
import API from '@/api/index.js'
import {create as createComments} from '@/store/modules/OctComments.js'
import OctNotifications from '@/store/modules/OctNotifications.js'

const normalizeItem = (val) => {
  // Default icon
  val.profileIcon = !val.profileIcon || !val.profileIcon.lowResolution ?
    {
      highResolution: '/images/customer__default.png',
      standardResolution: '/images/customer__default.png',
      lowResolution: '/images/customer__default.png',
    } :
    val.profileIcon

  // API returns array instead of object
  val.data = Array.isArray(val.data) ? {} : val.data

  // Normalise data field
  val.data = _merge({
    /** News data*/ 
    news: {
      /** Read news IDs */
      read: []
    },
    /** Notification data */
    notifications: {
      /** Read notification IDs */
      read: []
    },
    /** Owner as stylist state */
    isStylist: false
  }, val.data)

  return val
}

const state = () => {
  return {
    /** User ID */
    id: '',
    /** First name */
    firstName: '',
    /** Last name */
    lastName: '',
    /** Profile icon */
    profileIcon: {
      lowResolution: 'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7',
      standardResolution: 'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7',
      highResolution: 'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7'
    },
    /** User's role */
    role: '',
    /** Indicate the user is administrator */
    isAdministrator: false,
    /** Extra data */
    data: {}
  }
}

// getters
const getters = {
  state: state => state
}

// actions
const actions = {
  /*
   * Get user
   */
  async getUser ({commit, state}, id=state.id) {
    return API.getUser(id).then(response => {
      // Reset
      commit('reset')
      // Update state
      commit('state', response)

      return Promise.resolve(response)
    })
  },

  /*
   * Fetch user
   * @param {string} id User ID to fetch
   */
  async fetch ({commit, state}, id=state.id) {
    return API.getUser(id).then(response => {
      // Reset
      commit('reset')
      // Update state
      commit('state', response)

      return Promise.resolve(response)
    })
  },

  /** Save the user */
  save ({commit, state}) {
    let payload = JSON.parse(JSON.stringify(state))
    // Strip unnecessary properties
    delete payload.salon

    // Case of new user
    if (!payload.id) {
      delete payload.id
      delete payload.profileIcon
    }

    // Normalize payload
    payload.role = Number(payload.role)

    return API.updateUser(payload).then(response => {
      commit('state', Array.isArray(response) ? response[0] : response)
    })
  },

  /** Remove the user */
  remove ({state}, id=state.id) {
    return API.removeUser({id: id})
  }
}

// mutations
const mutations = {
  /** Reset state */
  reset (status) {
    Object.assign(status, normalizeItem(state()))
  },
  /**
   * Set data
   */
  data (state, {item}) {
    Object.assign(state.data, _merge(state.data, item))
  },
  /**
   * Set state
   */
  state (state, val) {
    Object.assign(state, normalizeItem(val))
  },
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    comments: createComments(),
    notifications: OctNotifications
  }
})

export default create()