var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-news",attrs:{"full":"","title":_vm.title,"align":_vm.align,"icon":"arrow-left"},on:{"dismiss":_vm.$_octPrototypeView_goBack}},[_c('div',{staticClass:"ss-news-header"},[_c('div',{staticClass:"ss-news-header__visual"},[_c('oct-media',{attrs:{"image":_vm.item.author === 1 ? {
            lowResolution: '/images/news__icon--milbon.png',
            standardResolution: '/images/news__icon--milbon.png',
            highResolution: '/images/news__icon--milbon.png'
          } : {
            lowResolution: '/images/news__icon--style-stock.png',
            standardResolution: '/images/news__icon--style-stock.png',
            highResolution: '/images/news__icon--style-stock.png'
          }}})],1),_c('div',{staticClass:"ss-news-header__text"},[_c('span',{staticClass:"ss-news-header__eyebrow-text"},[_vm._v(_vm._s(_vm.item.releaseAt.getFullYear())+"年"+_vm._s((_vm.item.releaseAt.getMonth()+1).toString().padStart(2, 0))+"月"+_vm._s(_vm.item.releaseAt.getDate().toString().padStart(2, 0))+"日")]),_c('span',{staticClass:"ss-news-header__primary-text"},[_vm._v(_vm._s(_vm.item.title))])])]),_c('div',{staticClass:"ss-news__content"},[(_vm.item.images.length)?_c('oct-media',{staticClass:"ss-news__media",attrs:{"image":_vm.item.images[0],"natural":""}}):_vm._e(),_c('div',{staticClass:"ss-news__text"},[_c('p',{staticClass:"ss-news__primary-text"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }