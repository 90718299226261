<template>
  <oct-dialog
    full
    title="その他"
    :icon="'arrow-left'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-category-selector"
  >
    <oct-list
      title="カテゴリーを選択"
    >
      <oct-list-item
        v-for="item in items"
        :key="item.id"
        :icon="item.icon"
        :title="item.name"
        @click.native="$router.push({
          path: `categories/${item.id}`,
          query: $route.query
        })"
      >
      </oct-list-item>
    </oct-list>
  </oct-dialog>
</template>

<script>
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSCategorySelector',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctList,
    OctListItem,
    OctDialog
  },
  data: () => ({
    items: [
      {
        id: '1',
        icon: 'pump',
        name: 'シャンプー',
      },
      {
        id: '2',
        icon: 'bottle',
        name: 'トリートメント',
      },
      {
        id: '3',
        icon: 'dispenser',
        name: 'アウトバス',
      },
      {
        id: '4',
        icon: 'spray',
        name: 'スタイリング',
      },
      {
        id: '5',
        icon: 'tube',
        name: 'スキンケア',
      },
      {
        id: '6',
        icon: 'lip-stick',
        name: 'メイク',
      },
      {
        id: '7',
        icon: 'dryer',
        name: 'ビューティーケア',
      },
      {
        id: '8',
        icon: 'pill',
        name: 'ヘルスケア',
      }
    ]
  }),
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-category-selector {
  ::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}
</style>