<template>
  <div class="oct-list">
    <div
      class="oct-list-subheader"
      v-if="title"
    >
      <span class="oct-list-subheader__primary-text">{{ title }}</span>
    </div>

    <ul class="oct-list__content">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OctList',
  props: {
    /** Text in the list sub header */
    title: {
      type: String,
      default: ''
    }
  },
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-list {
  margin: 0 0 oct-rem(32);
  padding: 0;
}

.oct-list__content {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  width: 100%;
  list-style: none;
}

.oct-list-subheader {
  margin: 0 0 oct-rem(16);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(24);
}
}

.oct-list-subheader__primary-text {
  font-weight: bold;
  line-height: 1;
  display: block;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(24);
}
}
</style>