import Vue from 'vue'
import API from '@/api/index.js'

const state = () => {
  return {
    /**
     * All items
     * @property {string} [].id - Notification ID
     * @property {string} [].text - Notification text
     * @property {date} [].publishedAt - Published date and time
     * @property {string} [].type - Notification type [notification|treatment|comment|news]
     * @property {boolean} [].read - Indicate the item is read
     */
    all: [],
    /** Array of read notification ID */
    read: []
  }
}

// getters
const getters = {
  /** @returns all items */
  all: state => state.all,
  /** All unread items */
  unread: state => state.all.filter(item => !item.read),
  /** All read items */
  read: state => state.all.filter(item => item.read)
}

// actions
const actions = {
  /**
   * Update all notifications below
   * - Unsent Treatments
   * - New staffs
   * - Promoted staffs
   * - New template comments
   */
  async update ({commit, dispatch, rootGetters}) {
    // Now
    const now = new Date()
    // 8 A.M. today
    const today = new Date(new Date().setHours(8, 0, 0, 0))
    // Unsent items within 4 weeks
    const items = await dispatch('fetchUnsentTreatments')
    // Unsent items within 72hours
    const unsent = items.filter(item => item.status !== 4 && now.getTime() - new Date(item.publishedAt).getTime() < 72*3600*1000)
    // Customers included in the items
    const customers = items.length ? await dispatch('fetchCustomers', {items: items.map(item => item.customer)}) : {}
    // Notifications
    const notifications = []

    // Number of unsent treatments within 72hours
    unsent.length && notifications.push({
      id: today.getTime(),
      text: `未送信のスタイリングデータが${unsent.length}件あります。`,
      publishedAt: today,
      type: 'notification',
      read: false
    })

    // Unsent treatments (within 72 hours)
    items.forEach(item => (
      (new Date().getTime() - new Date(item.publishedAt).getTime() > 72*3600*1000) &&
        notifications.push({
          id: item.id,
          text: `登録から72時間経過した${customers[item.customer] ?  customers[item.customer].name + 'さんの' : ''}スタイリングデータがございます。24時間後に送信ができなくなりますのでお早めに送信ください。`,
          type: 'treatment',
          // 72 hours after published
          publishedAt: new Date(new Date(item.publishedAt).getTime() + 72*3600*1000),
          read: false
        })
    ))

    /*
    // New staffs registered within 4 weeks exclude owner
    // Fetch all staffs
    rootGetters['staffs/filter'](item => (
      item.status === 4 && (item.role === 2 || item.role === 3) &&
        new Date().getTime() - new Date(item.publishedAt).getTime() < 4*7*24*3600*1000
    )).forEach(item => (
        notifications.push({
          id: item.id,
          text: `${item.lastName} ${item.firstName}が${item.role === 2 ? 'スタイリスト' : 'アシスタント'}として登録されました。`,
          type: 'notification',
          publishedAt: new Date(item.publishedAt),
          read: false
        })
    ))

    // Staffs promoted to stylists within 4 weeks
    rootGetters['staffs/filter'](item => (
      item.status === 4 && item.role === 2 && item.data.promoted &&
        new Date().getTime() - new Date(item.data.promoted.at).getTime() < 4*7*24*3600*1000
    )).forEach(item => (
        notifications.push({
          id: `p${item.id}`,
          text: `${item.lastName} ${item.firstName}がスタイリストへ昇進しました。`,
          type: 'notification',
          publishedAt: new Date(item.publishedAt),
          read: false
        })
    ))
    */

    // New comment template released within 4 weeks
    rootGetters['comments/filter'](item => (
      item.status === 4 && now.getTime() > item.releaseAt.getTime() &&
        now.getTime() - item.releaseAt.getTime() < 4*7*24*3600*1000
    )).forEach(item => (
        notifications.push({
          id: item.id,
          text: `「${item.message.substring(0, 10)}...」というテンプレートコメントが追加されましたのでご確認ください。`,
          type: 'comment',
          publishedAt: item.releaseAt,
          read: false
        })
    ))

    // Update all
    commit('all', {items: notifications})
  },

  /** Fetch unsent treatments */
  async fetchUnsentTreatments ({rootState}) {
    // Today
    const today = new Date(new Date().setHours(8, 0, 0, 0))
    // Date limit
    const limit = new Date(new Date().getTime() - 28*24*3600*1000)
    // Search payload
    // Unsent treatments within 4weeks
    const payload = {
      // Within 5 days
      datestart: `${limit.getFullYear()}-${(limit.getMonth()+1).toString().padStart(2, 0)}-${limit.getDate().toString().padStart(2, 0)}`,
      // 500 items
      counts: 500
    }

    // Add user ID
    // Don't add id parameter if current user is an admin
    // Admin needs to receive all treatment notifications
    if (!rootState.staffs.me.isAdministrator)
      payload.id = rootState.staffs.me.id

    // Fetch all items
    return (await Promise.all([
      API.getItems(Object.assign({}, payload, {status:2})),
      API.getItems(Object.assign({}, payload, {status:3})),
      API.getItems(Object.assign({}, payload, {status:4}))
    ])).reduce((acc, cur) => {
      // Narrow down to before 8:00AM and overdue
      cur.items.forEach(item => {
        new Date(item.publishedAt).getTime() < today.getTime() &&
        (!Array.isArray(item.data) && item.data.overdue !== undefined && item.data.overdue) &&
          acc.push(item)
      })
      return acc
    }, [])
  },

  /**
   * Fetch customer information specified in payload
   * @returns {object} Keys are customer ID, values are customer information
   */
  async fetchCustomers (context, {items}) {
    return (await API.getCustomers({
      id: [...new Set(items)].join(','),
      count: items.length
    })).items.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {})
  }
}

// mutations
const mutations = {
  /** Clear the collection and add new items */
  all (state, {items}) {
    Vue.set(state, 'all', items.sort((a, b) => b.publishedAt.getTime() - a.publishedAt.getTime()))
  },

  /** Turn read flag of each item on */
  read (state, {items}) {
    items.forEach(item => {
      (j => j && Object.assign(j, {read:true}))(state.all.find(i => i.id === item.id))
    })
  }
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default create()