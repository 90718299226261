<template>
  <div class="oct-form">
    <div class="oct-form__header" v-if="$scopedSlots.header">
      <h2 class="oct-form__header-text">
        <slot name="header" />
      </h2>
    </div>

    <div class="oct-form__content" v-if="$scopedSlots.default">
      <slot />
    </div>

    <footer
      class="oct-form__actions"
      v-if="$scopedSlots.helperActions ||
            $scopedSlots.primaryActions ||
            $scopedSlots.secondaryActions"
    >
      <div class="oct-form__primary-actions">
        <slot name="primaryActions" />
      </div>

      <div class="oct-form__secondary-actions">
        <slot name="secondaryActions" />
      </div>

      <div class="oct-form__helper-actions">
        <slot name="helperActions" />
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'OctForm',
  props: {
  },
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-form {
  margin: 0 0 oct-rem(64);

  &:last-child {
    margin-bottom: 0;
  }
}

.oct-form__header {
  margin: 0 0 oct-rem(28);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(32);
}
}

.oct-form__header-text {
  font-size: oct-rem(17);
  font-weight: normal;
  line-height: (24/17);
  letter-spacing: 0;
  margin: (-5em/17) 0;
  display: block;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(19);
  line-height: (26/19);
  letter-spacing: 0;
  margin: (-5em/19) 0;
}
}

.oct-form__content {
  padding: 0;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding: 0;
}
}

.oct-form__actions {
  margin: oct-rem(32) 0 0;
  display: flex;
  flex-direction: column;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }
}

.oct-form__primary-actions {
  margin: 0 0 oct-rem(16);
  flex: 1 1 auto;
  display: flex;
}

.oct-form__helper-actions {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    cursor: pointer;

    &:hover {
      color: $oct-theme--primary;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  color: $oct-theme--primary;
}
}
</style>

<style lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-form-column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: oct-rem(12-1) 0 oct-rem(12);
  border-top: 1px solid rgba(0,0,0,.2);
}

.oct-form-column-header__primary-text {
  font-size: oct-rem(23);
  font-weight: normal;
  line-height: (28/23);
  letter-spacing: -.001em;
  margin: (-5em/23) 0;
  display: block;
}
</style>