<template>
  <oct-dialog
    full
    :title="title"
    :align="align"
    icon="arrow-left"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-profile-editor"
  >
    <oct-user-form
      :payload="item"
      :editableProperties="{
        profileIcon: userId !== 'new',
        firstName: true,
        lastName: true,
        role: $store.state.staffs.me.isAdministrator,
        email: $store.state.staffs.me.isAdministrator,
        admin: $store.state.staffs.me.isAdministrator
      }"
      :disabledProperties="{
        admin: !userId || userId === my.id
      }"
      @update:payload="onUpdate"
      @submit="submit"
      ref="$_ssPE_form"
    />

    <template #actions>
      <oct-button
        block
        size="large"
        :disabled="$data.$_ssPE_invalid"
        @click.native="$refs.$_ssPE_form.submit()"
      >
        保存
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctUserForm from '@/components/user-form/OctUserForm.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSProfileEditor',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctUserForm,
    OctDialog
  },
  props: {
    /** User ID */
    userId: {
      type: String,
      default: ''
    },
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Dialog alignment */
    align: {
      type: String,
      default: 'left'
    },
  },
  data: () => ({
    $_ssPE_invalid: true 
  }),
  computed: {
    /** Data of a user specified by *userId or me */
    item () {
      return this.userId ?
              (this.$store.getters['staffs/byId'](this.userId) || undefined) :
              this.$store.state.staffs.me
    },
    ...mapState({
      /** Me */
      my: state  => state.staffs.me
    })
  },
  methods: {
    /** Submit data */
    async submit (payload) {
      try {
        // If promoted save it on the data field
        if (this.item && this.item.role > payload.role)
          Object.assign(payload, {
            data: {
              promoted: {
                to: payload.role,
                at: new Date
              }
            }
          })

        // Save data
        await this.$store.dispatch(`staffs/unshift`, {item: payload})

        // If user’s email is updated send verification email
        if (this.userId !== 'new' && payload.email !== this.item.email)
          await API.updateUserEmail(payload.id, payload.email)
            .then(() => this.$store.dispatch('alert', '新しいメールアドレスにメールアドレス確認のご案内をメールいたしました。メールに記載されているURLより新しいメールアドレスの登録をお願いいたします。'))
            .catch(() => this.$store.commit('error', {message: 'メールアドレスを変更できませんでした'}))

        // Go back
        this.$_octPrototypeView_goBack()

        // Notify
        this.$store.dispatch('notify', {
          text: this.userId === 'new' ? '新規スタッフを追加しました' :
            `${this.userId ? 'スタッフ' : 'アカウント'}情報を変更しました`
        })
      } catch (thrown) {
        this.$store.commit('error', {message: 'スタッフを保存できませんでした。メールアドレスが正しく入力されていない、または、他のユーザーと重複していないかご確認ください。'})
      }
    },
    /** Payload update handler */
    onUpdate () {
      // Update validation status
      this.$data.$_ssPE_invalid = this.$refs.$_ssPE_form.invalid
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-profile-editor {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}
</style>