<template>
  <div
    class="oct-form__col"
    :class="[{
      'oct-form__col--dense': dense
    },`oct-form__col--${columnWidth}`]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OctFormCol',
  components: {},
  props: {
    /** Number of column */
    cols: {
      type: Number,
      default: 12
    },
    /** Dense or not */
    dense: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    columnWidth () {
      return !this.cols ? 0 : 12/this.cols
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-form__col {
  position: relative;
  margin: 0;
  flex: 0 0 auto;
  box-sizing: border-box;

  &--dense {
    padding: 0 oct-rem(6);

    &:first-child {
      margin-left: oct-rem(6);
    }

    &:last-child {
      margin-right: oct-rem(6);
    }
  }
  
  &--0 {
    flex: 1 1 auto;
  }

  &--3 {
    flex: 0 0 50%;
  }

  &--6 {
    flex: 1 1 calc(50% - oct-rem(8));
    max-width: calc(50% - oct-rem(8));
  }

  &--9 {
    flex: 0 0 100%;
  }

  .oct-text-field {
    max-width: 100%;
  }

  &::v-deep .oct-text-field__input {
    max-width: 100%;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  position: relative;
  margin: 0 0 oct-rem(12);
  display: flex;
  flex: 0 0 auto;

  &--dense {
    padding: 0 oct-rem(6);

    &:first-child {
      margin-left: oct-rem(6);
    }

    &:last-child {
      margin-right: oct-rem(6);
    }
  }
  
  &--0 {
    flex: 1 1 auto;
  }

  &--3 {
    flex: 0 0 25%;
  }

  &--6 {
    flex: 1 1 calc(50% - oct-rem(16));
    max-width: calc(50% - oct-rem(16));
  }

  &--7 {
    flex: 0 0 (100%*7/12);
    width: (100%*7/12);
  }

  &--8 {
    flex: 0 0 (100%*8/12);
    width: (100%*8/12);
  }

  &--9 {
    flex: 0 0 (100%*9/12);
    width: (100%*9/12);
  }

  &--12 {
    flex: 0 0 100%;
    width: 100%
  }
}
}

.oct-form-col__action {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}
</style>
