<template>
  <oct-dialog
    full
    :title="category ? category.name : ''"
    :icon="'arrow-left'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-product-selector"
  >
    <oct-list
      title="ブランドを選択"
    >
      <oct-list-item
        v-for="item in items"
        :key="item.priority"
        v-bind="item"
        @click.native="
        $router.push({
          path: `${$route.params.categoryId}/brands/${item.id}`,
          query: $route.query
        })"
      >
        <template #trailing-icon>
          <oct-icon icon="chevron-right"/>
        </template>
      </oct-list-item>
    </oct-list>
  </oct-dialog>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSBrandSelector',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctList,
    OctListItem,
    OctDialog
  },
  computed: {
    /** List of brands with products */
    items () {
      return this.$store.state.products.brands
                .filter(item => this.$store.getters['products/products'](
                  this.$route.params.categoryId, item.id
                ).length)
                .map(item => this.brandToListItem(item))
    },
    /** Current category */
    category: vm => vm.$store.getters['products/categoryById'](vm.$route.params.categoryId)
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-product-selector {
  ::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}
</style>