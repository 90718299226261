<template>
  <oct-dialog
    full
    :title="customer ? `お客様検索` : `施術履歴`"
    :icon="'close'"
    @dismiss="$_octPrototypeView_goBack"
    @scroll="onScroll"
    class="ss-treatment-list"
  >
    <!-- Sorter -->
    <div class="ss-treatment-list__actions">
      <!-- Keyword -->
      <oct-text-field
        v-model="q"
        dense
        block
      >
        <template #leading-icon>
          <oct-icon icon="search" />
        </template>
      </oct-text-field>

      <div
        class="ss-treatment-list__action"
        @click="$router.push({name: 'Treatments.filter'})"
      >
        <oct-icon icon="tune" />
      </div>
    </div>

    <oct-list>
      <oct-list-item
        v-for="item in (customer ? collectionByCustomer : collection).items"
        :key="item.id"
        v-bind="$_sstlv_itemToListItem(item)"
        @click.native="customer ?
          $router.push({
            name: 'Customer',
            params: {
              customerId: item.customer
            }
          }).catch(() => {}) :
          $router.push({
            path: `${item.id}`
          }).catch(() => {})"
      >
        <!-- Meta -->
        <template #meta v-if="!customer">
          <oct-chip
            dense
            :selected="item.status != 4"
          >
            {{ item.status != 4 ? '未送信' : '送信済' }}
          </oct-chip>
        </template>

        <!-- Trailing icon -->
        <template #trailing-icon>
          <oct-icon icon="chevron-right"/>
        </template>
      </oct-list-item>
    </oct-list>

    <transition :name="transitionName">
      <router-view :key="$route.path" />
    </transition>
  </oct-dialog>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctList from '@/components/list/OctList.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSTreatmentListView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctChip,
    OctTextField,
    OctList,
    OctListItem,
    OctDialog
  },
  props: {
    /** Whether to search customer */
    customer: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    /** Search keyword */
    q: ''
  }),
  computed: {
    // Stylist‘s ID
    id: {
      get () {
        return this.$store.state.treatments.filter.id.join(',')
      },
      set (val) {
        this.$store.commit('treatments/filter/state', {id:[val],page:1})
      }
    },
    // Sort order
    order: {
      get () {
        return this.$store.state.treatments.filter.sort
      },
      set (val) {
        this.$store.commit('treatments/filter/state', {sort:val,page:1})
      }
    },
    /** Collection by customers */
    collectionByCustomer ()  {
      const customerIds = {}
      const items = this.collection.items.filter(item => {
        // If in customer search and the customer is already shown, set the item hidden
        if (customerIds[item.customer] === undefined) {
          customerIds[item.customer] = true
          return true
        }

        return false
      })

      return {items: items}
    },
    /** Searched items */
    collection: vm => vm.$store.getters['treatments/collection']('searched'),
    /** Collection has next items */
    hasNext: vm => vm.collection.items.length < vm.collection.total
  },
  methods: {
    /** Get list item */
    $_sstlv_itemToListItem (item) {
      const listItem = this.itemToListItem(item)

      // If search query is sat and customer name doesn't match query, set the item hidden.
      listItem.hidden = this.q !== '' && listItem.title.indexOf(this.q) === -1

      return listItem
    },
    /** Scroll handler */
    onScroll (event) {
      // Scroll top is not enough to load next items or items are fully fetched
      if (this.processing || this.collection.items.length >= this.collection.total ||
          event.target.scrollTop + event.target.clientHeight + 100 < event.target.scrollHeight)
        return

      this.processing = true

      this.$store.commit('treatments/filter/state', {
        page: this.$store.state.treatments.filter.page + 1
      })
    }
  },
  watch: {
    /** Search keyword */
    q () {
      this.processing = true

      if (this.$store.state.treatments.filter.page !== 1)
        this.$store.commit('treatments/filter/state', {page:1})
      else
        this.$store.dispatch('treatments/search')
    },
    collection () {
      this.processing = false

      this.$nextTick(() => {
        // The container
        const container = this.$el.querySelector('.oct-list__content')

        // If has next items, search more
        if (!this.processing && this.hasNext && container && container.getBoundingClientRect().bottom < window.innerHeight) {
          this.processing = true

          // Search more
          this.$store.commit('treatments/filter/state', {
            page: this.$store.state.treatments.filter.page + 1
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-treatment-list {
  &::v-deep .oct-dialog__content {
    padding-top: oct-rem(32);
  }
}

.ss-treatment-list__actions {
  margin: 0 0 oct-rem(32);
  display: flex;
  align-items: center;
  column-gap: oct-rem(14);
  padding: 0;

  .oct-select {
    flex: 1 1 auto;
  }
}

.ss-treatment-list__action {
  flex: 0 0 oct-rem(36);
  height: oct-rem(36);
  cursor: pointer;
}
</style>