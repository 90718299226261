<template>
  <div class="oct-checkbox">
    <label
      class="oct-checkbox__label"
      :class="[`oct-checkbox--${type ? type : 'square'}`]"
    >
      <input
        type="checkbox"
        class="oct-checkbox__native-control"
        :value="value"
        :required="required"
        :disabled="disabled"
        v-model="$_octCheckbox_value"
      />
      <div class="oct-checkbox__background">
        <oct-icon  class="oct-checkbox__checkmark" icon="done" v-if="!ordered" />

        <span class="oct-checkbox__index-text" v-else>{{ index === -1 ? '' : index + 1 }}</span>
      </div>
      <span class="oct-checkbox__label-text" v-if="label !== ''">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctCheckbox',
  components: { OctIcon },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    /** Whether checked */
    checked: {
      type: [Boolean,Array,String],
      default: false
    },
    /** Checked Value */
    value: {
      type: [String,Number],
      default: ''
    },
    /** Label text */
    label: {
      type: String,
      default: ''
    },
    /** Checkbox type [square | sign] */
    type: {
      type: String,
      defalut: 'square'
    },
    /** Whether ordered */
    ordered: {
      type: Boolean,
      default: false
    },
    /** External validation function */
    validator: {
      type: Function,
      default: null
    },
    /** Whether required */
    required: {
      type: Boolean,
      default: false
    },
    /** Native disabled attribute */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /** Index number in the array */
    index () {
      if (Array.isArray(this.$_octCheckbox_value)) // model is array
        return this.$_octCheckbox_value.indexOf(this.value)

      return -1
    },
    /** Delegate value */
    $_octCheckbox_value: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    /** Input validation */
    async validate (val) {
      // Empty check
      let invalid = this.required &&
          !this.$el.querySelector('.oct-checkbox__native-control').checked

      // Finally do the external validation
      if (!invalid && this.validator)
        invalid = !await this.validator(val)

      return !invalid
    },
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-checkbox {
  display: inline-block;
}

.oct-checkbox__label {
  display: flex;
  align-items: center;
}

.oct-checkbox__native-control {
  display: none;
}

.oct-checkbox__background {
  margin: 0 oct-rem(8) 0 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 oct-rem(30);
  width: oct-rem(30);
  height: oct-rem(30);
  border: oct-rem(2) solid $oct-theme--neutral-20;

  &:last-child {
    margin-right: 0;
  }

  .oct-checkbox:hover & {
    //border-color: $oct-theme--primary;
  }

  .oct-checkbox__native-control:checked + & {
    background-color: $oct-theme--primary;
    border-color: $oct-theme--primary;
  }

  .oct-checkbox:hover .oct-checkbox__native-control:checked + & {
    border-color: transparent;
    //background-color: rgba(0,0,0,.7);
  }

  .oct-checkbox__native-control:disabled + & {
    background-color: $oct-theme--neutral-10;
    border-color:  $oct-theme--neutral-20;
    border-width: oct-rem(1);
  }

  .oct-checkbox:hover .oct-checkbox__native-control:disabled + & {
    border-color:  $oct-theme--neutral-20;
  }

  .oct-checkbox--sign & {
    border-radius: 50%;
    border-color: rgba(0,0,0,.15);

    .oct-checkbox:hover & {
      border-color: rgba(0,0,0,.25);
    }
  }
}

.oct-checkbox__checkmark {
  fill: $oct-theme--on-primary;
  width: oct-rem(28);
  height: oct-rem(28);
  display: none;

  .oct-checkbox__native-control:checked + .oct-checkbox__background & {
    display: block;
  }

  .oct-checkbox__native-control:disabled + .oct-checkbox__background & {
    fill: $oct-theme--neutral-30;
  }
}

.oct-checkbox__index-text {
  font-size: oct-rem(13);
  line-height: 1;
  color: $oct-theme--on-primary;
  margin: 0;
}

.oct-checkbox__label-text {
  font-size: oct-rem(15);
  line-height: (18/15);
  letter-spacing: -.01em;
  margin: (-4em/15) 0;
  display: block;

  .oct-radio:hover & {
    opacity: .7;
  }

  .oct-checkbox__native-control:disabled ~ & {
    color: $oct-theme--neutral-30;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(18);
  line-height: (24/18);
  margin: (-5em/18) 0;
}
}
</style>
