<template>
  <oct-dialog
    full
    title="お知らせ一覧"
    :align="align"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-notifications"
  >
    <!-- Switch -->
    <div class="ss-notifications__sorter">
      <oct-switch 
        :texts="['通知', 'ニュース']"
        v-model="showNews"
      />
    </div>

    <!-- List -->
    <ul class="ss-notifications-list">
      <!-- List item -->
      <li
        class="ss-notifications-list__item"
        v-for="item in (showAll ? items : items.slice(0, 8))"
        :key="item.id"
        :class="[{
          'ss-notifications-list__item--read': item.read
        }, `ss-notifications-list__item--${item.type}`]"
      >
        <!-- Content -->
        <div class="ss-notification" @click="onClick(item)">
          <div class="ss-notification__content">
            <!-- Visual -->
            <div class="ss-notification__visual">
              <div
                class="ss-notification__icon"
                v-if="item.type === 'notification' || item.type === 'treatment'"
              >
                <oct-icon icon="bel-outline"></oct-icon>
              </div>
              <oct-media
                :image="item.profileIcon"
                v-else
              />
            </div>
            <!-- Texts -->
            <div class="ss-notification__text">
              <!-- Eyebrow -->
              <span class="ss-notification__secondary-text">
                {{ item.eyebrow }}
              </span>
              <!-- Title -->
              <span class="ss-notification__primary-text">
                {{ item.text }}
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Action -->
    <div class="ss-notifications__action" v-if="!showAll && items.length > 8">
      <oct-button
        outline
        @click.native="showAll = true"
      >もっと見る</oct-button>
    </div>
  </oct-dialog>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctSwitch from '@/components/switch/OctSwitch.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

const toRelativeDate = val => {
  const diff = new Date().getTime() - val.getTime()
  return diff < 24*3600*1000 ?
    // Within 24 hours
    `${~~(diff/(3600*1000))}時間前` :
    // Over 24 hours
    `${~~(diff/(24*3600*1000))}日前`
}

export default {
  name: 'SSNotificationsView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctMedia,
    OctButton,
    OctSwitch,
    OctDialog
  },
  props: {
    /** Dialog alignment */
    align: {
      type: String,
      default: 'right'
    },
  },
  data: () => ({
    /** Indicate whether notifications or news are displayed */
    showNews: false,
    /** Indicate to show all items */
    showAll: false
  }),
  computed: {
    /** All notifications or news */
    items: vm =>  vm.showNews ?
      vm.$store.getters['news/released'].map(item => vm.newsToItem(item)) :
      vm.$store.getters['staffs/me/notifications/all'].map(item => vm.notificationToItem(item)),
  },
  methods: {
    /** Convert notification data to a list item */
    notificationToItem (item) {
      return Object.assign({}, item, {
        profileIcon: this.$store.state.profileIcon,
        eyebrow: toRelativeDate(item.publishedAt)
      })
    },
    /** Convert news data to a list item */
    newsToItem (item) {
      return Object.assign({}, item, {
        profileIcon: item.author === 1 ? {
          lowResolution: '/images/news__icon--milbon.png',
          standardResolution: '/images/news__icon--milbon.png',
          highResolution: '/images/news__icon--milbon.png'
        } : {
          lowResolution: '/images/news__icon--style-stock.png',
          standardResolution: '/images/news__icon--style-stock.png',
          highResolution: '/images/news__icon--style-stock.png'
        },
        text: item.title,
        eyebrow: toRelativeDate(item.releaseAt),
        type: 'news'
      })
    },
    /** Item click handler */
    onClick (item) {
      // Mark as read
      this.markAsRead(item)

      // Go to next page specified by type
      // Treatments -> treatments/:id
      // Comment -> my/comments
      // News -> my/news/:id
      if (item.type === 'comment')
        this.$router.push({name: 'Comments.mine', query:{template:true}}).catch(() => {})
      else if (item.type === 'treatment')
        this.$router.push({path: `/treatments/${item.id}`}).catch(() => {})
      else if (item.type === 'news')
        this.$router.push({name: 'News', params: {newsId: item.id}}).catch(() => {})
      else
        this.$_octPrototypeView_goBack()
    },
    /** Mark an item as read */
    markAsRead (item) {
      this.$store.commit(
        item.type === 'news' ? 'news/read' : 'staffs/me/notifications/read',
        {items: [item]}
      )
    }
  },
  mounted () {
    // Restore view
    this.showNews = this.$route.query.news || this.$route.meta.news || false
  },
  watch: {
    'showNews' (val) {
      this.$route.meta.news = val
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-notifications {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}

.ss-notifications__sorter {
  margin-bottom: oct-rem(32);
  display: flex;
  justify-content: center;

  &::v-deep .oct-switch__primary-text {
    min-width: oct-rem(86);
    padding: 0;
  }
}

.ss-notifications-list {
  margin: 0 0 oct-rem(32);
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  padding: 0;
  list-style: none;
}

.ss-notification__content {
  display: flex;
  align-items: flex-start;
  gap: oct-rem(12);

  .ss-notifications-list__item--comment &,
  .ss-notifications-list__item--treatment & {
    cursor: pointer;
  }
}

.ss-notification__visual {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: oct-rem(64);
  height: oct-rem(64);
  border: 1px solid $oct-theme--neutral-20;
}

.ss-notification__icon {
  display: flex;
  align-items: center;
  width: oct-rem(33);
  height: oct-rem(33);
  justify-content: center;
  fill: $oct-theme--neutral;
  background-color: $oct-theme--surface;

  .oct-icon {
    width: oct-rem(46);
    height: oct-rem(46);
  }
}

.ss-notification__text {
  display: flex;
  flex-direction: column;
  gap: oct-rem(4);
  padding: oct-rem(8) 0 0;
}

.ss-notification__primary-text {
  font-size: oct-rem(14);
  font-weight: bold;
  line-height: 1.5;
  display: block;

  .ss-notifications-list__item--read & {
    font-weight: normal;
  }
}

.ss-notification__secondary-text {
  font-size: oct-rem(10);
  font-weight: bold;
  line-height: 1;
  color: $oct-theme--primary;
  display: flex;
  align-items: center;
  gap: oct-rem(4);

  &:after {
    content: "";
    display: block;
    width: oct-rem(8);
    height: oct-rem(8);
    background-color: $oct-theme--primary;
    border-radius: 50%;
  }

  .ss-notifications-list__item--read & {
    font-weight: normal;
    color: $oct-theme--neutral-50;

    &:after {
      content: none
    }
  }
}

.ss-notifications__action {
  margin: 0 0 oct-rem(32);
  display: flex;
  justify-content: center;
}
</style>