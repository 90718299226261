<template>
  <oct-dialog
    full
    :title="$route.meta.title"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-qr-scanner"
    :class="{
      'ss-qr-scanner--processing': processing
    }"
  >
    <oct-customer-card
      :icon="item.profileIcon"
      :title="`${item.name} 様`"
      :text="`${$_octPrototypeView_getDateString(new Date, true)}〜`"
      class="ss-qr-scanner__customer"
      v-if="!processing"
    />

    <div class="ss-qr__content" v-if="processing">
      <canvas id="ss-qr__canvas" class="ss-qr__canvas" hidden></canvas>
      <video playsinline muted id="ss-qr__video" class="ss-qr__video"></video>
      <canvas id="ss-qr__marker" class="ss-qr__marker" hidden></canvas>
    </div>

    <template
      #actions
      v-if="!processing"
    >
      <oct-button
        block
        size="large"
        @click.native="$router.replace({
          name:'Stylists.todays'
        }).catch(() => {})"
      >
        次へ
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctCustomerCard from '@/components/customer-card/OctCustomerCard.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import jsQR from 'jsqr'

let stream, video, canvas, canvasContext, timeoutId;

const drawLine = (context, begin, end, ratio, width=.08) => {
  // Gradient stroke
  const stroke = context.createLinearGradient(begin.x*ratio, begin.y*ratio, end.x*ratio, end.y*ratio);
  stroke.addColorStop(0, 'rgba(255,255,255,1)');
  stroke.addColorStop(width, 'rgba(255,255,255,1)');
  stroke.addColorStop(width+.001, 'rgba(255,255,255,0)');
  stroke.addColorStop(1-width-.001, 'rgba(255,255,255,0)');
  stroke.addColorStop(1-width, 'rgba(255,255,255,1)');
  stroke.addColorStop(1, 'rgba(255,255,255,1)');

  context.beginPath()
  context.moveTo(begin.x*ratio, begin.y*ratio)
  context.lineTo(end.x*ratio, end.y*ratio)
  context.lineWidth = 4
  context.strokeStyle = stroke
  context.stroke()
}

export default {
  name: 'SSQRScanner',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctCustomerCard,
    OctDialog
  },
  data: () => ({
    /** Customer ID */
    customerId: undefined,
    /** Indicate process is going */
    processing: true,
  }),
  computed: {
    item: vm => vm.$store.getters['customers/byId'](vm.customerId)
  },
  methods: {
    /** Start scanning */
    startScanning () {
      // Init
      this.processing = true
      this.$_ssQR_clearMarker()

      // Set elements
      video = document.getElementById('ss-qr__video')
      canvas = document.getElementById('ss-qr__canvas')
      canvasContext = canvas.getContext('2d')

      // Use facingMode: environment to attemt to get the front camera on phones
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: {
            min: 640,
            ideal: 1280,
            max: 1920
          },
          height: {
            min: 480,
            ideal: 720,
            max: 1080
          }
        }
      }).then(response => {
        stream = response
        video.srcObject = response
        video.play()

        // Start scanner
        this.$_ssQR_findQR()
      }).catch(() => {
        this.$store.dispatch('alert', 'カメラを起動できませんでした。ブラウザの設定でカメラへのアクセスを許可してください。')
        this.$_octPrototypeView_goBack()
      })
    },
    /** Stop scanning */
    stopScanning () {
      clearTimeout(timeoutId)
      video && video.pause()
      stream && stream.getTracks().forEach(item => item.stop())
    },
    /** Find QR */
    $_ssQR_findQR () {
      timeoutId = setTimeout(this.$_ssQR_findQR, 300)

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        // Draw the video input to the canvas
        canvasContext.drawImage(video, ...((from, to, ratio) => {
          return ((rf, rt) => {
            return rf > rt ? 
              [(from.width - from.height*rt)/2, 0, from.height*rt, from.height, 0, 0, to.width, to.height] :
              [0, (from.height - from.width/rt)/2, from.width, from.width/rt, 0, 0, to.width, to.height]
          })(
            from.width/from.height,
            from.width/from.height > ratio ? (to.width = from.height*ratio)/(to.height = from.height) :
                                              (to.width = from.width)/(to.height = from.width/ratio)
          )
        })(
          {
            width: video.videoWidth,
            height: video.videoHeight
          },
          canvas,
          video.clientWidth/video.clientHeight
        ))

        // Image from camera
        const image = canvasContext.getImageData(0, 0, canvas.width, canvas.height)
        // QR code
        const code = jsQR(image.data, image.width, image.height, {
          inversionAttempts: 'dontInvert',
        })

        if (code) {
          this.stopScanning()
          this.$_ssQR_drawMarker(code)
          setTimeout(() => this.$_ssQR_setCustomerDataFromCode(code), .8)
        }
      }
    },
    /** Draw marker */
    $_ssQR_drawMarker (code) {
      const marker = document.getElementById('ss-qr__marker')
      const context = marker.getContext('2d')
      const ratio = video.clientWidth/canvas.width

      marker.hidden = false
      marker.width = video.clientWidth
      marker.height = video.clientHeight

      drawLine(context, code.location.topLeftCorner, code.location.topRightCorner, ratio)
      drawLine(context, code.location.topRightCorner, code.location.bottomRightCorner, ratio)
      drawLine(context, code.location.bottomRightCorner, code.location.bottomLeftCorner, ratio)
      drawLine(context, code.location.bottomLeftCorner, code.location.topLeftCorner, ratio)
    },
    /** Clear marker */
    $_ssQR_clearMarker () {
      const marker = document.getElementById('ss-qr__marker')
      marker.getContext('2d').clearRect(0, 0, marker.width, marker.height)
    },
    /** Set customer data */
    async $_ssQR_setCustomerDataFromCode (code) {
      const [customerId, salonCode] = code.data.split('/')

      // Wrong store
      if (salonCode !== this.$store.state.id) {
        this.$store.dispatch('alert', '正しい店舗を指定してください')
        return this.startScanning()
      }

      // Update store
      this.$store.commit('treatments/add', {
        items: [{
          id: 'new',
          salon: salonCode,
          customer: customerId
        }]
      })

      // Update customer ID
      this.customerId = customerId

      // Update customer
      this.$store.dispatch('customers/add', {items:[{id: customerId}]})
        .then(() => {
          // Customer is not found
          if (!this.item) {
            this.$store.dispatch('alrt', '指定されたお客様はまだ店舗に紐づいていません')
            return this.startScanning()
          }

          this.processing = false
        })
        .catch(thrown => this.$store.commit('error', {
          code: 500,
          message: 'お客様情報を取得できませんでした',
          thrown: thrown
        }))
    }
  },
  mounted () {
    // Start
    this.stopScanning()
    this.startScanning()
  },
  destroyed () {
    this.stopScanning()
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-qr-scanner {
  height: 100% !important;

  &::v-deep {
    .oct-dialog {
      overflow: hidden;
    }

    .oct-dialog__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .oct-dialog__actions {
      background-color: transparent;
      animation-name: fade;
      animation-duration: .5s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
  }

  &--processing::v-deep {
    .oct-dialog-header {
      fill: $oct-theme--on-primary;
      color: $oct-theme--on-primary;
    }
  }
}

.ss-qr-scanner__customer {
  justify-content: center;
  height: 100%;
}

.ss-qr__content,
.ss-qr__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-qr__marker {
  position: absolute;
  top: 0;
  left: 0;
}

.ss-qr__canvas {
  position: relative;
  z-index: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  transition-delay: .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>