import _mergeWith from 'lodash/mergeWith'
import API from '@/api/index.js'
import Vue from 'vue'

/**
 * @typedef Salon
 * @property {string} id - Salon ID
 * @property {string} name - Name of the salon
 */

/**
 * Create an empty salon object
 * @param {object} item - Base object
 */
export const createSalon = (item={}) => (
  __mergeWith(
    {
      id: '',
      name: '',
      profileIcon: {},
      status: 4,
      data: {},
      milbonId: '',
      milbonPassword: '',
      ipAddresses: ['']
    },
    [
      item,
      {
        profileIcon: {id: item.profileIcon && item.profileIcon.id || '' },
        releasedAt: item.releasedAt ? new Date(item.releasedAt) : new Date(),
        owners: item.owners && item.owners.length ? item.owners.map(i => ({
          id: i.id,
          firstName: i.firstName,
          lastName: i.lastName,
          email: i.email,
          password: i.password || generatePassword()
        })) :
        [{
          email: '',
          password: generatePassword()
        }]
      }
    ]
  )
)

/**
 * Generate password
 * @params {number} length - Length of the password
 */
export const generatePassword = (length = 10) => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const allCharacters = lowerCaseLetters + upperCaseLetters + numbers;

  let password = '';

  // Pick up one letter form each category
  password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
  password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];

  // Fill up rest of charactors
  for (let i = password.length; i < length; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  // Shuffle the string
  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
}

const __mergeWith = (object, sources) => _mergeWith(object, ...sources, (a, b) => {if (Array.isArray(a)) return b})

/**
 * Normalize parameters. Remove undefined or null value from parameter.
 */
const normalizedParameter = params => (
  Object.keys(params).reduce((acc, cur) => {
    if (params[cur]) acc[cur] = params[cur]
    return acc
  }, {})
)

/**
 * @typedef {Object<number, array>} Results
 * @property {number} total - Total Number of search result
 * @property {array} items - Array of salon ID matched with the query
 */
const state = () => {
  return {
    /** All salons already be fetched */
    all: {},
    /**
     * All salons registered in milbon:ID. The key of entity is a salon ID and value is name of the salon
     * @type {object<string>}
     */
    candidates: {},
    /**
     * Search results by query
     * @type {Results}
     */
    queries: {},
    /** The last used query */
    latestQuery: {page: 1}
  }
}

// getters
const getters = {
  /** Get search result by query */
  byQuery: (state, getters) => query => {
    const result = state.queries[(new URLSearchParams(normalizedParameter(query))).toString()]
    return result ? {
      total: result.total,
      items: result.items.map(item => getters.byId(item))
    } : undefined
  },
  /** Get latest items */
  latest: (state, getters) => (
    getters['byQuery'](state.latestQuery)
  ),
  /**
   * Get salon by ID
   * @params {string} id - Salon ID
   */
  byId: state => id => state.all[id]
}

// actions
const actions = {
  /*
   * Fetch salons
   * @param {Object} payload - Search parameters
   */
  async search ({commit}, payload) {
    const params = normalizedParameter(payload)

    return API.getSalons(normalizedParameter(params)).then(response => {
      // Update latest query
      commit('latestQuery', params)

      // Reset
      commit('query', {
        query: (new URLSearchParams(params)).toString(),
        response
      })

      return Promise.resolve(response)
    })
  },

  /** Fetch all items */
  async fetchAll () {
    const params = {page: 1, count: 1000}
    let response = null
    let items = []

    while ((response = await API.getSalons(params))) {
      items.push(...response.items)
      params.page++

      if (response.total <= items.length || !response.items.length) break
    }

    return items
  },

  /**
   * Fetch an item
   * @param {string} - Salon ID
   */
  async fetchItem ({commit}, {id}) {
    return API.getSalonDetails({id: id})
            .then(response => commit('add', [response]))
  },

  /**
   * Save an item
   */
  async saveItem ({commit}, {payload, replace}) {
    return API.updateSalonDetails(payload, replace)
            .then(() => commit('add', [payload]))
  },

  /** Fetch all salons registered in milbon:iD */
  async fetchCandidates ({commit}) {
    return API.getAllSalons()
            .then(response => commit('candidates', response.reduce((acc, cur) => Object.assign(acc, {[cur.id]: cur.name}), {})))
  }
}

// mutations
const mutations = {
  /**
   * Set query
   * @params {object} query - Query parameters used to search
   * @params {object} response - Search response from API
   */
  query (state, {query, response}) {
    // Add or update queries
    Vue.set(state.queries, query, {
      total: response.total,
      items: response.items.map(item => item.id)
    })

    // Store salons in 'all'
    response.items.forEach(item =>
      Vue.set(state.all, item.id, __mergeWith({}, [(state.all[item.id] || {}), createSalon(item)]))
    )
  },
  /**
   * Set latest query
   * @params {object} quuery - Query object to set
   */
  latestQuery (state, query) {
    state.latestQuery = query
  },
  /**
   * Add items to the collection. If each item is already in the collection, the item is merged
   * @param {object} items - Array of item to add
   */
  add (state, items=[]) {
    items.forEach(item => {
      Vue.set(state.all, item.id, __mergeWith({}, [(state.all[item.id] || {}), createSalon(item)]))
    })
  },
  /**
   * Set candidates
   * @param {object} value - Value to set
   */
  candidates (state, value) {
    Vue.set(state, 'candidates', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}