var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-treatment-list",attrs:{"full":"","title":_vm.customer ? `お客様検索` : `施術履歴`,"icon":'close'},on:{"dismiss":_vm.$_octPrototypeView_goBack,"scroll":_vm.onScroll}},[_c('div',{staticClass:"ss-treatment-list__actions"},[_c('oct-text-field',{attrs:{"dense":"","block":""},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"search"}})]},proxy:true}]),model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}}),_c('div',{staticClass:"ss-treatment-list__action",on:{"click":function($event){return _vm.$router.push({name: 'Treatments.filter'})}}},[_c('oct-icon',{attrs:{"icon":"tune"}})],1)],1),_c('oct-list',_vm._l(((_vm.customer ? _vm.collectionByCustomer : _vm.collection).items),function(item){return _c('oct-list-item',_vm._b({key:item.id,nativeOn:{"click":function($event){_vm.customer ?
        _vm.$router.push({
          name: 'Customer',
          params: {
            customerId: item.customer
          }
        }).catch(() => {}) :
        _vm.$router.push({
          path: `${item.id}`
        }).catch(() => {})}},scopedSlots:_vm._u([(!_vm.customer)?{key:"meta",fn:function(){return [_c('oct-chip',{attrs:{"dense":"","selected":item.status != 4}},[_vm._v(" "+_vm._s(item.status != 4 ? '未送信' : '送信済')+" ")])]},proxy:true}:null,{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)},'oct-list-item',_vm.$_sstlv_itemToListItem(item),false))}),1),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view',{key:_vm.$route.path})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }