<template>
  <oct-account-dialog
    title="パスワード再設定"
    icon="arrow-left"
    class="ss-password-reminder"
    @dismiss="$_octPrototypeView_goBack"
  >
    <oct-form>
      <oct-form-group>
        <oct-text-field
          name="email"
          type="email"
          v-model="$data.$payload.email"
          placeholder="メールアドレス"
          required
          ref="$_ssPasswordReminderView_email"
        >
          <template #helperTextInvalid>
            メールアドレスを入力してください。
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Salon -->
      <oct-form-group>
        <oct-select
          outline
          block
          v-model="$data.$payload.salon"
          :items="[
            {
              value: '',
              text: 'サロンを選択してください'
            }
          ].concat($data.$_ssPR_salons)"
          v-if="$data.$_ssPR_salons.length > 1"
        />
      </oct-form-group>

      <template #primaryActions>
        <oct-button
          size="large"
          block
          :disabled="invalid"
          @click.native="submit"
        >
          送信
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSPasswordReminderView',
  mixins: [
    OctPrototypeView,
    OctPrototypeForm
  ],
  components: {
    OctButton,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctAccountDialog
  },
  props: {
    /** Payload */
    payload: {
      type: Object,
      default: () => ({
        salon: '',
        email: ''
      })
    }
  },
  data: () => ({
    /** Salon list the user belongs */
    $_ssPR_salons: []
  }),
  methods: {
    /** Submit handler */
    async beforeSubmit () {
      try {
        // My salons if salon has not been sat
        if (this.$data.$payload.salon === '')
          await API.getMySalons({email: this.$data.$payload.email})
            .then(response => this.$data.$payload.salon = response[0].id)

        // Send reminder
        await API.remindPassword(this.$data.$payload.salon, this.$data.$payload.email)

        this.$router.push({name:'PasswordReminder.sent'})
      } catch {
        this.$store.commit('error', {message: 'パスワード再設定メールを送信できませんでした'})
      }
    },
  },
  watch: {
    '$data.$payload.email': {
      handler (val) {
        // Build salon selector
        API.getMySalons({email: val})
          .then(response => {
            this.$data.$_ssPR_salons = response.map(item => ({
              value: item.id,
              text: item.name
            }))
            // Set salon ID if only one salon is available
            if (response.length === 1)
              this.$data.$payload.salon = response[0].id
            else
              this.$data.$payload.salon = ''
          })
          .catch(() => {
            this.$data.$_ssLV_salons = []
            this.$data.$payload.salon = ''
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>