var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ss-salon-list-view"},[_c('div',{staticClass:"ss-salon-list-view-header"},[_c('div',{staticClass:"ss-salon-list-view-header__section"},[_c('span',{staticClass:"ss-salon-list-view-header__secondary-text"},[_vm._v("表示件数")]),_c('oct-select',{attrs:{"outline":"","dense":"","items":[
          {
            value: 20,
            text: '20件'
          },
          {
            value: 50,
            text: '50件'
          },
          {
            value: 100,
            text: '100件'
          }
        ]},model:{value:(_vm.numberOfDisplay),callback:function ($$v) {_vm.numberOfDisplay=$$v},expression:"numberOfDisplay"}})],1),_c('div',{staticClass:"ss-salon-list-view-header__section"},[_c('span',{staticClass:"ss-salon-list-view-header__primary-text"},[_vm._v(" すべて（"+_vm._s((_vm.$store.getters['salons/latest'] || {total: 0}).total)+"） ")])]),_c('div',{staticClass:"ss-salon-list-view-header__section ss-salon-list-view-header__section--align-end"},[_c('oct-button',{attrs:{"outline":"","icon":"download","size":"small","align":"center"},nativeOn:{"click":function($event){return _vm.download.apply(null, arguments)}}},[_vm._v(" CSV出力 ")])],1)]),_c('oct-table',{attrs:{"headers":_vm.$data.$_ssSLV_headers,"tableData":_vm.items,"filters":_vm.$data.$_ssSLV_filters,"fixedColumns":2},on:{"click:menu":_vm.onClickFilter,"click:row":function($event){return _vm.$router.push({name: 'Management.salons.edit', params: {salonId: $event[0]}})}}}),_c('div',{staticClass:"ss-salon-list-view__action"},[_c('oct-button',{attrs:{"block":"","outline":"","icon":"plus","size":"large"},nativeOn:{"click":function($event){_vm.$router.push({name: 'Management.salons.edit', params: {salonId:'new'}}).catch(() => {})}}},[_vm._v(" サロン追加 ")])],1),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }