var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-customer",attrs:{"full":"","title":_vm.$route.meta.title,"icon":"arrow-left"},on:{"dismiss":_vm.$_octPrototypeView_goBack},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('oct-button',{attrs:{"block":"","size":"large"},nativeOn:{"click":function($event){return _vm.addNewTreatment.apply(null, arguments)}}},[_vm._v(" 新規施術追加 ")])]},proxy:true}])},[_c('div',[_c('oct-customer-card',{staticClass:"ss-customer-intro",attrs:{"icon":_vm.customer ? _vm.customer.profileIcon : undefined,"title":_vm.customer ? `${_vm.customer.name} 様` : ''}}),_c('div',{staticClass:"ss-customer__header"},[_c('span',{staticClass:"ss-customer__primary-text"},[_vm._v(" 過去1年間に"+_vm._s(_vm.collection.items.length)+"件の施術履歴があります ")])]),_c('div',{staticClass:"ss-customer__content"},[_c('div',{staticClass:"ss-customer-history"},[_c('ul',{staticClass:"ss-customer-history-list"},_vm._l((_vm.collection.items),function(item){return _c('li',{key:item.id,staticClass:"ss-customer-history-list__item",on:{"click":function($event){_vm.$router.push({
              path: `../${item.id}`,
            }).catch(() => {})}}},[_c('div',{staticClass:"ss-customer-history__content"},[_c('div',{staticClass:"ss-customer-history__body"},[_c('oct-media',{staticClass:"ss-customer-history__media",attrs:{"image":item.images.length ? item.images[0] : {
                    lowResolution: '/images/customer__default.png',
                    standardResolution: '/images/customer__default.png',
                    highResolution: '/images/customer__default.png'
                  },"alt":_vm.customer.name}}),_c('div',{staticClass:"ss-customer-history__text"},[_c('span',{staticClass:"ss-customer-history__primary-text"},[_vm._v(" "+_vm._s(_vm.$_octPrototypeView_getDateString(item.publishedAt))+" ")]),_c('ul',{staticClass:"ss-customer-history-products"},[_vm._l((_vm.getProducts(item).slice(0, 4)),function(product){return _c('li',{key:product.id,staticClass:"ss-customer-history-products__item"},[_c('oct-media',{staticClass:"ss-customer-history-products__media",attrs:{"image":{
                          lowResolution: product.image,
                          standardResolution: product.image,
                          highResolution: product.image
                        },"alt":product.name}})],1)}),(_vm.getProducts(item).length > 4)?_c('li',{staticClass:"ss-customer-history-products__item ss-customer-history-products__item--more"},[_c('oct-icon',{attrs:{"icon":"more-horiz"}})],1):_vm._e()],2)])],1),_c('div',{staticClass:"ss-customer-history__meta"},[_c('oct-chip',{attrs:{"dense":"","selected":item.status != 4}},[_vm._v(" "+_vm._s(item.status != 4 ? '未送信' : '送信済')+" ")]),_c('oct-icon',{staticClass:"ss-customer-history__trailing-icon",attrs:{"icon":"chevron-right"}})],1)])])}),0)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }