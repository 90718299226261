<template>
  <oct-account-dialog
    title="送信完了しました"
    text="パスワード設定のご案内をメールいたしました。メールに記載されているURLよりパスワードの設定登録をお願いいたします。"
    caption="メールが届かない場合、管理者にお問い合わせください。"
    class="ss-signup-complete"
  >
  </oct-account-dialog>
</template>

<script>
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'

export default {
  name: 'SSSignUpCompleteView',
  components: {
    OctAccountDialog
  },
}
</script>

<style scoped lang="scss">
</style>