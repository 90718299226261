export default () => {
  return {
    /** Resource ID */
    id: '',
    /** Low resolution image URL */
    lowResolution: '',
    /** Standard resolution image URL */
    standardResolution: '',
    /**
     * Process status
     * 2. Processing
     * 4. Done
     */
    status: 1
  }
}
