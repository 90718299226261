<template>
  <oct-account-dialog
    :title="verified ? 'メールアドレスを変更しました' :  'メールアドレスを変更できません'"
    :text="verified ? undefined : '管理者にお問い合わせください。'"
    class="ss-password-reset"
    v-show="verified !== null"
  >
    <oct-form>
      <template #primaryActions>
        <oct-button
          size="large"
          block
          @click.native="$router.push({name:'Login'})"
        >
          ログインページへ
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'

export default {
  name: 'SSVerifyEmailView',
  components: {
    OctButton,
    OctForm,
    OctAccountDialog
  },
  data: () => ({
    verified: null
  }),
  created () {
    // Verify email
    API.verifyUserEmail(this.$route.query.code)
      .then(() => this.verified = true)
      .catch(() => this.verified = false)
  }
}
</script>

<style scoped lang="scss">
</style>