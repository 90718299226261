<template>
  <oct-dialog
    full
    :title="title"
    align="left"
    icon="arrow-left"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-user-list"
  >
    <!-- Sorter -->
    <div class="ss-user-list__actions">
      <oct-select
        block
        dense
        v-model="order"
        :items="[
          {
            value: 'desc',
            text: '新しい順'
          },
          {
            value: 'asc',
            text: '古い順'
          }
        ]"
      />
      
      <oct-select
        block
        dense
        v-model="role"
        :items="[
          {
            value: '',
            text: '全てのスタッフ'
          },
          {
            value: '1',
            text: 'オーナー'
          },
          {
            value: '2',
            text: 'スタイリスト'
          },
          {
            value: '3',
            text: 'アシスタント'
          },
          {
            value: '4',
            text: 'スタッフ'
          }
        ]"
      />
    </div>

    <!-- List -->
    <oct-list>
      <oct-list-item
        v-for="item in items"
        :key="item.id"
        :media="{
          image: $_octPrototypeView_getProfileIconSafely(item.profileIcon)
        }"
        :title="`${item.lastName} ${item.firstName}`"
        :text="$_octPrototypeView_getRoleNameById(item.role)"
        @click.native="$router.push({
          path: `${$route.path}/${item.id}`
        }).catch(() => {})"
      >
        <!-- Meta -->
        <template #meta>
          <oct-chip
            selected
            type="danger"
            v-if="item.status === 1"
          >
            停止中
          </oct-chip>

          <oct-chip
            selected
            v-if="item.status === 2"
          >
            未登録
          </oct-chip>

          <oct-chip
            outline
            selected
            v-if="item.data.verified === false"
          >
            未承認
          </oct-chip>
        </template>

        <!-- Trailing icon -->
        <template #trailing-icon>
          <oct-icon icon="chevron-right"/>
        </template>
      </oct-list-item>
    </oct-list>

    <!-- Child routes -->
    <transition :name="transitionName">
      <router-view class="ss-dialog__content" :key="$route.name"/>
    </transition>

    <!-- Actions -->
    <template
      #actions
    >
      <!-- Actions -->
      <div class="ss-user-list-footer__actions">
        <!-- Sign Up URL -->
        <div class="ss-user-list-footer__section">
          <!-- URL -->
          <oct-text-field
            :value="$data.$_ssULV_signUpURL"
            block
            class="ss-user-list-footer__text-field"
          />

          <!-- Refresh -->
          <oct-button
            class="ss-user-list-footer__icon-button"
            icon="refresh"
            outline
            @click.native="$_ssUSV_setSignUpURL(true)"
          />

          <!-- Copy -->
          <oct-button
            class="ss-user-list-footer__button"
            outline
            @click.native="$_ssUSV_copyToClipboard($data.$_ssULV_signUpURL)"
          >
            URLコピー
          </oct-button>
        </div>

        <!-- Primary Action -->
        <div>
          <oct-button
            block
            size="large"
            @click.native="$router.push({
              path: `${$route.path}/new`
            }).catch(() => {})"
          >
            新規スタッフ追加
          </oct-button>
        </div>
      </div>
    </template>
  </oct-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctList from '@/components/list/OctList.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSUserListView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctChip,
    OctTextField,
    OctSelect,
    OctList,
    OctListItem,
    OctDialog
  },
  props: {
    /** Page title */
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // Sign up URL
      $_ssULV_signUpURL: '',
      // Sort order
      $order: this.$route.meta.order,
      // Indicate the role of users to show
      $role: this.$route.meta.role,
    }
  },
  computed: {
    order: {
      get () {
        return this.$data.$order ||  'desc'
      },
      set (val) {
        this.$data.$order = this.$route.meta.order = val
      }
    },
    role: {
      get () {
        return this.$data.$role ||  ''
      },
      set (val) {
        this.$data.$role = this.$route.meta.role = val
      }
    },
    // Array of users filtered by *order and *role
    items: vm => vm.$store.state.staffs.all.concat()
                  // Filter by role
                  .filter(item => !vm.role || item.role == vm.role)[vm.order === 'asc' ? 'reverse' : 'concat']()
  },
  methods: {
    /** Set sign up URL */
    async $_ssUSV_setSignUpURL (refresh=false) {
      try {
        const token = await this.$_ssUSV_getSalonToken(refresh)
        this.$data.$_ssULV_signUpURL = `${location.origin}/#signup/${this.$store.state.id}/${token}?salon=${this.$store.state.name}`
      } catch {
        this.$store.dispatch('alert', '新規スタッフ追加URLを設定できませんでした。しばらく経ってからもう一度やり直してください。')
      }
    },
    /** Get salon token */
    async $_ssUSV_getSalonToken (refresh=false) {
      return (await API.getSalonToken({id: this.$store.state.id, refresh})).token
    },
    /** Copy text to clipboard */
    $_ssUSV_copyToClipboard (text) {
      navigator.clipboard && navigator.clipboard.writeText(text)
      // Notify
      this.$store.dispatch('notify', {text: 'コピーしました'})
    }
  },
  async mounted () {
    this.$_ssUSV_setSignUpURL()

    /** Remove test staffs
    for (const item of this.$store.state.staffs.all) {
      item.lastName === '巡回' && await this.$store.dispatch('staffs/remove', {item})
    }
    */
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-user-list {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(16);
  }
}
}

.ss-user-list__actions {
  margin: 0 0 oct-rem(32);
  display: flex;
  column-gap: oct-rem(14);
  padding: 0;

  .oct-select {
    flex: 1 1 auto;
  }
}

/* Containers */
.ss-user-list-footer__section {
  display: flex;
  align-items: center;
  gap: oct-rem(16);
}

/* Footer */
.ss-user-list-footer__actions {
  display: flex;
  flex-direction: column;
  gap: oct-rem(24);
  width: 100%;
}

.ss-user-list-footer__icon-button {
  flex: 0 0 auto !important;
  border-color: $oct-theme--neutral-20;
}

.ss-user-list-footer__button {
  flex: 1 1 auto !important;
}
</style>