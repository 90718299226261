export default function updateNotificationPlugin (tick=null) {
  return store => {
    const update = async () => {
      // Update notifications every 5 min if store ID is sat
      if (!store.state.staffs.me.id || !store.state.id || (tick && new Date().getTime() - tick.getTime() < 5*60*1000)) return

      tick = new Date

      // Fetch all staffs
      await store.dispatch('staffs/fetchAll')
      // Fetch all comment templates
      await store.dispatch('comments/fetch', {id: store.state.id})
      // Fetch all released news
      await store.dispatch('news/fetchReleased')

      // Update notifications
      store.dispatch('staffs/me/notifications/update')
    }

    // Subscribe mutaion
    store.subscribe(mutation => {
      // Clear tick if a user signs in newly
      if (mutation.type === 'state' && mutation.payload.id)
        tick = null

      // Update read notifications
      if (mutation.type === 'staffs/me/notifications/read' && mutation.payload.save !== false ) {
        // Update read items in the user data
        store.commit('staffs/me/data', {
          item: {
            notifications: {
              read: store.getters['staffs/me/notifications/read'].map(item => ({id: item.id}))
            }
          }
        })
        // Save user data
        store.dispatch('staffs/me/save')
      }

      // Update read news
      if (mutation.type === 'news/read' && mutation.payload.save !== false ) {
        // Update read items in the user data
        store.commit('staffs/me/data', {
          item: {
            news: {
              read: store.getters['news/read'].map(item => ({id: item.id}))
            }
          }
        })
        // Save user data
        store.dispatch('staffs/me/save')
      }

      if (mutation.type === 'staffs/me/notifications/all' || mutation.type === 'news/released' ) {
        // Update read notifications
        store.commit('staffs/me/notifications/read', {
          items: store.state.staffs.me.data.notifications.read,
          save: false
        })
        // Update read news
        store.commit('news/read', {
          items: store.state.staffs.me.data.news.read,
          save: false
        })
      }
    })

    // Try to update every 3 sec
    setInterval(update, 3000)
  }
}