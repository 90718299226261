<template>
  <oct-dialog
    full
    :title="title"
    :align="align"
    icon="arrow-left"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-comment-editor"
  >
    <!-- Form -->
    <oct-form>
      <!-- Release date and time -->
      <oct-form-row v-if="!isMyComment">
        <!-- Release date -->
        <oct-form-col :cols="2">
          <oct-form-group
            label="公開日"
          >
            <oct-text-field
              type="date"
              required
              v-model="$data.$payload.releaseDate"
              :placeholder="dateToString(new Date())"
              ref="$_ssCE_releaseDate"
            >
              <template #helperTextInvalid>
                公開日を入力してください
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>

        <!-- Release time -->
        <oct-form-col :cols="2">
          <oct-form-group
            label="公開時間"
          >
            <!-- Release time from 10:00 to 18:00 -->
            <oct-select
              block
              outline
              v-model="$data.$payload.releaseTime"
              :items="Array(8).fill(0).map((val, i) => (
                {
                  value: `${i+10}:00`,
                  text: `${i+10}:00`
                }
              ))"
              ref="$_ssCE_releaseTime"
            />
          </oct-form-group>
        </oct-form-col>
      </oct-form-row>

      <!-- Publish status -->
      <oct-form-group
        label="公開状態"
        v-if="!isMyComment"
      >
        <oct-checkbox
          label="非公開"
          v-model="isUnPublisehd"
        />
      </oct-form-group>

      <!-- Message -->
      <oct-form-group>
        <oct-text-field
          :rows="isMyComment ? 16 : 10"
          :lazy="false"
          required
          block
          v-model="$data.$payload.message"
          ref="$_ssCE_message"
        >
          <template #helperTextInvalid>
            コメントを入力してください
          </template>
        </oct-text-field>
      </oct-form-group>
    </oct-form>

    <!-- Actions -->
    <template #actions>
      <oct-button
        size="large"
        block
        :disabled="invalid || !$data.$payload.message"
        @click.native="submit"
        class="ss-comment-editor__action"
      >
        {{ commentId ? '更新' : '保存' }}
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSCommentEditor',
  mixins: [
    OctPrototypeView,
    OctPrototypeForm
  ],
  components: {
    OctButton,
    OctCheckbox,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctFormRow,
    OctFormCol,
    OctDialog
  },
  props: {
    /** Comment ID */
    commentId: {
      type: String,
      default: ''
    },
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Dialog alignment */
    align: {
      type: String,
      default: 'right'
    },
    /** Indicate path of module to use, ‘staffs/me’ or ‘’ (store root) */
    module: {
      type: String,
      default: 'staffs/me'
    },
    /** Comment payload */
    payload: {
      type: Object,
      default: () => ({
        /** Comment ID (empty string by default) */ 
        id: '',
        /** Comment text (empty string by default) */  
        message: '',
        /** Comment status (4 by default) */ 
        status: 4,
        /**  Indicate this comment is sat as default */
        default: false,
        /** Release date of the comment (YYYY/MM/DD - Today by default) */ 
        releaseDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
        /** Release time of the comment (HH:MM - ‘10:00’ by default) */
        releaseTime: '10:00'
      })
    }
  },
  data () {
    return {}
  },
  computed: {
    /** Indicate the comment is mine */
    isMyComment () {
      return /\/me\//.test(this.module)
    },
    /** Indicate the comment is unpublished */
    isUnPublisehd: {
      get () {
        return this.$data.$payload.status !== 4
      },
      set (val) {
        this.$data.$payload.status = val ? 3 : 4
      }
    }
  },
  methods: {
    /** Submit item */
    async beforeSubmit (payload) {
      try {
        // Combines the publication date and time set in payload
        payload.releaseAt = new Date(`${payload.releaseDate}T${payload.releaseTime}:00`)

        // Update and save target comment of the module in the store
        await this.$store.dispatch(`${this.module}/${this.isMyComment ? 'save' : 'unshift'}`, payload)

        // Go back
        this.$_octPrototypeView_goBack()

        // Notify
        this.$store.dispatch('notify', {
          text: `${/\/me\//.test(this.module) ? 'マイ' : 'テンプレート'}コメントを${this.commentId ?  '保存' : '作成' }しました`
        })
      } catch (thrown) {
        this.$store.commit('error', thrown)
      }
    },
    /** Date to string */
    dateToString: val => `${val.getFullYear()}-${(val.getMonth() + 1).toString().padStart(2, '0')}-${val.getDate().toString().padStart(2, '0')}`
  },
  watch: {
    commentId: {
      handler (val) {
        // Merge payload
        Object.assign(this.$data.$payload, this.$store.getters[`${this.module}/byId`](val) || this.$data.$payload)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-comment-editor {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}

.ss-comment-editor__action {
  min-width: oct-rem(100);
}
</style>