<template>
  <oct-dialog
    full
    :title="title"
    :align="align"
    :icon="userId ? 'arrow-left' : 'close'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-profile"
  >
    <!--
      editable: Staff controls enabled in staff’s profile only for admin
      enableSecondaryActions: Staff list button enabled in admin’s my profile
    -->
    <oct-user-card
      :text="$store.state.name"
      :eyebrow="item.isAdministrator ? '' : $_octPrototypeView_getRoleNameById(item.role)"
      :title="`${item.lastName} ${item.firstName}`"
      :image="item.profileIcon"
      :status="item.data.verified === false ? 0 : item.status"
      :editable="me.isAdministrator && userId !== ''"
      :enableSecondaryActions="me.isAdministrator && !$store.getters['isManagement'] && userId === ''"
      @click:edit="$router.push({
        path: `${$route.path}/settings`
      })"
      @click:remove="removeUser"
      @click:verify="verifyUser"
      @click:suspend="toggleSuspend"
      @click:remind="sendReminder"
      @click:signout="signOut"
    />

    <!-- Child routes -->
    <transition :name="transitionName">
      <router-view class="ss-dialog__content" :key="$route.name"/>
    </transition>
  </oct-dialog>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index.js'
import OctUserCard from '@/components/user-card/OctUserCard.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSProfileView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctUserCard,
    OctDialog
  },
  props: {
    /** User ID */
    userId: {
      type: String,
      default: ''
    },
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Dialog alignment */
    align: {
      type: String,
      default: 'left'
    },
  },
  computed: {
    /** Current user */
    item () {
      return this.userId ?
              (this.$store.getters['staffs/byId'](this.userId) || {}) :
              this.$store.state.staffs.me
    },
    ...mapState({
      me: state => state.staffs.me
    })
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .then(() => this.$router.replace({name:'Welcome'}).catch(() => {}))
        .catch(thrown => this.$store.commit('error', thrown))
    },
    /** Remove current user */
    async removeUser () {
      try {
        // Confirm 
        await this.$store.dispatch('confirm', 'スタッフを削除しますか')

        // Remove a user
        this.$store.dispatch('staffs/remove', {item:this.item})
          .then(() => {
            // Go back
            this.$_octPrototypeView_goBack()

            // Notify
            this.$store.dispatch('notify', {
              type: 'danger',
              text: 'スタッフを削除しました'
            })
          })
          .catch(() => this.$store.commit('error', {messsage: 'ユーザーを削除できませんでした'}))
      } catch {
        // Do nothing
      }
    },
    /** Verify a user */
    verifyUser () {
      const user = this.item
      user.data.verified = true

      this.$store.dispatch('staffs/save', {item: user})
        .then(() => (
          // Notify
          this.$store.dispatch('notify', {
            type: 'default',
            text: 'スタッフを承認しました'
          })
        ))
        .catch(thrown => this.$store.commit('error', thrown))
    },
    /** Toggle suspend status of the user */
    toggleSuspend () {
      this.$store.dispatch('staffs/save', {item: Object.assign({}, this.item, {
        status: this.item.status === 4 ? 1 : 4
      })})
        .then(() => (
          // Notify
          this.$store.dispatch('notify', {
            type: this.item.status !== 4 ? 'danger' : 'default',
            text: this.item.status === 4 ? 'スタッフ停止を解除しました' : 'スタッフを停止しました'
          })
        ))
        .catch(thrown => this.$store.commit('error', thrown))
    },
    /** Send reminder of registration */
    sendReminder () {
      // Remind
      this.$store.dispatch('staffs/remind', {item:this.item})
        .then(() => (
          // Notify
          this.$store.dispatch('notify', {
            text: 'パスワード登録メールを再送しました'
          })
        ))
        .catch(thrown => this.$store.commit('error', thrown))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-profile {
  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }

  &::v-deep .oct-dialog__content {
    margin-bottom: oct-rem(58);
  }
}
</style>