import _cloneDeep from 'lodash/cloneDeep'

let publicPath = 'https://sstock.milbon.co.jp/api/v1'
let milbonid = 'https://salon.milbon.co.jp'

if (/^(localhost|stg\.sstock\.milbon\.co\.jp)/.test(location.hostname)) {
  publicPath = 'https://stg.sstock.milbon.co.jp/api/v1'
  milbonid = 'https://stg.salon.milbon.co.jp'
}

const state = {
  user: {
    id: undefined
  },
  options: {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  accessToken: '',
  refreshToken: '',
  api: {
    signup: `${publicPath}/auth/signup`,
    signin: `${publicPath}/auth/signin`,
    signout: `${publicPath}/auth/signout`,
    verifyEmail: `${publicPath}/auth/email`,
    refresh: `${milbonid}/api/refresh_token.aspx`,
    userReminder: `${publicPath}/users/reminder`,
    passwordReminder: `${publicPath}/auth/password/reminder`,
    passwordReset: `${publicPath}/auth/password/reset`,
    email: `${publicPath}/message/email`,
    salon: (id, payload) => `${publicPath}${id ? '' : '/auth'}/salons${id ? `/${id}` : ''}?${new URLSearchParams(payload)}`,
    salons: payload => payload && payload.id ? `${publicPath}/management/salons/${payload.id}` : `${publicPath}/management/salons${payload ? '?' + new URLSearchParams(payload) : ''}`,
    salonsMilbonId: `${publicPath}/management/milbonid/salons`,
    salonToken: id => `${publicPath}/management/salons/${id}/token`,
    user: (id='') => `${publicPath}/users${id ? '/' : ''}${id}`,
    userEmail: (id='') => `${publicPath}/users/${id}/email`,
    users: payload => `${publicPath}/users?${new URLSearchParams(payload)}`,
    item: id => `${publicPath}/treatments${id ? `/${id}` : ''}`,
    items: payload => `${publicPath}/treatments?${new URLSearchParams(payload)}`,
    news: payload => `${publicPath}/news?${new URLSearchParams(payload)}`,
    newsItem: id => `${publicPath}/news${id ? '/' : ''}${id}`,
    products: (payload) => `${milbonid}/api/products.aspx?${new URLSearchParams(payload)}`,
    customers: (payload) => `${milbonid}/api/members.aspx?${new URLSearchParams(payload)}`,
    history: (id) => `${publicPath}/customer/${id}/treatments`,
    comments: (id, type, commentId, payload) => `${publicPath}/${type}/${id}/comments${commentId ? `/${commentId}` : ''}?${new URLSearchParams(payload)}`,
    upload: (payload, video=false) => `${publicPath}/upload/${video ? 'video' : 'photo'}?${new URLSearchParams(payload)}`,
    download: (id, video=false) => `${publicPath}/download/${video ? 'video' : 'photo'}/${id}`,
  },
  /**
   * Promises of currently loading meidas
   * @param {promise} [Asset ID] Promise object
   */
  mediaCache: {}
}

/** Create date parameter */
const getDateParameter = (val, time=false) => {
  const t = time ? ` ${val.getHours().toString().padStart(2, '0')}:${val.getMinutes().toString().padStart(2, '0')}` : ''
  return `${val.getFullYear()}-${(val.getMonth()+1).toString().padStart(2, '0')}-${val.getDate().toString().padStart(2, '0')}${t}`
}

/** Strip unnecessary parameter */
const normalizedParameter = (val) => {
  Object.keys(val).forEach(key => {
    if (val[key] === '' || val[key] === undefined || val[key] === null)
      delete val[key]
  })
  return val
}

/** Key name of localStorage */
const keyOfStorage = '@@stylestockspajs@@'

/** Store token in local storage */
const saveToken = data => {
  localStorage.setItem('@@stylestockspajs@@',
    JSON.stringify(Object.assign(
      JSON.parse(localStorage.getItem('@@stylestockspajs@@') || '{}')
      , data)
  ))
  return data
}

/** Load token from local storage */
const loadToken = () => (
  updateToken(((data) => (
    JSON.parse(data || '{}')
  ))(localStorage.getItem('@@stylestockspajs@@')))
)

/** Update tokens */
const updateToken = data => {
  // Update state
  state.accessToken = data.accessToken || ''
  state.refreshToken = data.refreshToken || ''
  state.options.headers['X-Authorization'] = state.accessToken ?
    `Bearer ${state.accessToken}` : undefined
  state.user = data.id ? {id: data.id} : (data.user ? data.user : state.user)

  // Save
  saveToken({
    user: state.user,
    accessToken: state.accessToken,
    refreshToken: state.refreshToken
  })

  return data
}

/** Analyze API resonse */
const analyzeResponse = response => {
  if (response.code)
    throw(response)

  if (response.err)
    throw(response.err)

  return response
}

// Load token
loadToken()

const exported = {
  /** Signup */
  async signUp (password, code) {
    return fetch(state.api.signup, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: password,
        code: code
      })
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Signup with token */
  async signUpWithToken (payload, token) {
    return fetch(state.api.user(), {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Sign in with email and password */
  async signInWithEmailAndPassword (salon, email, password) {
    return fetch(state.api.signin, {
      method: 'POST',
      body: JSON.stringify({
        salon: salon,
        email: email,
        password: password
      })
    })
      .then(response => response.json())
      // Update token
      .then(json => updateToken(analyzeResponse(json)))
  },

  /**
   * Refresh token
   */
  async refreshToken () {
    return fetch(state.api.refresh, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.refreshToken}`
      }
    })
      .then(response => response.json())
      .then(async json => updateToken(Object.assign({
          refreshToken: state.refreshToken
        }, {
          accessToken: (await analyzeResponse(json)).access_token
        })
      ))
  },

  /** Send remind mail of registration */
  remindUser (id) {
    return fetch(state.api.userReminder, Object.assign({
      method: 'POST',
      body: JSON.stringify({id:id})
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Send password remind mail */
  remindPassword (salon, email) {
    return fetch(state.api.passwordReminder, Object.assign({
      method: 'POST',
      body: JSON.stringify({
        salon: salon,
        email: email
      })
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Reset password */
  async resetPassword (password, code) {
    return fetch(state.api.passwordReset, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: password,
        code: code
      })
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Verify email */
  async verifyUserEmail (code) {
    return fetch(state.api.verifyEmail, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: code
      })
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get salon */
  async getSalon (id) {
    return fetch(state.api.salon(id), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Fetch salons */
  async getSalons (payload) {
    return fetch(state.api.salons(payload), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Fetch all salons registered in milbon:iD */
  async getAllSalons () {
    return fetch(state.api.salonsMilbonId, Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Fetch detailed information of a salons specified by ID
   * @param {string} id - Salon ID
   */
  async getSalonDetails ({id}) {
    return fetch(state.api.salons({id: id}), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Update detailed information of a salons specified by ID
   */
  async updateSalonDetails (payload={}, replace=true) {
    return fetch(state.api.salons(replace ? {id: payload.id} : undefined), Object.assign({
      method: replace ? 'PUT' : 'POST',
      body: JSON.stringify(payload)
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Fetch salon token
   * @param {string} id - Salon ID
   */
  async getSalonToken ({id, refresh}) {
    return fetch(state.api.salonToken(id), Object.assign({
      method: refresh ? 'PUT' : 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get user */
  async getUser (id=null) {
    id = id ? id : (state.user ? state.user.id : 'undefined')
    return fetch(state.api.user(id), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Update user */
  async updateUser (payload) {
    return fetch(state.api.user(payload.id), Object.assign({
      method: payload.id ? 'PUT' : 'POST',
      body: JSON.stringify(payload)
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Update user’s email and verification email is sent */
  async updateUserEmail (id, email) {
    return fetch(state.api.userEmail(id), Object.assign({
      method: 'PUT',
      body: JSON.stringify({
        email: email
      })
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Remove a user */
  async removeUser (payload) {
    return fetch(state.api.user(payload.id), Object.assign({
      method: 'DELETE'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get users */
  async getUsers (payload) {
    return fetch(state.api.users(payload), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get item */
  async getItem (id=null) {
    return fetch(state.api.item(id), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Remove item */
  async removeItem (id) {
    return fetch(state.api.item(id), Object.assign({
      method: 'DELETE'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get items */
  async getItems (payload) {
    // Convert date to parameters
    Object.assign(payload, {
      datestart: typeof payload.datestart === 'object' ? getDateParameter(payload.datestart) : payload.datestart, 
      dateend: typeof payload.dateend === 'object' ? getDateParameter(payload.dateend) : payload.dateend, 
    })

    return fetch(state.api.items(normalizedParameter(payload)), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Update item */
  async updateItem (payload) {
    return fetch(state.api.item(payload.id), Object.assign({
      method: payload.id ? 'PUT' : 'POST',
      body: JSON.stringify(payload)
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get staff history */
  async getStaffHistory (id) {
    return fetch(state.api.history(id), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get my salons */
  async getMySalons (payload) {
    return fetch(state.api.salon('', payload), {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Fetch products
   * @param {string} payload.salon Salon ID
   * @param {number} payload.count Item count in a page
   * @param {number} payload.page Page number
   */
  async getProducts (payload) {
    return fetch(state.api.products(payload), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Fetch customers
   * @param {string} payload.id Array of customer ID (comma separated string)
   * @param {number} payload.count Item count in a page
   * @param {number} payload.page Page number
   */
  async getCustomers (payload) {
    return fetch(state.api.customers(payload), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.accessToken}`
      }
    })
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Fetch all comments
   * @param {string} id User or salon ID to fetch
   * @param {boolean} type Comment type to fetch (users:user's comment, salons: saon's comment)
   */
  async getComments (id, type='users') {
    return fetch(state.api.comments(id, type), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Add a comments
   * @param {string} id User or salon ID to send
   * @param {boolean} type Comment type to send (users:user's comment, salons: saon's comment)
   * @param {object} payload Comment object to send
   */
  async updateComment (id, type, payload) {
    return fetch(state.api.comments(id, type, payload.id), Object.assign({
      method: payload.id ? 'PUT' : 'POST',
      body: JSON.stringify(Object.assign(payload, {data: {}}))
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Remove a comments
   * @param {string} id User or salon ID to send
   * @param {boolean} type Comment type to send (users:user's comment, salons: saon's comment)
   * @param {object} payload Comment object to send
   */
  async removeComment (id, type, payload) {
    return fetch(state.api.comments(id, type, payload.id), Object.assign({
      method: 'DELETE',
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Fetch news */
  async getNews (payload) {
    return fetch(state.api.news(payload), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Fetch a news item */
  async getNewsItem (id) {
    return fetch(state.api.newsItem(id), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Update a news
   * @param {object} payload News object to send
   */
  async updateNews (payload) {
    // Create copy
    payload = _cloneDeep(payload)

    const id = payload.id

    // Delete unncessary properties
    delete payload.id
    delete payload.salon
    delete payload.publishedAt
    delete payload.updatedAt

    return fetch(state.api.newsItem(id), Object.assign({
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(Object.assign(payload, {data: {}}))
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Remove a news
   * @param {string} id News ID to remove
   */
  async removeNews (id) {
    return fetch(state.api.newsItem(id), Object.assign({
      method: 'DELETE',
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get Upload URL */
  async getUploadURL (payload, video=false) {
    return fetch(state.api.upload(payload, video), Object.assign({
      method: 'GET'
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /**
   * Get Download URL
   * If you requests same assets frequently, this method returns a cached request
   */
  async getDownloadURL (id, video=false) {
    return state.mediaCache[id] ?
      state.mediaCache[id] :
      state.mediaCache[id] =
        fetch(state.api.download(id, video), Object.assign({
          method: 'GET'
        }, state.options))
          .then(response => response.json())
          .then(json => {
            // Remove cache
            delete state.mediaCache[id]
            return analyzeResponse(json)
          })
  },

  /** Upload file */
  upload (url, file, progress=()=>{}) {
    const xhr = new XMLHttpRequest()

    xhr.open('PUT', url)
    xhr.upload.onprogress = progress
    xhr.send(file)
    return xhr
  },

  /** Sign out */
  async signOut () {
    return fetch(state.api.signout, Object.assign({
      method: 'POST'
    }, state.options))
      .finally(() => localStorage.removeItem(keyOfStorage))
  },

  /**
   * Send emails
   * @param {array} to Mail addresses to send
   * @param {string} title Mail title
   * @param {string} body HTML content
   */
  sendEmail (to, title, body) {
    return fetch(state.api.email, Object.assign({
      method: 'POST',
      body: JSON.stringify({
        to: to,
        title: title,
        body: body
      })
    }, state.options))
      .then(response => response.json())
      .then(json => analyzeResponse(json))
  },

  /** Get key name of localStorage  */
  getStorageKey () {
    return keyOfStorage
  },

  /** @returns {boolean} True if has accessToken */
  hasAccessToken () {
    return state.accessToken ? true : false
  }
}

export default exported