<template>
  <oct-dialog
    full
    :title="category ? category.name : ''"
    :icon="'arrow-left'"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-product-selector"
  >
    <oct-list
      title="商品を選択"
    >
      <oct-list-item
        v-for="item in items"
        :key="item.id"
        v-bind="item"
        dense
        @click.native="submit(item)"
      >
      </oct-list-item>
    </oct-list>
  </oct-dialog>
</template>

<script>
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSProductSelector',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctList,
    OctListItem,
    OctDialog
  },
  props: {
    /** Treatment ID */
    treatmentId: {
      type: String,
      default: ''
    }
  },
  computed: {
    /** Target treatment */
    item: vm => vm.$store.getters['treatments/byId'](vm.treatmentId),
    items () {
      return this.$store.getters['products/products'](this.$route.params.categoryId, this.$route.params.brandId)
        .map(item => this.productToListItem(item))
    },
    category () {
      return this.$store.getters['products/categoryById'](this.$route.params.categoryId)
    }
  },
  methods: {
    submit (item) {
      // Index to insert to others array
      const index = this.$route.query.index
      // Prop to update
      const prop = this.$route.query.prop
      // Other products
      const others = this.item.others

      // Case of recommended item
      if (prop === 'recommended') {
        console.warn(item)
        // Commit new item
        this.$store.dispatch('treatments/set', {
          items: [
            {
              id: this.item.id,
              data: {
                recommendedItem: {
                  item: item.id
                }
              }
            }
          ]
        })
      } else { // Other cases
        // Update others if necessary
        prop === 'others' &&
          (index != -1 ? others.splice(index, 1, item.id) : others.push(item.id))

        // Commit new item
        this.$store.dispatch('treatments/set', {
          items: [
            Object.assign(
              {
                id: this.item.id
              },
              prop === 'others' ? {
                others: others
              } : {
                [prop]: item.id
              }
            )
          ]
        })
      }

      this.$_octPrototypeView_goBack(prop === 'others' || prop === 'recommended' ? -3 : -2)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-product-selector {
  ::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(32);
  }
}
</style>