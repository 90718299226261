<template>
  <oct-dialog
    full
    :title="$route.meta.title"
    icon="arrow-left"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-customer"
  >
    <div>
      <!-- Customer card. Customer may not exist -->
      <oct-customer-card
        :icon="customer ? customer.profileIcon : undefined"
        :title="customer ? `${customer.name} 様` : ''"
        class="ss-customer-intro"
      />

      <div class="ss-customer__header">
        <span class="ss-customer__primary-text">
          過去1年間に{{ collection.items.length }}件の施術履歴があります
        </span>
      </div>

      <div class="ss-customer__content">
        <div class="ss-customer-history">
          <ul class="ss-customer-history-list">
            <li
              v-for="item in collection.items"
              :key="item.id"
              @click="$router.push({
                path: `../${item.id}`,
              }).catch(() => {})"
              class="ss-customer-history-list__item"
            >
              <div class="ss-customer-history__content">
                <!-- Main Content -->
                <div class="ss-customer-history__body">
                  <!-- Image -->
                  <oct-media
                    :image="item.images.length ? item.images[0] : {
                      lowResolution: '/images/customer__default.png',
                      standardResolution: '/images/customer__default.png',
                      highResolution: '/images/customer__default.png'
                    }"
                    :alt="customer.name"
                    class="ss-customer-history__media"
                  />

                  <!-- Text & Products -->
                  <div class="ss-customer-history__text">
                    <!-- Date -->
                    <span class="ss-customer-history__primary-text">
                      {{ $_octPrototypeView_getDateString(item.publishedAt) }}
                    </span>

                    <!-- Products -->
                    <ul class="ss-customer-history-products">
                      <li
                        class="ss-customer-history-products__item"
                        v-for="product in getProducts(item).slice(0, 4)"
                        :key="product.id"
                      >
                        <oct-media
                          :image="{
                            lowResolution: product.image,
                            standardResolution: product.image,
                            highResolution: product.image
                          }"
                          :alt="product.name"
                          class="ss-customer-history-products__media"
                        />
                      </li>

                      <!-- More Horizontal -->
                      <li
                        class="ss-customer-history-products__item ss-customer-history-products__item--more"
                        v-if="getProducts(item).length > 4"
                      >
                        <oct-icon icon="more-horiz"/>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Chip -->
                <div class="ss-customer-history__meta">
                  <oct-chip
                    dense
                    :selected="item.status != 4"
                  >
                    {{ item.status != 4 ? '未送信' : '送信済' }}
                  </oct-chip>

                  <!-- Trailing Icon -->
                  <oct-icon
                    icon="chevron-right"
                    class="ss-customer-history__trailing-icon"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Action -->
    <template #actions>
      <oct-button
        block
        size="large"
        @click.native="addNewTreatment"
      >
        新規施術追加
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctCustomerCard from '@/components/customer-card/OctCustomerCard.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSCustomerView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctChip,
    OctMedia,
    OctCustomerCard,
    OctDialog
  },
  props: {
    /** Customer ID */
    customerId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    /** Customer itself */
    customer: vm => vm.$store.getters['customers/byId'](vm.customerId),
    /** Customer’s recent treatments */
    collection: vm => vm.$store.getters['treatments/collection'](vm.customerId)
  },
  methods: {
    /**
     * Get treated products
     * @param {object} item - A treatment object
     */
    getProducts (item) {
      return [item.shampoo, item.treatment, item.outbath].concat(item.others)
        .map(p => (
          p ? this.$store.getters['products/byId'](p) : undefined 
        ))
        .filter(p => p)
    },
    /** Add new treatment */
    addNewTreatment () {
      // Add new treatment
      this.$store.commit('treatments/add', {
        items: [{
          id: 'new',
          salon: this.$store.state.id,
          customer: this.customerId
        }]
      })

      // Go to stylist selector
      this.$router.push({
        name: 'Stylists.todays'
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-customer {
  transform: translateZ(0);

  &::v-deep .oct-dialog-header {
    margin-bottom: oct-rem(16);
  }
}

.ss-customer-intro {
  margin-bottom: oct-rem(32);

  &::v-deep .oct-customer-card__media {
    margin-bottom: oct-rem(32);
  }
}

.ss-customer__header {
  text-align: center;
  margin: 0 0 oct-rem(32);
}

.ss-customer__primary-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: block;
}

.ss-customer-history-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ss-customer-history-list__item {
  margin: 0 0 oct-rem(16);
  cursor: pointer;
}

.ss-customer-history__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ss-customer-history__body {
  display: flex;
  align-items: center;
  gap: oct-rem(16);
}

.ss-customer-history__media {
  flex: 0 0 oct-rem(64);
  width: oct-rem(64);
  max-width: oct-rem(64);
  padding-top: oct-rem(64)
}

.ss-customer-history__primary-text {
  font-size: oct-rem(12);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: block;
  margin: 0 0 oct-rem(12);
}

.ss-customer-history-products {
  list-style: none;
  margin: 0;
  display: flex;
  gap: oct-rem(4);
  min-height: oct-rem(32);
  padding: 0;
}

.ss-customer-history-products__item {
  flex: 0 0 oct-rem(32);
  width: oct-rem(32);
  max-width: oct-rem(32);
  border: 1px solid $oct-theme--neutral-20;

  &--more {
    display: flex;
    align-items: center;
    border-width: 0;

    .oct-icon {
      width: oct-rem(12.5);
    }
  }
}

.ss-customer-history__meta {
  display: flex;
  align-items: center;
  gap: oct-rem(4);

  .oct-chip {
    flex: 0 0 auto;
  }
}

.ss-customer-history__trailing-icon  {
  fill: $oct-theme--neutral-50;
  flex: 0 0 oct-rem(32);
  width: oct-rem(32);
  max-width: oct-rem(32);
}
</style>