<template>
  <div class="ss-news-list">
    <!-- List -->
    <ul class="ss-news-list__list" v-if="items.length">
      <!-- List item -->
      <li
        class="ss-news-list__list-item"
        v-for="item in items"
        :key="item.id"
        @click="$router.push({name:'News.edit', params:{newsId:item.id}}).catch(() => {})"
      >
        <!-- The item -->
        <div class="ss-news-list-card">
          <!-- Content -->
          <div class="ss-news-list-card__content">
            <!-- Texts -->
            <div class="ss-news-list-card__text">
              <!-- Release date and time -->
              <div class="ss-news-list-card__meta">
                <!-- Date and time -->
                <span class="ss-news-list-card__meta-text">
                  {{ item.releaseAt.getFullYear() }}/{{ (item.releaseAt.getMonth()+1).toString().padStart(2, 0) }}/{{ item.releaseAt.getDate().toString().padStart(2, 0) }} {{ item.releaseAt.getHours().toString().padStart(2, 0) }}:{{ item.releaseAt.getMinutes().toString().padStart(2, 0) }}
                </span>
                <!-- Status indicator -->
                <oct-chip
                  :text="item.status === 2 ? '下書き' : '非公開'"
                  :selected="item.status === 2"
                  dense
                  v-if="item.status !== 4"
                />
              </div>
              <!-- Title -->
              <p class="ss-news-list-card__primary-text">
                {{ item.title }}
              </p>
              <!-- Author -->
              <span class="ss-news-list-card__secondary-text">
                {{ item.author === 1 ? 'milbon' : 'STYLE STOCK'}}
              </span>
            </div>
            <!-- Visual -->
            <div
              class="ss-news-list-card__visual"
              v-if="item.images.length"
            >
              <oct-media
                :image="item.images[0]"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Actions -->
    <div class="ss-news-list__action">
      <oct-button
        block
        size="large"
        @click.native="$router.push({name: 'News.edit', params: {newsId:'new'}}).catch(() => {})"
      >
        ニュース作成
      </oct-button>
    </div>

    <!-- Dialog -->
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctChip from '@/components/chips/OctChip.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSNewsListView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctButton,
    OctMedia,
    OctChip
  },
  computed: {
    ...mapGetters({
      items: 'news/all',
    }),
    ...mapState({
      me: state => state.staffs.me,
    }),
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .then(() => {
          this.$router.replace({name:'Welcome'}).catch(() => {})
        })
        .catch(thrown => this.$store.commit('error', thrown))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-news-list {
  padding: 0 0 oct-rem(84);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding-bottom: 0;
}
}

.ss-news-list__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid $oct-theme--neutral-20;
}

.ss-news-list__list-item {
  padding: oct-rem(15) oct-rem(16) oct-rem(16);
  border-top: 1px solid $oct-theme--neutral-20;
  cursor: pointer;

  &:last-child {
    padding-bottom: oct-rem(15);
  }
}

.ss-news-list-card__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: oct-rem(12);
}

.ss-news-list-card__text {
  display: flex;
  flex-direction: column;
  gap: oct-rem(8);
}

.ss-news-list-card__meta {
  display: flex;
  align-items: center;
  gap: oct-rem(8);
  min-height: oct-rem(26);
}

.ss-news-list-card__meta-text {
  font-size: oct-rem(14);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: block;
}

.ss-news-list-card__primary-text {
  font-size: oct-rem(14);
  line-height: 1.5;
  display: block;
}

.ss-news-list-card__secondary-text {
  font-size: oct-rem(12);
  line-height: 1;
  color: $oct-theme--neutral-50;
  display: block;
}

.ss-news-list-card__visual {
  flex: 0 0 auto;
  width: oct-rem(64);
  height: oct-rem(64);
  border: 1px solid $oct-theme--neutral-20;
}

.ss-news-list__action {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  column-gap: oct-rem(14);
  padding: oct-rem(16);
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  position: static;
  margin: 0 auto;
  width: oct-rem(392+64*2);
  padding: oct-rem(64);
}
}

/** Override transform settings */
.ss-news-list > .oct-dialog {
  /** Property and duration settings duaring transition */
  &.forward-enter-active, &.forward-leave-active, &.back-enter-active, &.back-leave-active {
    overflow: hidden;

    &::v-deep {
      & > .oct-dialog__container {
        height: 100%;
        overflow: hidden;
        transition: transform .5s;
      }
    }
  }

  &--center {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateY(100%);
        }
      }
    }
  }

  &--right {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(100%);
        }
      }
    }
  }

  &--left {
    /** Transition starting/ending point settings */
    &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
      &::v-deep {
        & > .oct-dialog__container {
          transform: translateX(-100%);
        }
      }
    }
  }

  /** Transition starting/ending point settings */
  &.forward-enter, &.back-leave-to, &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }

  &.forward-leave-to, &.back-enter {
    &::v-deep {
      & > .oct-dialog__container > .oct-dialog__surface {
        transform: none;
      }
    }
  }
}
</style>