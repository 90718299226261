import Vue from 'vue'
import API from '@/api/index.js'

/** Normalize item */
const normalizeItem = item => (Object.assign({
  profileIcon: undefined,
  favorites: [],
  updatedAt: new Date()
}, item))

const state = () => {
  return {
    /**
     * All items
     * @property {string} {}.id - Customer ID
     * @property {string} {}.name - Customer name
     * @property {object} {}.profileIcon - Customer’s profile icon (undefined as default)
     * @property {array} {}.favorites - Favorite stylists and assistants
     * @property {date} {}.updatedAt - Last updated date and time
     */
    all: {},
  }
}

// getters
const getters = {
  /** Returns customer data specified by id */
  byId: state => id => state.all[id]
}

// actions
const actions = {
  /**
   * Fetch customers specified by customer ID in items.
   * If you're adding customers to the collection that are already in the collection and updated within a hour, they’ll be ignored, unless you pass {force: true}.
   * If you pass {reset:true}, the collection will be reset.
   */
  async add ({state, commit, dispatch}, {items, options}) {
    [items, options] = [items || [], options || {}]
    // Now
    const now = new Date()

    // Target IDs
    const id = [...new Set(
        items.filter(item => (
          // Not in the collection
          !state.all[item.id] ||
          // Updated before 1 hour
          now.getTime() - state.all[item.id].updatedAt.getTime() > 3600*1000 ||
          // Force update
          options.force
        ))
        .map(item => item.id)
      )]
      .join(',')

    // No target
    if (!id) return Promise.resolve([])

    // Fetch customers
    const customers = (await API.getCustomers({
        id: id,
        count: items.length
      })).items

    // Fetch profile icons
    const icons = await dispatch('fetchProfileIcons', {items:customers})

    // Reset the collection if needed
    options.reset && commit('reset')

    // Add customers
    commit('add', {
      items: customers.map(item => Object.assign(item, {
        profileIcon: icons[item.id]
      }))
    })

    return Promise.resolve(customers)
  },
  /** Fetch customer’s favorite stylists and assistants, then update item */
  fetchFavorites ({commit}, {item}) {
    // Fetch favorite staffs
    API.getStaffHistory(item.id)
      .then(response => commit('favorites', {item: item, favorites: response}))
  },
  /**
   * Returns profile icon images of each customers specified by customer ID in items
   */
  async fetchProfileIcons (context, {items}) {
    // Today
    const today = new Date
    // Result
    const treatments = []
    // Payload
    const payload = {
      // Customer IDs
      customer: items.map(item => item.id).join(','),
      // Narrow down to a year
      datestart: `${today.getFullYear()-1}-${(today.getMonth()+1).toString().padStart(2,0)}-${today.getDate().toString().padStart(2,0)}`,
      // Status (published only)
      status: 4,
      // Items per page
      count: 100,
      // Page
      page: 1
    }

    // Fetch all treatments
    // Request with customer ID may be an error if the customer are not exist. So we ignore errors
    while (
      await API.getItems(payload).then(response => {
        treatments.push(...response.items)
        return response.items.length && treatments.length < response.total
      }).catch(() => {})
    ) payload.page++

    return items.reduce((acc, cur) => (
      Object.assign(acc, {
        // Add image
        [cur.id]: (treatments.find(item => item.customer === cur.id) || {images:[]}).images[0]
      })
    ), {})
  }
}

// mutations
const mutations = {
  /**
   * Add items to the collection.
   * If you're adding items to the collection that are already in the collection, they’ll be merged.
   */
  add (state, {items}) {
    items.forEach(item => Vue.set(state.all, item.id, normalizeItem(
      // Don't merge profile icon
      Object.assign({}, state.all[item.id], item)
    )))
  },
  /** Remove all items from the collection */
  reset (state) {
    Vue.set(state, 'all', {})
  },
  /** Set favorite stylists and assistants on the item */
  favorites (state, {item, favorites}) {
    // Invalid item
    if (!state.all[item.id]) return

    // Update favorites
    Vue.set(state.all[item.id], 'favorites',
      // Array of user ID. Frequent user first
      Object.values(favorites
        .reduce((acc, cur) => {
          // Frequency object
          if (acc[cur])
            acc[cur].count++
          else
            acc[cur] = {id: cur, count: 1}
          return acc
        }, {}))
        .sort((a, b) => b.count - a.count)
        .map(i => i.id)
    )
  },
  /** Remove items from the collection. Pass {exclude: [IDs]} to exclude removing  */
  clear (state, {exclude}) {
    Object.keys(state.all).forEach(item =>
      (!exclude || !exclude.includes(item)) &&
        Vue.delete(state.all, item)
    )
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}