<template>
  <div
    class="oct-form__row"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OctFormRow',
  props: {
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-form__row {
  margin: 0 0 oct-rem(16);
  display: flex;
  column-gap: oct-rem(14);
  row-gap: oct-rem(16);
  flex-wrap: wrap;

  .oct-form-group__row & {
    width: 100%;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  column-gap: oct-rem(32);
}
}
</style>
