var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.href === '' ? _vm.tagName : 'a',{tag:"compnent",staticClass:"oct-button",class:[
    {
      'oct-button--outlined': _vm.outline,
      'oct-button--block': _vm.block,
      'oct-button--square': !_vm.$scopedSlots.default && !_vm.text && _vm.icon,
    },
    `oct-button--${_vm.type}`,
    `oct-button--${_vm.size}`,
    `oct-button--align-${_vm.align}`
  ],attrs:{"href":_vm.href === '' ? false : _vm.href}},[(_vm.icon)?_c('oct-icon',{staticClass:"oct-button__leading-icon",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),_vm._t("default"),(_vm.$scopedSlots['trailing-icon'])?_c('div',{staticClass:"oct-button__trailing-icon"},[_vm._t("trailing-icon")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }