<template>
  <oct-account-dialog
    title="送信完了しました"
    text="パスワード再設定のご案内をメールいたしました。メールに記載されているURLよりパスワードの再設定登録をお願いいたします。"
    caption="メールが届かない場合、管理者にお問い合わせください。"
    class="ss-password-reminder"
  >
    <oct-form>
      <template #primaryActions>
        <oct-button
          size="large"
          block
          @click.native="$router.push({name:'Welcome'})"
        >
          トップページへ
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'

export default {
  name: 'SSPasswordReminderViewSent',
  components: {
    OctButton,
    OctForm,
    OctAccountDialog
  },
}
</script>

<style scoped lang="scss">
</style>