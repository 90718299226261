<template>
  <oct-account-dialog
    title="パスワードを変更しました"
    class="ss-password-reset"
  >
    <oct-form>
      <template #primaryActions>
        <oct-button
          size="large"
          block
          @click.native="$router.push({name:'Login'})"
        >
          ログインページへ
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import OctButton from '@/components/button/OctButton.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'

export default {
  name: 'SSPasswordResetViewDone',
  components: {
    OctButton,
    OctForm,
    OctAccountDialog
  },
}
</script>

<style scoped lang="scss">
</style>