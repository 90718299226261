import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'OctPrototypeForm',
  props: {
    payload: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      /** Indicate process is running */
      processing: false,
      $payload: _cloneDeep(this.payload),
      invalid: true,
    }
  },
  computed: {
  },
  methods: {
    /**
     * Submit form data
     * @event submit
     */
    async submit () {
      // Do nothing while processing
      if (this.processing) return

      // Raise processing
      this.processing = true

      await this.$_octPrototypeForm_validate(true)

      if (!this.invalid) {
        await this.beforeSubmit(this.$data.$payload, ...arguments)
        // Emit submit event with payload
        this.$emit('submit', this.$data.$payload)
      }

      this.processing = false
    },
    /** Do something before submit event is emit */
    beforeSubmit () {},
    /** Validate all refs */
    async $_octPrototypeForm_validate (force=false) {
      // Validate all refs
      if (force)
        for (const $ref of Object.values(this.$refs))
          $ref && typeof $ref.validate === 'function' && await($ref.validate())

      // Validate each ref if has validate function
      this.invalid =
        Object.values(this.$refs).find(item => item && item.invalid) ? true : false
    }
  },
  watch: {
    /**
     * Watch payload and validate on change
     * @event update:payload
     */
    '$data.$payload': {
      handler () {
        this.$_octPrototypeForm_validate()
        this.$emit('update:payload', this.$data.$payload)
      },
      deep: true
    },
    /** Reset $payload when the original payload is changed */
    payload: {
      handler (val) {
        this.$set(this.$data, '$payload', _cloneDeep(val))
      }
    }
  }
}