import API from '@/api/index.js'

const slugCategory = [
  '',
  'shampoo',
  'treatment',
  'outbath'
]

const state = () => {
  return {
    /** All items */
    all: [],
    /** Last updated time */
    updatedAt: new Date()
  }
}

// getters
const getters = {
  /**
   * Get products
   * @param {string} category Category ID to find
   * @param {string} brand Brand ID to find
   */
  products: (state, getters) => (category, brand) => {
    const b = getters['brandById'](brand)
    return state.all.filter(item => (
      (!category || item.categories.find(c => c.id == category)) &&
      (!brand || !b || item.brand_master.url === b.url)
    ))
  },
  /** Get product by ID */
  byId: state => id => (
    state.all.find(item => item.id == id)
  ),
  /** Get category by ID */
  categoryById: state => id => (
    state.categories.find(item => item.id == id)
  ),
  /** Get brand by ID */
  brandById: state => id => (
    state.brands.find(item => item.id == id)
  )
}

// actions
const actions = {
  /**
   * Fetch all products
   * @param {string} salon Salon ID to fetch
   */
  async fetch ({commit}, salon) {
    // Saved data
    const storage = JSON.parse(localStorage.getItem(API.getStorageKey()) || '{}')

    // Has product in localStorage
    if (storage.products && new Date().getTime() < storage.products.expires) {
      commit('state', storage.products)
      return Promise.resolve(storage.products.all)
    }

    const items = []
    const hasNext = response => !response.items.length ? false : items.length < response.counts
    const payload = {salon: salon, page: 1, count: 100}

    while (
      hasNext(await API.getProducts(payload)
        .then(response => {
          items.push(...response.items)
          return response
        })
      )
    )
      payload.page++

    commit('state', {all:items})

    // Save products in localStorage
    localStorage.setItem(
      API.getStorageKey(),
      JSON.stringify(Object.assign(storage, {
        products: {
          expires: new Date().getTime() + 6*3600*1000,
          all: items
        }
      }))
    )

    return Promise.resolve(items)
  }
}

// mutations
const mutations = {
  /** Update state */
  state (state, val) {
    // Collect brands and catetories
    val.all &&
      Object.assign(val, {
        categories: Object.values(
            val.all.reduce((acc, cur) => {
              acc.push(...cur.categories)
              return acc
            }, []).reduce((acc, cur) => {
              acc[cur.id] = cur
              return acc
            },{})
          )
            .filter(item => item.id && item.id != 9)
            .sort((a, b) => a.id - b.id)
            .map(item => Object.assign(item, {slug: slugCategory[item.id] || undefined})),
        brands: Object.values(
            val.all.map(item => item.brand_master)
              .reduce((acc, cur) => {
                acc[cur.url] = cur
                return acc
              }, {})
          ).sort((a, b) => a.priority - b.priority)
            .map((item, i) => Object.assign(item, {id: i+1}))
      })

    Object.assign(state, val)
  }
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default create()