var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-profile-editor",attrs:{"full":"","title":_vm.title,"align":_vm.align,"icon":"arrow-left"},on:{"dismiss":_vm.$_octPrototypeView_goBack},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('oct-button',{attrs:{"block":"","size":"large","disabled":_vm.$data.$_ssPE_invalid},nativeOn:{"click":function($event){return _vm.$refs.$_ssPE_form.submit()}}},[_vm._v(" 保存 ")])]},proxy:true}])},[_c('oct-user-form',{ref:"$_ssPE_form",attrs:{"payload":_vm.item,"editableProperties":{
      profileIcon: _vm.userId !== 'new',
      firstName: true,
      lastName: true,
      role: _vm.$store.state.staffs.me.isAdministrator,
      email: _vm.$store.state.staffs.me.isAdministrator,
      admin: _vm.$store.state.staffs.me.isAdministrator
    },"disabledProperties":{
      admin: !_vm.userId || _vm.userId === _vm.my.id
    }},on:{"update:payload":_vm.onUpdate,"submit":_vm.submit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }