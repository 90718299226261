<template>
  <oct-dialog
    full
    :title="title"
    @dismiss="$_octPrototypeView_goBack"
    class="ss-news-editor"
    @action="removeItem"
    :action="$data.$payload.id ? {
      text: '削除',
      type: 'danger'
    } : undefined"
  >
    <!-- Form -->
    <oct-form>
      <!-- Title -->
      <oct-form-group
        label="タイトル"
      >
        <oct-text-field
          required
          block
          v-model="$data.$payload.title"
          ref="$_ssNE_title"
        >
          <template #helperTextInvalid>
            タイトルを入力してください
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Author -->
      <oct-form-group
        label="投稿者"
      >
        <oct-select
          block
          outline
          v-model="$data.$payload.author"
          :items="[
            {
              value: 1,
              text: 'milbon'
            },
            {
              value: 2,
              text: 'STYLE STOCK'
            },
          ]"
          ref="$_ssNE_author"
        />
      </oct-form-group>

      <!-- Image -->
      <oct-form-group
        label="キービジュアル"
      >
        <oct-media
          :image.sync="image"
          :actions="image ? ['remove'] : ['add']"
          @remove="removeImage"
          @update:image="$_octPrototypeForm_validate"
          editable
          class="ss-news-editor__media"
        >
          <template #note>キービジュアルを選択</template>
        </oct-media>
      </oct-form-group>

      <!-- Text -->
      <oct-form-group
        label="本文"
      >
        <oct-text-field
          :rows="20"
          required
          block
          v-model="$data.$payload.text"
          ref="$_ssNE_text"
        >
          <template #helperTextInvalid>
            本文を入力してください
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Release date and time -->
      <oct-form-row>
        <!-- Release date -->
        <oct-form-col :cols="2">
          <oct-form-group
            label="公開日"
          >
            <oct-text-field
              required
              type="date"
              v-model="releaseDate"
              :placeholder="dateToString(new Date())"
              @change="$_octPrototypeForm_validate"
              ref="$_ssNE_releaseDate"
            >
              <template #helperTextInvalid>
                公開日を入力してください
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>

        <!-- Release time -->
        <oct-form-col :cols="2">
          <oct-form-group
            label="公開時間"
          >
            <!-- Release time from 00:00 to 23:00 -->
            <oct-select
              block
              outline
              v-model="releaseTime"
              :items="Array(23).fill(0).map((val, i) => (
                {
                  value: i,
                  text: `${(i).toString().padStart(2, 0)}:00`
                }
              ))"
              @input="$_octPrototypeForm_validate"
              ref="$_ssNE_releaseTime"
            />
          </oct-form-group>
        </oct-form-col>
      </oct-form-row>

      <!--
        Publish status
        Not displayed if the news is draft
      -->
      <oct-form-group
        label="公開状態"
        v-if="$data.$payload.status !== 2"
      >
        <oct-checkbox
          label="非公開"
          v-model="isPrivate"
        />
      </oct-form-group>
    </oct-form>

    <!-- Actions -->
    <template #actions>
      <oct-button
        size="large"
        outline
        block
        :disabled="invalid || !$data.$payload.title || !$data.$payload.text"
        @click.native="submit(false)"
        class="ss-news-editor__action"
        v-if="$data.$payload.status === 2"
      >
        下書き保存
      </oct-button>

      <oct-button
        size="large"
        block
        :disabled="invalid || !$data.$payload.title || !$data.$payload.text"
        @click.native="submit(true)"
        class="ss-news-editor__action"
      >
        {{ $data.$payload.status === 2 ? '公開' : '更新' }}
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import OctMedia from '@/components/media/OctMedia.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSNewsEditor',
  mixins: [
    OctPrototypeView,
    OctPrototypeForm
  ],
  components: {
    OctMedia,
    OctButton,
    OctCheckbox,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormGroup,
    OctFormRow,
    OctFormCol,
    OctDialog
  },
  props: {
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Comment payload */
    payload: {
      type: Object,
      default: () => ({
        /** News ID */ 
        id: '',
        /** News title */  
        title: '',
        /** News text */  
        text: '',
        /** News images */  
        images: [],
        /** News author (1:milbon, 2:Style Stock) */  
        author: 1,
        /** Release date and time */ 
        releaseAt: new Date(new Date().setMinutes(0)),
        /** News status (2 by default) */ 
        status: 2
      })
    }
  },
  computed: {
    /** News image */
    image: {
      get () {
        return this.$data.$payload.images.length ? this.$data.$payload.images[0] : undefined
      },
      set (val) {
        this.$set(this.$data.$payload.images, 0, val)
      }
    },
    /** Release date */
    releaseDate: {
      get () {
        return this.dateToString(this.$data.$payload.releaseAt)
      },
      set (val) {
        const values = val.split('-').map(item => Number(item))
        this.$data.$payload.releaseAt.setFullYear(values[0])
        this.$data.$payload.releaseAt.setMonth(values[1] - 1, values[2])
      }
    },
    /** Release time */
    releaseTime: {
      get () {
        return this.$data.$payload.releaseAt.getHours()
      },
      set (val) {
        this.$data.$payload.releaseAt.setHours(val, 0)
      }
    },
    /** Indicate the news is private */
    isPrivate: {
      get () {
        return this.$data.$payload.status === 3
      },
      set (val) {
        this.$data.$payload.status = val ? 3 : 4
      }
    }
  },
  methods: {
    /**
     * Submit item
     * @param {boolean} publish Publish then news if true
     */
    async beforeSubmit (payload, publish=false) {
      // Clone payload
      payload = _cloneDeep(payload)

      // Update status if nessessary
      if (publish && payload.status === 2) payload.status = 4

      try {
        // Update and save target comment of the module in the store
        await this.$store.dispatch('news/save', {payload: payload})

        // Go back
        this.$_octPrototypeView_goBack()

        // Notify
        this.$store.dispatch('notify', {
          text: `ニュースを${payload.id ?  '保存' : '作成' }しました`
        })
      } catch (thrown) {
        this.$store.commit('error', {message: `ニュースを${payload.id ?  '保存' : '作成' }できませんでした。もう一度やり直してください`})
      }
    },
    /** Remove the news */
    async removeItem () {
      try {
        // Confirm 
        await this.$store.dispatch('confirm', 'このニュースを本当に削除しますか？\n削除すると元には戻せません。')

        // Do remove
        await this.$store.dispatch('news/remove', {item:this.$data.$payload})
          .then(() => this.$store.dispatch('notify', {
            text: `ニュースを削除しました`,
            type: 'danger'
          }))
      } catch (thrown) {
        this.$store.commit('error', {message: `ニュースを削除できませんでした。もう一度やり直してください`, thrown: thrown})
      } finally {
        // Go back
        this.$_octPrototypeView_goBack()
      }
    },
    /** Remove all image */
    async removeImage () {
      // Confirm
      await this.$store.dispatch('confirm', {text: 'この写真を本当に削除しますか？\n削除すると元には戻せません。'})
      // Remove
      this.$data.$payload.images = []
    },
    /** Date to string */
    dateToString: val => `${val.getFullYear()}-${(val.getMonth() + 1).toString().padStart(2, '0')}-${val.getDate().toString().padStart(2, '0')}`
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-news-editor__media {
  padding-top: (200%/358)*100;
  background-color: $oct-theme--neutral-10;
}
</style>