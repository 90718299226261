<template>
  <div
    class="oct-dialog"
    :class="[{
      'oct-dialog--full': full
    }, `oct-dialog--${align}`]"
    @scroll="$emit('scroll', $event)"
  >
    <div
      class="oct-dialog__container"
      @scroll="$emit('scroll', $event)"
    >
      <div class="oct-dialog__surface"
        role="alertdialog"
        aria-modal="true"
      >
        <div
          class="oct-dialog-header"
          :class="{
            'oct-dialog-header--with-action': action.text
          }"
        >
          <div
            class="oct-dialog-header__icon"
            @click="$emit('dismiss')"
          >
            <oct-icon :icon="icon"/>
          </div>

          <h2 class="oct-dialog-header__primary-text">
            {{ title }}
          </h2>

          <div
            class="oct-dialog-header__action"
            v-if="action.text"
            @click="$emit('action')"
          >
            <span class="oct-dialog-header__action-text">{{ action.text }}</span>
          </div>
        </div>

        <div class="oct-dialog__content">
          <slot />
        </div>

        <div
          class="oct-dialog__actions"
          v-if="$scopedSlots.actions"
        >
          <slot name="actions"/>
        </div>
      </div>
    </div>
    <div class="oct-dialog__scrim" @click="$emit('dismiss')"></div>
  </div>
</template>

<script>
import OctDialog from '@/components/dialog/index.js'

export default {
  name: 'OctDialog',
  mixins: [
    OctDialog
  ],
}
</script>

<style scoped lang="scss">
@import "index.scss";
</style>