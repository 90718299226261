<template>
  <div
    class="oct-select"
    :class="[{
      'oct-select--block': block,
      'oct-select--outlined': outline,
      'oct-select--disabled': disabled,
      'oct-select--dense': dense
    }]"
  >
    <div class="oct-select__anchor">
      <select
        class="oct-select__native-input"
        v-model="$_octSelect_value"
      >
        <option
          v-for="(item, i) in items"
          :key="i"
          :value="item.value"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </option>
      </select>
      <span class="oct-select__dropdown-icon">
        <oct-icon icon="expand-more" />
      </span>
    </div>

    <div
      class="oct-select-helper-line"
      v-if="helperText !== '' || (invalid && helperTextInvalid !== '')"
    >
      <span class="oct-select-helper-text" aria-hidden="true">{{ invalid ? helperTextInvalid : helperText }}</span>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctSelect',
  components: { OctIcon },
  props: {
    /** Selected Value */
    value: {
      type: [String, Number],
      default: ''
    },
    /**
     * Options
     * @property {string} items[].text Display text
     * @property {string} items[].value The value
     * @property {boolean} items[].disabled The option is disabled if true
     */
    items: {
      type: Array,
      default () {
        return []
      }
    },
    /** Helper text */
    helperText: {
      type: String,
      default: ''
    },
    /** Invalid helper text */
    helperTextInvalid: {
      type: String,
      default: ''
    },
    /** Display block mode */
    block: {
      type: Boolean,
      default: false
    },
    /** Indicate outline style */
    outline: {
      type: Boolean,
      default: false
    },
    /** Dense */
    dense: {
      type: Boolean,
      default: false
    },
    /** External validation function */
    validator: {
      type: Function,
      default: null
    },
    /** Whether required */
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      invalid: false
    }
  },
  computed: {
    /**
     * Whether current selected option is disabled
     */
    disabled () {
      // selected value
      const selected = this.items.filter(item => item.value === this.$_octSelect_value)

      if (selected.length)
        return selected[0].disabled || false

      // nothing is selected
      return false
    },
    $_octSelect_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /** Input validation */
    async validate (val=this.$_octSelect_value) {
      // Empty check
      let invalid = this.required && !val

      // Number check
      invalid = (this.type === 'number' && !this.validateNumber(val)) || invalid

      // Email check
      invalid = (this.type === 'email' && !this.validateEmail(val)) || invalid

      // Finally do the external validation
      if (!invalid && this.validator)
        invalid = !await this.validator(val)

      this.invalid = invalid

      return !invalid
    },
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-select {
  display: inline-block;

  &--block {
    display: block;
    width: 100%;

    .oct-select__native-input {
      width: 100%;
    }
  }
}

.oct-select__anchor {
  position: relative;
}

.oct-select__native-input {
  font-family: $font-family-sans-serif-ja;
  font-size: oct-rem(16);
  font-weight: 400;
  color: $oct-theme--neutral;
  line-height: normal;
  margin: 0;
  height: oct-rem(48);
  padding: 0 oct-rem(32+16-1) 0 oct-rem(16-1);
  background-color: $oct-theme--neutral-10;
  border: 1px solid $oct-theme--neutral-10;
  outline: none;
  box-sizing: border-box;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  .oct-select--outlined & {
    border-color: $oct-theme--neutral-20;
    background-color: transparent;
  }

  .oct-select--dense & {
    font-size: oct-rem(14);
    padding: oct-rem(11-1) oct-rem(27+11-1) oct-rem(11-1) oct-rem(11-1);
  }

  .oct-select[disabled] &,
  .oct-select--disabled & {
    color: rgba(0,0,0,.55);
  }

  .oct-select[disabled] & {
    pointer-events: none;
    background-color: $oct-theme--neutral-10;
  }

  option:disabled {
    opacity: .55;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .oct-select--dense & {
    font-size: oct-rem(16);
    padding: oct-rem(16-1) oct-rem(27+16-1) oct-rem(16-1) oct-rem(16-1);
  }
}
}

.oct-select__dropdown-icon {
  position: absolute;
  top: 50%;
  right: oct-rem(16-4);
  width: oct-rem(20);
  height: oct-rem(20);
  transform: translateY(-50%);
  pointer-events: none;

  .oct-icon {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  right: oct-rem(16-4);
  width: oct-rem(24);
  height: oct-rem(24);
}
}

.oct-select-helper-line {
  color: $oct-theme--neutral-50;
  margin: 0 0 oct-rem(8);

  &:last-child {
    margin-bottom: 0;
  }

  [aria-invalid="true"] ~ &--invalid {
    color: $oct-theme--danger;
    opacity: 1;
  }
}

.oct-select-helper-text {
  font-size: oct-rem(12);
  line-height: (18/12);
  margin: 0;
  display: block;
}
</style>
