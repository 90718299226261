<template>
  <div class="oct-radio">
    <label
      class="oct-radio__label"
    >
      <input
        type="radio"
        class="oct-radio__native-control"
        :id="id"
        :name="id"
        :value="value"
        :disabled="disabled"
        v-model="$_octRadio_value"
      />
      <div class="oct-radio__background">
        <oct-icon  class="oct-checkbox__checkmark" icon="done" />
      </div>
      <span class="oct-radio__label-text" v-if="label !== ''">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctRadio',
  components: {
    OctIcon
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    /** The id */
    id: {
      type: String,
      default: ''
    },
    /** Whether checked */
    checked: {
      type: [Number,String,Boolean],
      default: ''
    },
    /** Checked Value */
    value: {
      type: [Number,String,Boolean],
      default: ''
    },
    /** Native disabled attribute */
    disabled: {
      type: [String,Boolean],
      default: false
    },
    /** Label text */
    label: {
      type: String,
      default: ''
    },
  },
  computed: {
    /** Delegate value */
    $_octRadio_value: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-radio {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.oct-radio__label {
  display: flex;
  align-items: center;
}

.oct-radio__native-control {
  display: none;
}

.oct-radio__background {
  margin: 0 oct-rem(8) 0 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 oct-rem(32);
  width: oct-rem(32);
  height: oct-rem(32);
  border: oct-rem(1) solid $oct-theme--neutral-20;
  border-radius: 50%;

  &:last-child {
    margin-right: 0;
  }

  .oct-icon {
    fill: $oct-theme--surface;
    display: none;
    width: 100%;
    height: 100%;
  }

  .oct-radio__native-control:checked + & {
    background-color: $oct-theme--primary;

    .oct-icon {
      display: block;
    }
  }
}

.oct-radio__inner-circle {
  width: oct-rem(10);
  height: oct-rem(10);
  border-radius: 50%;

  .oct-radio__native-control:checked + .oct-radio__background & {
    background-color: $oct-theme--surface;
  }
}

.oct-radio__label-text {
  font-size: oct-rem(18);
  line-height: (24/18);
  margin: (-5em/18) 0;
  display: block;
}
</style>
