<template>
  <div
    class="oct-dialog"
    :class="{
      'oct-dialog--full': full
    }"
  >
    <div class="oct-dialog__container">
      <div class="oct-dialog__surface"
        role="alertdialog"
        aria-modal="true"
      >
        <div class="oct-dialog-header">
          <oct-icon
            icon="stylestock"
            :width="234"
            :height="52"
          />
        </div>

        <div class="oct-dialog-subheader">
          <div
            class="oct-dialog-subheader__title"
            :class="{
              'oct-dialog-subheader__title--with-icon': icon
            }"
          >
            <div
              class="oct-dialog-subheader__icon"
              v-if="icon"
              @click="$emit('dismiss')"
            >
              <oct-icon :icon="icon"/>
            </div>

            <h3 class="oct-dialog-subheader__primary-text">
              {{ title }}
            </h3>
          </div>

          <div
            class="oct-dialog-subheader__text"
            v-if="text || caption"
          >
            <p
              class="oct-dialog-subheader__secondary-text"
              v-if="text"
            >
              {{ text }}
            </p>

            <p
              class="oct-dialog-subheader__caption-text"
              v-if="caption"
            >
              {{ caption }}
            </p>
          </div>
        </div>

        <div class="oct-dialog__content">
          <slot />
        </div>

        <div
          class="oct-dialog__actions"
          v-if="$scopedSlots.actions"
        >
          <slot name="actions"/>
        </div>
      </div>
    </div>
    <div class="oct-dialog__scrim" @click="$emit('cancel')"></div>
  </div>
</template>

<script>
import OctDialog from '@/components/dialog/index.js'

export default {
  name: 'OctAccountDialog',
  mixins: [
    OctDialog
  ],
  props: {
    full: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "../dialog/index.scss";
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-dialog-header {
  margin-bottom: oct-rem(32);
  justify-content: center;
  height: oct-rem(84);
  padding: 0;

  .oct-icon__stylestock {
    width: oct-rem(234);
    height: oct-rem(52);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(120-56);
  height: oct-rem(56*2+65);

  .oct-icon__stylestock {
    width: oct-rem(292.55);
    height: oct-rem(65);
  }
}
}

.oct-dialog-subheader {
  margin: 0 auto oct-rem(32);
  width: 100%;
  padding: 0 oct-rem(16);
  -webkit-font-smoothing: antialiased;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(56);
  width: oct-rem(640+64*2);
  padding: 0 oct-rem(64);
}
}

.oct-dialog-subheader__icon {
  fill: $oct-theme--neutral;
  flex: 0 0 oct-rem(32);
  width: oct-rem(32);
  height: oct-rem(32);
  cursor: pointer;
}

.oct-dialog-subheader__title {
  margin: 0 0 oct-rem(32);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &--with-icon {
    padding-right: oct-rem(32);
  }
}

.oct-dialog-subheader__primary-text {
  font-size: oct-rem(24);
  font-weight: normal;
  line-height: 1;
  text-align: center;
  margin: 0;
  display: block;
  flex: 1 1 auto;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(32);
}
}

.oct-dialog-subheader__secondary-text {
  font-size: oct-rem(16);
  line-height: 2;
  margin: 0 0 oct-rem(14);
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
}

.oct-dialog-subheader__caption-text {
  font-size: oct-rem(12);
  line-height: (18/12);
  margin: 0;
  display: block;
}

.oct-dialog__container {
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .oct-dialog--full & {
    width: 100%;
  }
}
}

.oct-dialog__content {
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  width: oct-rem(640+64*2)
}
}
</style>