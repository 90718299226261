<template>
  <div class="ss-welcome">
    <div class="ss-welcome__content">
      <div class="ss-welcome__visual">
        <oct-media
          :image="image"
          class="ss-welcome__media"
        />
      </div>

      <oct-icon
        icon="stylestock--filled"
        class="ss-welcome__logo"
        :width="234"
        :height="52"
      />

      <div class="ss-welcome__action">
        <span class="ss-welcome__primary-text">
          Style Stock の利用を開始する。
        </span>

        <oct-button
          block
          size="large"
          @click.native="$router.replace({name:'Login'}).catch(() => {})"
        >
          ログイン
        </oct-button>
      </div>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctMedia from '@/components/media/OctMedia.vue'

export default {
  name: 'SSWelcomeView',
  components: {
    OctIcon,
    OctButton,
    OctMedia
  },
  props: {
    image: {
      type: Object,
      default: () => ({
        lowResolution: '/images/style-stock__hero--small.jpg',
        standardResolution: '/images/style-stock__hero.jpg'
      })
    },
    video: {
      type: Object,
      default: () => ({
        url: '/media/style-stock__hero.mp4',
        autoplay: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-welcome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ss-welcome__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.ss-welcome__visual {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  pointer-events: none;
}

.ss-welcome__media {
  height: 100%;
  padding-top: 0;

  ::v-deep .oct-media__image {
    object-position: center center;
  }

  ::v-deep .oct-media__video {
    display: block;
  }
}

.ss-welcome__logo {
  fill: $oct-theme--on-primary;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: oct-rem(234);
  height: oct-rem(52);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  width: oct-rem(292.55);
  height: oct-rem(65);
}
}

.ss-welcome__action {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: oct-rem(20);
  width: 100%;
  padding: oct-rem(24) oct-rem(16) oct-rem(32);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  display: grid;
  gap: oct-rem(56);
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: oct-rem(42) oct-rem(56);
}
}

.ss-welcome__primary-text {
  font-size: oct-rem(20);
  line-height: 1;
  color: $oct-theme--neutral-variant;
  display: block;
}
</style>