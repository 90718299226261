<template>
  <oct-dialog
    full
    :title="$route.meta.title"
    :action="{
      text: '削除',
      type: 'danger'
    }"
    :icon="back.name ? 'close' : 'arrow-left'"
    @action="removeItem"
    @dismiss="back.name ?
      $router.push(back).catch(() => {}) :
      $_octPrototypeView_goBack()"
  >
    <!-- Content -->
    <div class="ss-treatment__content" v-if="item">
      <!-- Customer. Customer may not exist -->
      <oct-customer-card
        :icon="customer && customer.profileIcon || undefined"
        :title="customer ? `${customer.name} 様` : ''"
        :text="`${$_octPrototypeView_getDateString(item.publishedAt, true)}〜`"
        :meta="{
          text: item.status >= 4 ? '送信済' : '未送信',
          active: item.status < 4
        }"
        history
        @click:history="customer && $router.push({
          name: 'Customer',
          params: {
            customerId: customer.id
          }
        })"
        class="ss-treatment-intro"
      />

      <!-- Stylists -->
      <div class="ss-treatment__section ss-treatment__section--user">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.stylists.length
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            スタイリスト
          </h3>
        </div>

        <oct-list>
          <!-- The user may not exist -->
          <oct-list-item
            v-for="item in staffs(item.stylists)"
            :key="item.id"
            :media="{
              image: $_octPrototypeView_getProfileIconSafely(item.profileIcon)
            }"
            :title="`${item.lastName || ''} ${item.firstName || ''}`"
            text="スタイリスト"
            removable
            @remove="removeUser('stylists', item.id)"
            @click.native="$router.push({
              path: `${$route.path}/stylists`
            }).catch(() => {})"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <oct-list-item
            :media="{
              image: $_octPrototypeView_getProfileIconSafely()
            }"
            title="スタイリストを追加"
            @click.native="$router.push({
              path: `${$route.path}/stylists`
            }).catch(() => {})"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>
        </oct-list>
      </div>

      <!-- Assistants -->
      <div class="ss-treatment__section ss-treatment__section--user">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.assistants.length
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            アシスタント
          </h3>
        </div>

        <oct-list>
          <!-- The user may not exist -->
          <oct-list-item
            v-for="item in staffs(item.assistants)"
            :key="item.id"
            :media="{
              image: $_octPrototypeView_getProfileIconSafely(item.profileIcon)
            }"
            :title="`${item.lastName || ''} ${item.firstName || ''}`"
            text="アシスタント"
            removable
            @remove="removeUser('assistants', item.id)"
            @click.native="$router.push({
              path: `${$route.path}/assistants`
            }).catch(() => {})"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <oct-list-item
            :media="{
              image: $_octPrototypeView_getProfileIconSafely()
            }"
            title="アシスタントを追加"
            @click.native="$router.push({
              path: `${$route.path}/assistants`
            }).catch(() => {})"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>
        </oct-list>
      </div>

      <!-- Products -->
      <div class="ss-treatment__section">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.shampoo && !item.treatment && !item.outbath && !item.others.length
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            使用商品
          </h3>
        </div>

        <oct-list>
          <!-- Shampoo -->
          <oct-list-item
            v-bind="product(item.shampoo, 1)"
            :removable="!!productByID(item.shampoo)"
            @remove="removeProduct('shampoo')"
            @click.native="$router.push({
              path: `${$route.path}/products/categories/1`,
              query: {
                prop: 'shampoo'
              }
            })"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <!-- Treatment -->
          <oct-list-item
            v-bind="product(item.treatment, 2)"
            :removable="!!productByID(item.treatment)"
            @remove="removeProduct('treatment')"
            @click.native="$router.push({
              path: `${$route.path}/products/categories/2`,
              query: {
                prop: 'treatment'
              }
            })"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <!-- Out bath -->
          <oct-list-item
            v-bind="product(item.outbath, 3)"
            :removable="!!productByID(item.outbath)"
            @remove="removeProduct('outbath')"
            @click.native="$router.push({
              path: `${$route.path}/products/categories/3`,
              query: {
                prop: 'outbath'
              }
            })"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <!-- Others -->
          <oct-list-item
            v-for="(p, j) in others"
            :key="p"
            v-bind="product(p, 4)"
            removable
            @remove="removeProduct('others',p)"
            @click.native="$router.push({
              path: `${$route.path}/products/categories`,
              query: {
                prop: 'others',
                index: j
              }
            })"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>

          <!-- Others plus -->
          <oct-list-item
            title="その他"
            icon="plus-circle"
            @click.native="$router.push({
              path: `${$route.path}/products/categories`,
              query: {
                prop: 'others',
                index: -1
              }
            })"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>
        </oct-list>
      </div>

      <!-- Recommend Product -->
      <div class="ss-treatment__section">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.data.recommendedItem.item
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            おすすめ商品
          </h3>
        </div>

        <oct-list>
          <!-- Product -->
          <oct-list-item
            title="商品を選ぶ"
            icon="plus-circle"
            @click.native="$router.push({
              path: `${$route.path}/products/categories`,
              query: {
                prop: 'recommended',
              }
            })"
            v-if="!item.data.recommendedItem.item || !this.$store.getters['products/byId'](item.data.recommendedItem.item)"
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>


          <oct-list-item
            v-bind="this.product(item.data.recommendedItem.item)"
            removable
            @remove="removeProduct('recommended',p)"
            @click.native="$router.push({
              path: `${$route.path}/products/categories`,
              query: {
                prop: 'recommended',
              }
            })"
            v-else
          >
            <template #trailing-icon>
              <oct-icon icon="chevron-right"/>
            </template>
          </oct-list-item>
        </oct-list>
      </div>

      <!-- Message for Recommend Product -->
      <div
        class="ss-treatment__section"
        v-if="$_ssTreatmentView_recommendedMessage"
      >
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.data.recommendedItem.message
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            おすすめ商品メッセージ
          </h3>
        </div>

        <div class="ss-treatment__body">
          <oct-text-field
            :rows="7"
            v-model="$_ssTreatmentView_recommendedMessage"
            block
            disabled
          >
            <template #helperText>
              仕様変更に伴い、現在お客様には表示されておりません
            </template>
          </oct-text-field>
        </div>
      </div>

      <!-- Photos -->
      <div class="ss-treatment__section">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.images.length
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            施術写真
          </h3>
        </div>

        <div class="ss-treatment-slider">
          <ul class="ss-treatment-media">
            <li
              class="ss-treatment-media__item"
              v-for="(img, i) in item.images"
              :key="img.id"
            >
              <oct-media
                :image="img"
                :actions="['remove']"
                @remove="removeImage(i)"
                @upload:complete="$_ssTreatmentView_onUploadStatusChange($event, i)"
                editable
              />
            </li>

            <li
              class="ss-treatment-media__item"
              v-for="(i, j) in (item.images.length < 3 ? Array(3 - item.images.length).fill(0) : [])"
              :key="`p${j}`"
            >
              <oct-media
                :image="$_ssTreatmentView_image(item.images.length + j + 1)"
                :actions="['add']"
                @upload:complete="$_ssTreatmentView_onUploadStatusChange"
                editable
              />
            </li>

            <li
              class="ss-treatment-media__item"
              :key="new Date().getTime()"
            >
              <oct-media
                :actions="['new']"
                @upload:complete="$_ssTreatmentView_onUploadStatusChange"
                editable
              />
            </li>

            <li class="ss-treatment-media__item" role="separator"></li>
          </ul>
        </div>

        <!-- Note -->
        <div class="ss-treatment__note">
          <p class="ss-treatment__note-text">
            ※ブラウザ上で撮影するとカメラロールに写真が保存されません。
          </p>
        </div>
      </div>

      <!-- Video -->
      <div class="ss-treatment__section">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.videos.length
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            施術動画
          </h3>
        </div>

        <div class="ss-treatment-media ss-treatment-media--video">
          <oct-media
            type="video"
            :video.sync="$_ssTreatmentView_video"
            :actions="$_ssTreatmentView_video ? ['remove'] : ['add']"
            @remove="removeVideo"
            editable
          />
        </div>

        <!-- Note -->
        <div class="ss-treatment__note">
          <p class="ss-treatment__note-text">
            ※ブラウザ上で撮影するとカメラロールに写真が保存されません。
          </p>
        </div>
      </div>

      <!-- Message -->
      <div class="ss-treatment__section">
        <div
          class="ss-treatment-header"
          :class="{
            'ss-treatment-header--empty': !item.message
          }"
        >
          <h3 class="ss-treatment-header__primary-text">
            <span class="ss-treatment-header__icon">
              <oct-icon icon="done"/>
            </span>
            一言コメント
          </h3>
        </div>

        <div class="ss-treatment__body">
          <oct-text-field
            :rows="7"
            v-model="$_ssTreatmentView_message"
            block
          ></oct-text-field>
        </div>
      </div>
    </div>

    <!-- Email to send -->
    <ss-customer-mail
      :id="item.id"
      ref="$_ssTV_email"
      class="ss-treatment__customer-email"
      v-if="item"
    />

    <!-- Child routes -->
    <transition :name="transitionName">
      <router-view class="ss-dialog__content" :key="$route.name"/>
    </transition>

    <template
      #actions
      v-if="
        item &&
        (
          $store.state.staffs.me.isAdministrator ||
          $store.state.staffs.me.role === 1 ||
          item.status < 4 ||
          (new Date).getTime() - item.publishedAt.getTime() < 48*3600*1000
        )
      "
    >
      <oct-button
        block
        outline
        size="large"
        :disabled="item.status >= 3"
        @click.native="updateState(3)"
      >
        タブレット連携
      </oct-button>

      <oct-button
        size="large"
        @click.native="updateState(4)"
        class="ss-treatment__action"
      >
        {{ item.status >= 4 ? '更新' : '送信' }}
      </oct-button>
    </template>
  </oct-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctIcon from '@/components/icon/OctIcon.vue'
import OctButton from '@/components/button/OctButton.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctList from '@/components/list/OctList.vue'
import OctListItem from '@/components/list/OctListItem.vue'
import OctDialog from '@/components/dialog/OctDialog.vue'
import OctCustomerCard from '@/components/customer-card/OctCustomerCard.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import SsCustomerMail from '@/views/SSCustomerMail.vue'

export default {
  name: 'SSTreatmentView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctButton,
    OctMedia,
    OctTextField,
    OctList,
    OctListItem,
    OctDialog,
    OctCustomerCard,
    SsCustomerMail,
  },
  props: {
    /** Treatment ID */
    treatmentId: {
      type: String,
      default: ''
    },
    /** Indicate editable */
    editable: {
      type: Boolean,
      default: true
    },
    /** Path to go back */
    back: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    /** Treatment data */
    item: vm => vm.$store.getters['treatments/byId'](vm.treatmentId),
    /** Customer data */
    customer: vm => vm.item ? vm.$store.getters['customers/byId'](vm.item.customer) : undefined,
    /** @returns {array} Array of actual staff data */
    staffs: vm => items => items.reduce((acc, cur) => acc.concat(vm.$store.getters['staffs/byId'](cur.id) || []), []),
    /** Produc by ID */
    productByID: vm => id => vm.$store.getters['products/byId'](id),
    /** Other products */
    others: vm => vm.item.others.filter(item => vm.$store.getters['products/byId'](item)),
    /** Message for recommended item */
    $_ssTreatmentView_recommendedMessage: {
      get () {
        return this.item.data.recommendedItem.message
      },
      set (val) {
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            data: {
              recommendedItem: {
                message: val
              }
            }
          }]
        })
      }
    },
    /** Message from the stylist */
    $_ssTreatmentView_message: {
      get () {
        return this.item.message
      },
      set (val) {
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            message: val
          }]
        })
      }
    },
    /** Image to newly upload */
    $_ssTreatmentView_image: () => (i=1) => (
      {
        id: '',
        lowResolution: `/images/styling__alt--0${i}.png`,
        standardResolution: `/images/styling__alt--0${i}.png`,
        highResolution: `/images/styling__alt--0${i}.png`,
        status: 1
      }
    ),
    /** Treatment’s videos */
    $_ssTreatmentView_video: {
      get () {
        return this.item.videos[0]
      },
      set (val) {
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            videos:[val]
          }]
        })
      }
    }
  },
  methods: {
    /** Product by ID */
    product (id='', alt='') {
      return this.productToListItem(
        // The product may be discontinued
        id && this.$store.getters['products/byId'](id) ?
          this.$store.getters['products/byId'](id) :
          {
            categories: [{
              id: alt,
              name: this.$store.getters['products/categoryById'](alt).name
            }]
          }
      )
    },
    /** Remove this item */
    async removeItem () {
      try {
        // Confirm 
        await this.$store.dispatch('confirm', 'この施術情報を本当に削除しますか？削除すると元には戻せません。')

        // Do remove
        this.$store.dispatch('treatments/remove', {items:[this.item]})
          .then(() => {
            this.$_octPrototypeView_goBack()
            // Notify
            this.$store.dispatch('notify', {text: '施術情報を削除しました', type: 'danger'})
          })
          .catch(thrown => this.$store.commit('error', thrown))
      } catch {
        // Do nothing
      }
    },
    /** Remove a user from this item */
    removeUser (type, id) {
      this.$store.dispatch('treatments/set', {
        items: [{
          id: this.item.id,
          [type]: this.item[type].filter(i => i.id !== id),
        }]
      })
    },
    /** Remove a product from this item */
    removeProduct (type, id=null) {
      // Case of recommended item
      if (type === 'recommended') {
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            data: {
              recommendedItem: {
                item: ''
              }
            },
          }]
        })

        return
      }

      id === null ?
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            [type]: '',
          }]
        }) :
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            [type]: this.item[type].filter((i) => i !== id),
          }]
        })
    },
    /** Update state */
    async updateState (val) {
      try {
        // Item data field
        const data = this.item.data
        // Current status
        const oldStatus = this.item.status

        // Confirm 
        this.item.status !== 4 ?
          await this.$store.dispatch('confirm', `この施術情報を${val === 3 ? 'タブレット' : 'milbon:iD'}に連携しますか？この操作は元に戻せません。`) :
          await this.$store.dispatch('confirm', 'この施術情報を更新しますか？ただし、既に送付したメールの内容は変更できません。')

        // The first publishing
        // Add overdue state and release time
        if (val === 4 && this.item.status !== 4) {
          // Update overdue property (Sent within 72hours)
          data.overdue = new Date().getTime() - this.item.publishedAt.getTime() > 72*3600*1000
          data.releasedAt = new Date()
        }

        // Actually update
        this.$store.commit('treatments/add', {
          items: [{
            id: this.item.id,
            status: val,
            data: data
          }]})

        // Do save
        this.$store.dispatch('treatments/save', {id: this.item.id}).then(() => {
          val === 4 && oldStatus !== 4 &&
            // Send mail
            API.sendEmail(
              [this.customer.email],
              'STYLE STOCKに新しいスタイルが追加されました',
              this.$refs.$_ssTV_email.toHTML()
            )
              .catch(() => this.$store.commit('error', {message: 'お客さまにメールが送信できませんでした'}))

          // Go to ‘Dashboard’
          oldStatus !== 4 && val === 4 && this.$router.push({name:'Dashboard'}).catch(() => {})
        })

        // Notify
        this.$store.dispatch('notify', {text: val === 3 ? 'タブレットに連携しました' : 'milbon:iDに送信しました'})
      } catch {/* Do noting */}
    },
    /** Remove an image */
    async removeImage (index) {
      try {
        // Confirm
        await this.$store.dispatch('confirm', {text: 'この写真を本当に削除しますか？\n削除すると元には戻せません。'})

        // Remove an item
        this.item.images.splice(index, 1)

        // Remove an image
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            images: this.item.images,
          }]
        })
      } catch {() => {}}
    },
    /** Remove an video */
    async removeVideo () {
      try {
        // Confirm
        await this.$store.dispatch('confirm', {text: 'この動画を本当に削除しますか？\n削除すると元には戻せません。'})

        // Remove an video
        this.$store.dispatch('treatments/set', {
          items: [{
            id: this.item.id,
            videos: []
          }]
        })
      } catch {() => {}}
    },
    /** Image upload status change handler */
    async $_ssTreatmentView_onUploadStatusChange (image, i=null) {
      // Copy images and swap an image
      const images = this.item.images.concat()
      i !== null ? images.splice(i, 1, image) : images.push(image)

      // Update state
      this.$store.dispatch('treatments/set', {
        items: [{
          id: this.item.id,
          images: images
        }]
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // Reset scroll position if final destination is this component
      if (to.matched[to.matched.length-1].instances &&
            to.matched[to.matched.length-1].instances.default === vm)
        to.meta.savedPosition = {top: 0, treatmentId: ''}
    })
  },
  beforeRouteUpdate (to, from, next) {
    // If leave from this component, save scroll position and current treatment ID to from route meta field 
    if (from.matched[from.matched.length-1].instances &&
          from.matched[from.matched.length-1].instances.default === this)
      from.meta.savedPosition = {top: this.$el.scrollTop, treatmentId: from.params.treatmentId}

    next()
  },
  mounted () {
    // Restore scroll position if saved treatment ID had not changed
    this.$el.scrollTo(0, this.$route.meta.savedPosition &&
      this.$route.meta.savedPosition.treatmentId === this.$route.params.treatmentId &&
        this.$route.meta.savedPosition.top || 0)
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-treatment__content {
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(64);
}
}

.ss-treatment-intro {
  margin: 0 0 oct-rem(64);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: oct-rem(32) oct-rem(16) 0;

  .oct-chip {
    margin-bottom: oct-rem(16);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding: 0 oct-rem(64);
}
}

.ss-treatment-intro__media {
  margin: 0 0 oct-rem(16);
  width: oct-rem(80);
  height: oct-rem(80);
}

.ss-treatment-intro__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-treatment-intro__text {
  text-align: center;
}

.ss-treatment-intro__primary-text {
  font-size: oct-rem(32);
  font-weight: bold;
  line-height: 1;
  margin: 0 0 oct-rem(16)
}

.ss-treatment-intro__secondary-text {
  font-size: oct-rem(12);
  line-height: 1;
  color: #928F9B;
  margin: 0 0 oct-rem(16);
  display: block;
}

.ss-treatment-intro__link-text {
  font-size: oct-rem(16);
  line-height: 1;
  color: $oct-theme--primary;
  fill: $oct-theme--primary;
  display: inline-flex;
  align-items: center;

  .oct-icon {
    margin-left: oct-rem(4);
    width: oct-rem(20);
    height: oct-rem(20);
  }
}

.ss-treatment__section {
  margin: 0 0 oct-rem(64);

  &:last-child {
    margin-bottom: oct-rem(32);
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  &--user {
    &::v-deep .oct-list__content {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }
}
}

.ss-treatment-header {
  margin: 0 0 oct-rem(16);
  padding: 0;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-bottom: oct-rem(32);
}
}

.ss-treatment-header__primary-text {
  font-size: oct-rem(20);
  line-height: 1;
  display: flex;
  align-items: center;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(24);
}
}

.ss-treatment-header__icon {
  fill: $oct-theme--on-primary;
  color: $oct-theme--on-primary;
  margin: 0 oct-rem(8) 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: oct-rem(32);
  height: oct-rem(32);
  border-radius: 50%;
  background-color: $oct-theme--primary;

  .ss-treatment-header--empty & {
    background-color: $oct-theme--neutral-20;
  }

  .oct-icon {
    width: 100%;
    height: 100%;
  }
}

.ss-treatment-slider {
  margin: 0 oct-rem(-16) oct-rem(-16);
  padding: 0 0 oct-rem(16);
  overflow: auto;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin: 0 oct-rem(-64) oct-rem(-16);
  padding: 0 0 oct-rem(16);
}
}

.ss-treatment-media {
  margin: 0;
  display: flex;
  gap: oct-rem(14);
  padding: 0 0 0 oct-rem(16);
  list-style: none;

  &--video {
    flex-direction: column;
    align-items: center;

    .oct-media {
      width: oct-rem(216);
      padding-top: oct-rem(384);
      background-color: $oct-theme--neutral-10;
      border-radius: oct-rem(4);
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  gap: oct-rem(32);
  padding-left: oct-rem(64);

  &--video {
    padding-left: 0;

    .oct-media {
      width: oct-rem(256);
      padding-top: oct-rem(455);
    }
  }
}
}

.ss-treatment-media__item {
  margin: 0;
  flex: 0 0 auto;
  width: (216%/359)*100;
  padding: 0;

  &[role="separator"] {
    width: oct-rem(1);
  }

  &::v-deep {
    .oct-media {
      background-color: $oct-theme--neutral-10;
    }
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  width: oct-rem(256);

  &[role="separator"] {
    width: oct-rem(32);
  }
}
}

.ss-treatment-media__uploader {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: $oct-theme--neutral-10;
}

.ss-treatment-media__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ss-treatment-media__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-treatment__note {
  margin: oct-rem(20) 0 0;
}

.ss-treatment__note-text {
  font-size: oct-rem(14);
  line-height: 1.5;
  color: $oct-theme--neutral-50;
}

.ss-treatment__action {
  min-width: oct-rem(110);
}

.ss-treatment__customer-email {
  display: none;
}
</style>